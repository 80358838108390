
.campaign-module {
  margin: 0 -8px 16px 0;

  .campaign-header h5 {
    letter-spacing: 3px;
    font-weight: bold;
    font-size: 37px;
  }

  .campaign-message {
    display: none;
  }
  .campaign-message.active {
    display: block;
  }

  .campaign-links {
    margin-top: 20px;

    .campaign-field {
      position: relative;
      display: inline-block;

      label {
        letter-spacing: 1px;
        font-weight: 600;
        color: #797979;
        font-size: 13px;
        margin: 0;
      }

      input {
        display: block;
        margin: 0;
        padding: 8px 10px;
        border: none;
        background: #f7f7f7;
        border-radius: 3px;
        font-weight: bold;
        margin-top: 7px;
      }
    }
  }

  .campaign-stage {
    display: none;

    .expected-delivery {
      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;
      margin: 0;
      color: #797979;
      letter-spacing: 2px;
    }

    .delivery-date-container {

      .delivery-top {
        .delivery-day {
          letter-spacing: 2px;
          font-size: 29px;
          font-weight: 100;
          text-transform: uppercase;
          color: #797979;
          margin: 0;
        }
      }

      .delivery-left {
        position: relative;
        display: inline-block;
        width: 160px;


        .delivery-bottom {

          .delivery-month {
            text-transform: uppercase;
          }

          p {
            margin: 0;
          }

          .delivery-num {
            position: relative;
            display: inline-block;

            span {
              font-size: 65px;
              font-weight: 500;
              line-height: 65px;
              margin-left: -2px;
            }
          }
          .delivery-cal {
            position: relative;
            display: inline-block;
            padding-left: 5px;
            color: #797979;
          }
        }
      }

      .delivery-right {
        position: relative;
        display: inline-block;
        border-left: 1px solid #eee;
        padding-left: 15px;

        p {
          margin: 0;
        }
        .delivery-time-by {
          color: #797979;
          font-size: 16px;
        }
        .delivery-time {
          font-weight: bold;
          font-size: 25px;
        }
      }
    }


    .delivered-message {
      margin-bottom: 25px;

      .delivered-title {
        font-size: 12px;
        font-weight: bold;
        margin-right: 10px;
      }
      .delivered-date {
        color: #797979;
      }
    }
  }

  .campaign-stage.active {
    display: block;
  }

  .campaign-progress {
    position: relative;
    width: 100%;
    height: 30px;

    .campaign-progress-container {
      position: relative;

      .campaign-progress-mark {
        position: absolute;
        width: 20px;
        height: 20px;
        border: 1px solid #eee;
        border-radius: 30px;
        top: 0;
        z-index: 2;
        background: white;
      }
      .campaign-progress-mark.active {
        background: #77dd77;
        border-color: #77dd77;
      }
      .campaign-progress-mark1 {
        left: -1%;
      }
      .campaign-progress-mark2 {
        left: 48%;
      }
      .campaign-progress-mark3 {
        right: -1%;
      }

      .campaign-progress-line {
        position: relative;
        display: inline-block;
        width: 50%;
        height: 8px;
        border: 1px solid #eee;
        border-radius: 50px;
        overflow: hidden;
        background: white;

        .campaign-progress-line-inner {
          position: absolute;
          width: 0%;
          height: 100%;
          left: 0;
          top: 0;
          background: #77dd77;
        }
      }
      .campaign-progress-line.active {
        border-color: #77dd77;

        .campaign-progress-line-inner {
          width: 100%;
        }
      }
    }
  }
}



.campaign-stage-3 {

  .campaign-field {
    position: relative;
    display: inline-block;

    label {
      color: #797979;
      letter-spacing: 3px;
    }
    p {
      font-size: 65px;
    font-weight: 500;
    line-height: 65px;
    }
  }
}

.campaign-module.campaign-stage-0 {
  .campaign-message-0, .campaign-stage-0 {
    display: block !important;
  }
}

.campaign-module.campaign-stage-1 {
  .campaign-message-1, .campaign-stage-1 {
    display: block !important;
  }
  .campaign-progress-mark1 {
    background: #77dd77 !important;
    border-color: #77dd77 !important;
  }
}

.campaign-module.campaign-stage-2 {
  .campaign-message-2, .campaign-stage-2 {
    display: block !important;
  }
  .campaign-progress-mark1, .campaign-progress-mark2 {
    background: #77dd77 !important;
    border-color: #77dd77 !important;
  }
  .campaign-progress-line1 {
    border-color: #77dd77 !important;

    .campaign-progress-line-inner {
      width: 100% !important;
    }
  }
}

.campaign-module.campaign-stage-3 {
  .campaign-message-3, .campaign-stage-3 {
    display: block !important;
  }
  .campaign-progress-mark1, .campaign-progress-mark2, .campaign-progress-mark3 {
    background: #77dd77 !important;
    border-color: #77dd77 !important;
  }
  .campaign-progress-line1, .campaign-progress-line2 {
    border-color: #77dd77 !important;

    .campaign-progress-line-inner {
      width: 100% !important;
    }
  }
}

.brand-restaurant {

  .restaurant-image {
    // height: 132px;
    height: 285px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    -webkit-transition: -webkit-transform .3s;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s,-webkit-transform .3s;
    // border-radius: 3px 3px 0 0;
    border-radius: 15px;
  }
  .restaurant-meta {
    padding: 15px 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: initial;
    position: relative;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;

    h3 {
      color: #333;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-top: 0;
      margin-bottom: 5px;
      // font-size: 18px;
      font-size: 20px;
      font-weight: 700;
      line-height: 24px;
      border: none;
      padding-bottom: 0;
      text-transform: initial;
    }

    .restaurant-ratings {
      margin: 0 0 5px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding: 0;

      .restaurant-stars-container {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        margin: 0;
        padding: 0;

        .restaurant-stars {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-item-align: center;
          align-self: center;

          img {
            height: 18px;
          }

          // .red-star {
          //   position: relative;
          //   color: #db3843;
          //   font-size: 24px;
          // }
          // .gray-star {
          //   position: relative;
          //   color: #e1e1e1;
          //   font-size: 24px;
          // }
          // .halfstar {
          //   position: absolute;
          //   top: 0;
          //   left: 0;
          //   width: 0;
          //   color: #db3843;
          //   overflow: hidden;
          //   font-size: 24px;
          // }
        }
      }

      .ratings-text {
        margin: 0 0 0 6px;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        font-weight: 400;
      }
    }

    .restaurant-sub {
      margin: 0 0 10px;
      height: 44px;
      white-space: initial;
      font-size: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2; /* number of lines to show */
      height: 44px;
      max-height: 44px;

      .restaurant-dot {
        margin: 0 4px;
      }

      .restaurant-price {

      }
      .restaurant-categories {

      }

      // span:not(:first-child) {
      //   margin-left: .25rem;
      // }
    }

    .restaurant-description {
      display: none;
      white-space: normal;
    }
  }
}



.brand-campaign {
  .brand-logo {
    position: absolute;
    maxWidth: 100%;
    maxHeight: 100%;
    top: 0;
    // bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    padding: 13px 20px;
  }
  button {
    display: block;
    // margin: 45px auto 0 auto;
    margin: 15px auto 0 auto;
    padding: 10px 25px;
    // border-radius: 25px;
    // border: none;
    // background: orange;
    // font-weight: bold;
    // text-transform: uppercase;
    // letter-spacing: 2px;
    // color: white;
    // box-shadow: rgba(0, 0, 0, 0.05) 0px 2px 9px 0px;
  }

  .brand-logo-container {
    position: relative;
    display: inline-block;
    float: left;
  }
  .brand-meta-container {
    position: relative;
    display: inline-block;
    float: left;
    width: 100%;
    // width: ~'calc(100% - 150px)';

    h4 {
      // padding-top: 40px;
      padding-top: 8px;
      margin-bottom: 0;
    }
  }

  .col-md-3 {

    img {
      display: block;
      margin: 0 auto;
      max-width: 100%;
      max-height: 210px;
      border-radius: 3px;
    }
  }
}

.brand-btns {
  position: absolute;
  top: 0;
  right: 0;

  .brand-btns-inner {
    position: relative;

    button {
      // background: white;
      position: relative;
      background: black;
      color: white;
      padding: 14px 15px 14px 48px;
      border: 1px solid rgba(0, 0, 0, 0.08);
      border-radius: 3px;
      box-shadow: rgba(0, 0, 0, 0.05) 0px 2px 9px 0px;
      text-transform: uppercase;
      letter-spacing: 1px;
      font-color: #333;
      font-size: 16px;
      font-weight: 700;
      cursor: pointer;

      img {
        position: absolute;
        width: 24px;
        margin-right: 10px;
        left: 12px;
      }
    }
  }
}

button.action-b {
  position: relative;
  display: block;
  margin: 20px auto 0;
  // background: white;
  background: black;
  color: white;
  padding: 8px 15px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 2px 9px 0px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-color: #333;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
}



.module.brand-tile {
  position: relative;
  overflow: hidden;
  height: 430px;
  max-height: 430px;
  // margin: 25px 15px;
  margin: 0;
  padding: 15px;
  cursor: pointer;
  transition: all 0.32s ease;

  .brand-category {
    position: absolute;
    top: 10px;
    left: 10px;
    padding: 4px 8px;
    background: purple;
    color: white;
    border-radius: 3px;
  }

  .brand-platforms {

  }

  .brand-logo-container {
    position: relative;
    height: 300px;
    padding-left: 0;

    .brand-logo {
      position: absolute;
      max-width: 100%;
      max-height: 100%;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      padding: 13px 20px;
    }
  }

  .brand-pitch {
    height: 60px;
    line-height: 20px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    text-overflow: ellipsis;
    max-width: 100%;
    overflow: hidden;
  }

  button {
    display: block;
    // margin: 45px auto 0 auto;
    margin: 30px auto 0 auto;
    padding: 10px 25px;
    // border-radius: 25px;
    // border: none;
    // background: orange;
    // font-weight: bold;
    // text-transform: uppercase;
    // letter-spacing: 2px;
    // color: white;
    // box-shadow: rgba(0, 0, 0, 0.05) 0px 2px 9px 0px;
  }

  // .brand-logo-container {
  //   position: relative;
  //   display: inline-block;
  //   float: left;
  // }
  .brand-meta-container {
    position: relative;
    display: inline-block;
    float: left;
    width: 100%;
    padding: 0 15px;
    // width: ~'calc(100% - 150px)';

    h4 {
      letter-spacing: 0.75px;
      font-weight: 900;
      line-height: 30px;
      padding-top: 8px;
      margin-bottom: 8px;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 100%;
      overflow: hidden;
      font-family: Barlow;
    }

    a {
      display: block;
      text-transform: none;
      letter-spacing: 1px;
      font-size: 14px;
      color: #8a8a8a;
      margin-top: -10px;
    }
  }

  .col-md-3 {

    img {
      display: block;
      margin: 0 auto;
      max-width: 100%;
      max-height: 210px;
      border-radius: 3px;
    }
  }
}

.module.brand-tile.brand-tile-offer {
  background: none !important;
  box-shadow: none !important;
  border: none !important;
}

.brand-image-hover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.13);
  border-radius: 15px;
  transition: all 0.32s ease;
  opacity: 0;
}

.brand-tile:hover {
  // transform: translateY(-12px);
  box-shadow: 0 15px 25px rgba(0, 0, 0, .1);

  .brand-image-hover {
    opacity: 1;
  }
  .brand-image-spread {
    // box-shadow: 0 1px 10px rgba(51,51,51,.8);
  }

  h3 {
    text-decoration: underline;
  }
}

.module.brand-tile.brand-tile-active {
  height: 300px;
  background: white;
  margin-bottom: 20px;
  border: none;

  .brand-image {
    height: 125px;
    // height: 100px;
    box-shadow: none !important;

    .brand-image-spread {
      position: relative;
      background-position: 0 15%;
      border-radius: 15px 15px 0 0;

      .brand-image-profile {
        position: absolute;
        bottom: -30px;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 85px;
        height: 85px;
        border-radius: 50%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        z-index: 3;
        box-shadow: rgba(0, 0, 0, 0.32) 0px 2px 13px -5px;
      }

      .brand-res-photo-container {
        position: relative;
        display: inline-block;
        float: left;
        width: 33.33%;
        height: 100%;

        .brand-res-photo {
          width: 100%;
          height: 100%;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
        }
      }
    }
  }

  .brand-meta {
    font-family: Barlow;
    text-align: center;
    padding: 35px 0 0 0;
  }
}


.brand-btns {
  position: absolute;
  top: 0;
  right: 0;

  .brand-btns-inner {
    position: relative;

    button {
      // background: white;
      position: relative;
      background: black;
      color: white;
      padding: 14px 15px 14px 48px;
      border: 1px solid rgba(0, 0, 0, 0.08);
      border-radius: 3px;
      box-shadow: rgba(0, 0, 0, 0.05) 0px 2px 9px 0px;
      text-transform: uppercase;
      letter-spacing: 1px;
      font-color: #333;
      font-size: 16px;
      font-weight: 700;
      cursor: pointer;

      img {
        position: absolute;
        width: 24px;
        margin-right: 10px;
        left: 12px;
      }
    }
  }
}

button.action-b {
  position: relative;
  display: block;
  margin: 20px auto 0;
  // background: white;
  background: black;
  color: white;
  padding: 8px 15px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 2px 9px 0px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-color: #333;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
}



.onboard-campaign {
  .onboard-container.interact-section {
    background: none;

    .onboard-youtube-prof-card {
      box-shadow: none;
      background: none;
      overflow: initial;
      // opacity: 1;
      transform: none;

      .onboard-banner, .onboard-bottom {
        background: white;
        border-radius: 20px;
        transition: all 1s ease;
        border: 1px solid #eee;
        padding: 36px 10px;
        height: initial;
      }

      .onboard-banner {
        transform: translateY(-200px);
      }

      .onboard-bottom {
        transform: translateY(200px);

        img {
          border-radius: 50%;
        }
      }

      .start-btn {
        opacity: 0;
        position: absolute;
        padding: 10px 25px;
        text-transform: uppercase;
        letter-spacing: 2px;
        top: -23px;
        margin: auto;
        left: 0;
        right: 0;
        border: 1px solid #ddd;
        background: white;
        color: #333;
        font-weight: bold;
        font-size: 20px;
        width: 190px;
        border-radius: 3px;
        box-shadow: 0 10px 8px -1px rgba(0, 0, 0, 0.02);
        transition: all 0.3s ease;
      }
    }

    .onboard-youtube-prof-card.loaded {
      .onboard-banner, .onboard-bottom {
        border-radius: 20px 20px 0 0;
      }
      .onboard-banner {
        border-radius: 20px 20px 0 0;
        // border: 1px 1px 0px 1px solid #eee;
        transform: translateY(0px);
      }
      .onboard-bottom {
        border-radius: 0 0 20px 20px;
        // border: 0px 1px 1px 1px solid #eee;
        transform: translateY(0px);
      }
    }

    .onboard-youtube-prof-card.loaded-2 {
      .start-btn {
        opacity: 1;
      }
    }
  }
}


.shopify-logo {
  width: 30px;
  margin-right: 10px;
  margin-top: -6px;
}




.youtube-like-container {
  display: block;
  min-width: 130px;
  max-width: 250px;
  margin: 10px auto;

  .thumbs-container {
    position: relative;
    display: block;
    margin: 0 auto 12px auto;
    text-align: center;

    .thumb {
      position: relative;
      display: inline-block;

      img {
        width: 20px;
        opacity: 0.42;
        margin-right: 8px;
      }
      span {
        font-weight: 500;
      }

      .thumb-img-wrap {
        position: relative;
        display: inline-block;

        img {
          margin-top: -6px;
        }
      }
    }
    .thumb-like {
      margin-right: 20px;
    }
    .thumb-dislike {

      img {
        transform: rotate(180deg);
      }
    }
  }
  .like-bar-container {
    position: relative;

    .like-bar-outer {
      position: relative;
      width: 100%;
      height: 4px;
      overflow: hidden;
      background: red;
      // background: #d71e18;

      .like-bar-inner {
        position: absolute;
        height: 4px;
        background: green;
        // background: #00bf4c;
      }
    }
  }
}

.result-stat-num {
  // font-size: 30px;
  font-size: 22px;
  font-weight: 700;
  margin: 0px;
  text-align: center;
}
.result-stat-label {
  text-transform: uppercase;
  letter-spacing: 0.8px;
  color: #aaa;
  font-size: 11px;
  font-weight: 700;
  margin: 0px;
  text-align: center;
}

.bounty-tile-categories {
  position: relative;
  overflow: hidden;
  width: 100%;

  .bounty-tile-categories-container {
    position: relative;
    min-width: 500px;
  }
}
.bounty-tile-category {
  position: relative;
  display: inline-block;
  font-family: Barlow;
  padding: 2px 8px;
  border-radius: 3px;
  float: left;
  margin-right: 6px;
}

.brand-game {
  .brand-image {
    position: relative;
    height: 285px;
    box-shadow: rgba(0, 0, 0, 0.62) 0px 2px 35px -25px;
    background-color: transparent;
    border-radius: 15px;

    .brand-image-spread {
      width: 100%;
      height: 100%;
      // border-radius: 3px 3px 0 0;
      border-radius: 15px;
      background: rgb(213,213,213);
      background-image: linear-gradient(321deg, rgba(213,213,213,1) 0%, rgba(223,223,223,1) 35%, rgba(236,236,236,1) 100%);
      background-position: top;
      background-size: cover;
      background-repeat: no-repeat;
      // -webkit-transition: -webkit-transform .3s;
      // transition: -webkit-transform .3s;
      // transition: transform .3s;
      // transition: transform .3s,-webkit-transform .3s;
      // -webkit-transition: -webkit-transform .3s;
      // transition: -webkit-transform .3s;
      transition: all .32s ease;
      // transition: transform .3s,-webkit-transform .3s;
    }

    .brand-image-tiles {
      position: absolute;
      bottom: 10px;
      right: 10px;

      .brand-image-tile {
        position: relative;
        display: inline-block;
        float: right;
        margin-left: 10px;
        padding: 4px 8px;
        background: #fffffff0;
        border-radius: 8px;
        font-weight: 700;
        font-family: 'Barlow';
      }
    }

  }

  .brand-meta {
    position: relative;
    padding: 15px 0;
    height: 90px;

    h3 {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-top: 0;
      margin-bottom: 6px !important;
      font-size: 20px;
      font-family: 'Barlow';
      font-weight: 700;
      line-height: 24px;
      border: none;
      padding-bottom: 0;
      text-transform: initial;
    }

    p.sub-text {
      font-size: 14px !important;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2; /* number of lines to show */
      height: 44px;
      max-height: 44px;
    }

    .game-live-nums {
      position: absolute;
      bottom: 0;
      right: 10px;

      .game-live-nums-container {
        position: relative;

        .game-live-num {
          position: relative;
          display: inline-block;

          img {
            width: 13px;
            margin-right: 5px;
            margin-left: 9px;
            margin-top: -1px;
          }

          span {
            font-family: Barlow;
            font-weight: 900;
            font-size: 13px;
          }
        }
      }
    }
  }
}

.brand-tag {
  border: 1px solid #dad8de;
  background: #faf9fa;
  border-radius: 4px;
  padding: 3px 5px;
  margin-right: 6px;
}

.bounty-stage-indicator {
  display: none;
}
