/* LOADER CSS */
.loader {
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999999;
}
.spinner {
  /*margin: 100px auto 0;*/
  width: 70px;
  text-align: center;
  max-width: 35%;
  max-height: 35%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.spinner > div {
  width: 18px;
  height: 18px;
  /*background-color: #dd313c;*/
  // background-color: #387FFF;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}
.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% { -webkit-transform: scale(0) }
  40% { -webkit-transform: scale(1.0) }
}
@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
}

/* END LOADER CSS */


/* LOADER 2 CSS */

.loader2 {
  font-size: 90px;
  text-indent: -9999em;
  overflow: hidden;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  margin: 72px auto;
  position: relative;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load6Loader2 1.7s infinite ease, roundLoader2 1.7s infinite ease;
  animation: load6Loader2 1.7s infinite ease, roundLoader2 1.7s infinite ease;
}
@-webkit-keyframes load6Loader2 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}
@keyframes load6Loader2 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}
@-webkit-keyframes roundLoader2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes roundLoader2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* END LOADER 2 CSS */

/* LOADER 3 CSS */

.loader-module {
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999999;
    width: 250px;
    height: 250px;

  .loader-module-inner {
    position: relative;
    width: 250px;
    height: 250px;

    .loader-module-text {
      text-align: center;
      margin-top: 25px;
    }

    .loader-module-icon {
      position: relative;
      width: 100%;
      height: 60px;

      .sk-chase-container {
        position: absolute;
        margin: auto;
        top: 0;
        left: 0;
        right: 0;
        // bottom: 0;
        z-index: 9999999;
        width: 60px;
        height: 60px;

        .sk-chase {
          position: relative;
          width: 60px;
          height: 60px;
          animation: sk-chase 2.5s infinite linear both;

          .sk-chase-dot {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            animation: sk-chase-dot 2.0s infinite ease-in-out both;
          }

          .sk-chase-dot:before {
            content: '';
            display: block;
            width: 25%;
            height: 25%;
            // background-color: #fff;
            background-color: #000;
            // background-color: #387FFF;
            border-radius: 100%;
            animation: sk-chase-dot-before 2.0s infinite ease-in-out both;
          }

          .sk-chase-dot:nth-child(1) { animation-delay: -1.1s; }
          .sk-chase-dot:nth-child(2) { animation-delay: -1.0s; }
          .sk-chase-dot:nth-child(3) { animation-delay: -0.9s; }
          .sk-chase-dot:nth-child(4) { animation-delay: -0.8s; }
          .sk-chase-dot:nth-child(5) { animation-delay: -0.7s; }
          .sk-chase-dot:nth-child(6) { animation-delay: -0.6s; }
          .sk-chase-dot:nth-child(1):before { animation-delay: -1.1s; }
          .sk-chase-dot:nth-child(2):before { animation-delay: -1.0s; }
          .sk-chase-dot:nth-child(3):before { animation-delay: -0.9s; }
          .sk-chase-dot:nth-child(4):before { animation-delay: -0.8s; }
          .sk-chase-dot:nth-child(5):before { animation-delay: -0.7s; }
          .sk-chase-dot:nth-child(6):before { animation-delay: -0.6s; }
        }
      }
    }
  }
}

@keyframes sk-chase { 100% { transform: rotate(360deg); } }
@keyframes sk-chase-dot { 80%, 100% { transform: rotate(360deg); } }
@keyframes sk-chase-dot-before { 50% { transform: scale(0.4); } 100%, 0% { transform: scale(1.0); } }

/* END LOADER 3 CSS */
