.isMobile {
  .invitation-module.invite-standalone {
      height: 100vh;
      overflow: scroll;
      width: 100vw;
      top: 0;
      left: 0;
      position: absolute;
      border-radius: 0;

    .overview-wrapper {
      margin-top: 30px;
    }

    .game-header {
      border-radius: 0px;
    }
  }
}

.invite-collaboration {

  .module {
    cursor: pointer;
  }

  h4,h3 {
    font-size: 18px !important;
    text-align: left;
    margin-bottom: 0px;
    color: black;
  }
  .sub-text {
    font-size: 16px !important;
  }

  p {
    font-size: 14px;
  }

  .detail {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .side-gradient {
    position: absolute;
    width: 12px;
    height: 100%;
    top: 0;
    left: 0;
  }


  .logo-column {
    .logo {
      min-width: 25px;
      max-width: 30px;
      height: auto;
      //margin-bottom: 16px;
      margin-top: 5px;
      margin-right: 13px;
    }
  }

  .rate-column {
    margin-left: auto;
  }
}
