.connect-socials-container {

  .close-social {
    position: absolute;
    width: 15px;
    height: 15px;
    top: 25px;
    right: 25px;

    img {
      width: 100%;
      cursor: pointer;
    }
  }
}


.row-flex-end {
  display: flex;
  align-items: flex-end;
}

.account-settings {
  font-family: Barlow;
}

.account-input {
  background: none;
  border: none;
  border-bottom: 1px solid #a2a3a5;
  padding: 5px 0;
  margin: 0 0 10px 0;
}

.btn-action {
  width: initial;
  padding: 4px 18px 6px 18px;
  float: initial;
  // margin: 20px 0 0 0px;
  border-radius: 3px;
  border: 1px solid #00000000;
  background: none;
  font-family: Barlow;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0px;
  text-transform: none;
  -webkit-appearance: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &.btn-primary {
    background: #2f80ed;
    border-color: #2f80ed;
    color: white;
  }
  &.btn-error {
    color: #CF6679;
    background: none;
    border: 1px solid #CF6679;
    box-shadow: none;
  }
  &.btn-secondary, &.btn-inactive {
    background: none;
    box-shadow: none;
    transition: all 0.2s ease;
    border: 1px solid #ffffff00;
  }
  &.btn-goback { border: none;}
  &.update-settings-primary:hover { background: #1366d6; }
  &.btn-right, &.btn-left { display: inline-block; }
  &.btn-right { float: right; margin-left: 6px; }
  &.btn-left { float: left; margin-right: 6px; }
}

.invitation-accept-btn { background-image: linear-gradient(to right, #00c6ff 0%, #0072ff  51%, #00c6ff  100%) }
.invitation-accept-btn {
  display: block;
  margin: 0 auto;
  padding: 15px 45px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  border: none;
  outline: none;
  font-size: 16px;
  font-weight: bold;
}

.invitation-accept-btn:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}


.plaid-btn {
  position: relative;
  display: block;
  width: initial;
  padding: 9px 30px !important;
  float: right;
  margin: 10px auto 0 auto;
  border-radius: 50px !important;
  border: none !important;
  font-family: Barlow;
  font-weight: 500;
  color: white;
  font-size: 16px;
  letter-spacing: 0px;
  background: #2f80ed !important;
  text-transform: none;
}
.plaid-btn:hover {
  background: #1366d6;
}

.plaid-btn.plaid-btn-w-icon {
  padding-left: 50px !important;

  .plaid-btn-icon {
    position: absolute;
    width: 16px;
    top: 12px;
    left: 25px;
  }
}

.settings-address-actions {

  a {
    cursor: pointer;
    color: #2f80ed;
    padding: 0 10px;
  }
}

.account-img {
  display: block;
  width: 75px;
  height: 75px;
  margin: 0 auto;
  border-radius: 50%;
  background: #eee;
  background-image: url(https://static-cdn.jtvnw.net/jtv_user_pictures/d0a20abf-12c8-438d-88d2-503244c6a3af-profile_image-300x300.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

#logout.btn-action {
  background: black;
  margin: 25px auto;
  display: block;
  float: initial;
  width: 135px;
  text-align: center;
}

.connect-platforms {

  .col-md-3 {
    padding: 0 8px;
  }

  .connect-platform {
    font-family: Barlow;
    cursor: pointer;

    h5 {
      font-size: 18px;
      font-weight: 700;
    }

    .connect-platform-img {
      height: 45px;
      margin-bottom: 15px;
    }

    .connect-platform-success {
      position: absolute;
      display: none;
      top: 15px;
      right: 15px;
      width: 25px;
      height: 25px;
      padding: 2px 8px;
      background: #09ad09;
      border-radius: 50%;

      img {
        width: 10px;
      }
    }
  }

  .connect-platform.active {
    .connect-platform-success {
      display: block;
    }
    // color: white !important;
    //
    // .sub-text {
    //   color: white !important;
    // }
  }
  // .connect-platform.instagram.active {
  //   background-image: linear-gradient(-135deg, #1400c8, #b900b4, #f50000) !important;
  // }
  // .connect-platform.youtube.active {
  //   background: rgb(255, 0, 0) !important;
  // }
  // .connect-platform.twitch.active {
  //   background: rgb(100, 65, 164) !important;
  // }
  // .connect-platform.mixer.active {
  //   background: rgb(42, 55, 74) !important;
  // }
  .connect-platform.instagram.selected {
    border-color: #b900b4 !important;
  }
  .connect-platform.youtube.selected {
    border-color: rgb(255, 0, 0) !important;
  }
  .connect-platform.twitch.selected {
    border-color: rgb(100, 65, 164) !important;
  }
  .connect-platform.mixer.selected {
    border-color: rgb(42, 55, 74) !important;
  }
}

.account-gender-container {
  padding: 0 4px;
}
.account-gender {
  cursor: pointer;
  text-align: center;
}
.dark-input.account-gender-male:hover, .dark-input.account-gender-male.active {
  background: #71b5ef !important;
  border-color: #71b5ef !important;
  color: white !important;
}
.dark-input.account-gender-female:hover, .dark-input.account-gender-female.active {
  background: pink !important;
  border-color: pink !important;
  color: white !important;
}
.dark-input.account-gender-other:hover, .dark-input.account-gender-other.active {
  background: black !important;
  border-color: black !important;
  color: white !important;
}

.error-msg {
  color: $error;
  margin-left: 10px;
}

@media (max-width: 550px){
  .connect-platforms {
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  .connect-platform {
    height: 200px;
  }
}

.rates-form {

  .rates {

    input::before {
      content: '$';
    }
  }

  .categories-container {
    margin: 20px 0;
  }

  .categories {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 10px;

    &::before {
      content: none;
    }

    .tag-pill {
      background: transparent;
      border: 1px solid #dae0e6;

      cursor: pointer;

      @media screen and (min-width: 992px) {
        &:hover {
          background-color: rgba(#dae0e6, 0.5) !important;
        }
      }

      &.selected {
        background-color: rgba(#dae0e6, 0.7) !important;
      }
    }
  }

  .dzu-dropzone {
    margin-top: 15px;
  }

  .dzu-previewImage {
    max-width: calc(100% - 60px);
    max-height: 250px;
    padding: 20px 20px 20px 0;
  }

  .rate-img-preview {
    position: relative;
    width: 100%;
    padding-bottom: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 8px;

    .rate-img-preview-darken {
      display: none;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: rgba(0,0,0,0.32);
    }

    .rate-img-delete {
      display: none;
      position: absolute;
      top: 6px;
      right: 6px;
      max-width: 20px;
      filter: grayscale(1);
      cursor: pointer;
      z-index: 2
    }
  }

  .rate-img-preview:hover {
    .rate-img-preview-darken, .rate-img-delete {
      display: block;
    }
  }


  .dzu-inputLabel {
    width: 100%;

    input {
      padding: 50px 33%;
      background: #f6fafe;
      font-weight: 100;
      color: #858585;
      border-radius: 8px;
    }
  }

}

.success {
  color: #09ad09 !important;
}

.success-check {
  background-color: #09ad09;
  z-index: 1;
  border-radius: 50%;
  right: 30px;
  bottom: 15px;
  padding: 5px;

  &.active {
    display: flex;
  }

  img {
    height: 10px;
    width: 10px;
  }
}

.email-verified {
  position: absolute;
  display: none;
}

.slider-labels {
  margin: 15px -8% 25px;
}

.horizontal-slider {
  height: 8px;
  border-radius: 3px;

  .example-track {
    height: 8px;
    border-radius: 8px;
  }
  .example-track-0 {
    // background: $dark-input;
    border-radius: 8px 0 0 8px;
  }
  .example-track-1 {
    // background: $weave-orange;
  }
  .example-track-2 {
    // background: $dark-input;
  }

  .example-thumb {
    width: 34px;
    height: 34px;
    top: -13px;
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.22) 0px 2px 4px 0px;
    // background: $dark-input;
    // color: $dark-input;
  }
}

.horizontal-slider.horizontal-slider-single {
  .example-track-0 {
    // background: $weave-orange;
  }
  .example-track-1 {
    // background: $dark-input;
  }
}

.add-filter-restriction-img {
  font-weight: 900;
  font-size: 24px;
  padding: 10px;
  // border: 2px solid #868686;
  border: 2px solid #c1c1c1;
  display: inline-block;
  float: right;
  border-radius: 50%;
  line-height: 13px;
  margin-top: -5px;

  svg {
    width: 20px;
    opacity: 0.5;
  }
}

@media screen and (max-width: 991px){
  .talent-manager-container {
    margin-bottom: 25px;
  }
}
