.html, body {

}
.login-page {
  width: 100vw;
  height: 100vh;
  background: #f5f4f9;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  /*width: 100%;*/
  /*height: 100%;*/
  padding: 0;
  margin: 0;
  color: #4a4a4a;
  // overflow: hidden;


  .login-box {
    margin: auto;
    width: 350px;
    max-width: 100%;
    background: white;
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0 10px 20px -10px rgba(0,0,0,.4);
    z-index: 2;

    .login-box-inner {
      position: relative;
      width: 100%;
      height: 100%;
    }

    .logo-hero {
      width: 168px;
    }

    header {
      height: 100px;
      background: #05d5ff;
      background: linear-gradient(118deg, #5d3dff, #2a95ff 32%, #05d5ff 59%, #12d9fb 62%, #20ddf6 66%, #22ddf6 67%, #a6ffcb);
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
    }
    .login-padding {
      padding: 25px;
    }

    input {
      transition: ease-in-out all 500ms;
    }

    .login-error {
      input {
        box-shadow: inset 0px 0px 1px 1px #ff0000a1;
      }
    }

    .social-button {
      background: rgba(255, 0, 0, 0.79);
      padding: 16px 16px 12px;
      display: block;
      border-radius: 8px;
      text-align: left;
      color: white;
      font-size: 14px;
      box-shadow: 0 1px 2px rgba(0,0,0,.3);
      cursor: pointer;
      margin: 0 auto;
    }
    .social-button .social-button-icon {
      width: 21px;
      height: 18px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100%;
      display: inline-block;
      background-image: url(https://s3-us-west-2.amazonaws.com/weave-img/web-assets/icons/youtube-icon.svg);
    }
    .social-button .social-button-text {
      display: inline-block;
      top: -4px;
      position: relative;
      margin-left: 10px;
      font-weight: 500;
    }
    .login-separator {
      text-align: center;
      margin: 14px 0;
      display: block;
      letter-spacing: 1px;
      font-size: 12px;
    }
    .input-text-wrapper {
      background: #F5F4F7;
      background-size: 14px;
      background-repeat: no-repeat;
      background-position: 16px center;
      height: 50px;
      // margin-bottom: 20px;
      margin-bottom: 8px;
      border-radius: 8px;
    }
    .input-text-wrapper.email {
      background-image:
        url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE4LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCINCgkgdmlld0JveD0iMCAwIDM1MCAzNTAiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDM1MCAzNTA7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxnPg0KCTxwYXRoIGQ9Ik0xNzUsMTcxLjE3M2MzOC45MTQsMCw3MC40NjMtMzguMzE4LDcwLjQ2My04NS41ODZDMjQ1LjQ2MywzOC4zMTgsMjM1LjEwNSwwLDE3NSwwcy03MC40NjUsMzguMzE4LTcwLjQ2NSw4NS41ODcNCgkJQzEwNC41MzUsMTMyLjg1NSwxMzYuMDg0LDE3MS4xNzMsMTc1LDE3MS4xNzN6Ii8+DQoJPHBhdGggZD0iTTQxLjkwOSwzMDEuODUzQzQxLjg5NywyOTguOTcxLDQxLjg4NSwzMDEuMDQxLDQxLjkwOSwzMDEuODUzTDQxLjkwOSwzMDEuODUzeiIvPg0KCTxwYXRoIGQ9Ik0zMDguMDg1LDMwNC4xMDRDMzA4LjEyMywzMDMuMzE1LDMwOC4wOTgsMjk4LjYzLDMwOC4wODUsMzA0LjEwNEwzMDguMDg1LDMwNC4xMDR6Ii8+DQoJPHBhdGggZD0iTTMwNy45MzUsMjk4LjM5N2MtMS4zMDUtODIuMzQyLTEyLjA1OS0xMDUuODA1LTk0LjM1Mi0xMjAuNjU3YzAsMC0xMS41ODQsMTQuNzYxLTM4LjU4NCwxNC43NjENCgkJcy0zOC41ODYtMTQuNzYxLTM4LjU4Ni0xNC43NjFjLTgxLjM5NSwxNC42OS05Mi44MDMsMzcuODA1LTk0LjMwMywxMTcuOTgyYy0wLjEyMyw2LjU0Ny0wLjE4LDYuODkxLTAuMjAyLDYuMTMxDQoJCWMwLjAwNSwxLjQyNCwwLjAxMSw0LjA1OCwwLjAxMSw4LjY1MWMwLDAsMTkuNTkyLDM5LjQ5NiwxMzMuMDgsMzkuNDk2YzExMy40ODYsMCwxMzMuMDgtMzkuNDk2LDEzMy4wOC0zOS40OTYNCgkJYzAtMi45NTEsMC4wMDItNS4wMDMsMC4wMDUtNi4zOTlDMzA4LjA2MiwzMDQuNTc1LDMwOC4wMTgsMzAzLjY2NCwzMDcuOTM1LDI5OC4zOTd6Ii8+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8L3N2Zz4NCg==);
    }
    .input-text-wrapper.password {
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMCAxNiI+PHBhdGggZmlsbD0iIzAwMDAwMCIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNOC44OSA2LjRWNC4xMUE0IDQgMCAwIDAgNSAwYTQgNCAwIDAgMC0zLjg5IDQuMTFWNi40Qy40NiA2LjYzIDAgNy4zIDAgOC4wNXY2LjJDMCAxNS4yMi43NCAxNiAxLjY3IDE2aDYuNjZBMS43IDEuNyAwIDAgMCAxMCAxNC4yNXYtNi4yYzAtLjc2LS40Ni0xLjQtMS4xMS0xLjY1ek01IDEuMTZjMS41NCAwIDIuNzggMS4zMiAyLjc4IDIuOTV2Mi4ySDIuMjJWNC4xYzAtMS42MyAxLjI0LTIuOTUgMi43OC0yLjk1em0uMzcgMTAuN2MwIC4yMi0uMTcuNC0uMzcuNGEuMzguMzggMCAwIDEtLjM3LS40VjEwLjdjMC0uMjEuMTctLjM5LjM3LS4zOS4yIDAgLjM3LjE4LjM3LjM5djEuMTZ6Ii8+PC9zdmc+);
    }
    .input-text-wrapper.url {
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MCA2MCI+PHBhdGggZmlsbD0iIzAwMDAwMCIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNMzAgMTVjLTguMjUgMC0xNSA2Ljc1LTE1IDE1czYuNzUgMTUgMTUgMTVoMTVjOC4yNSAwIDE1LTYuNzUgMTUtMTVhMzAgMzAgMCAxIDAtNjAgMCAzMCAzMCAwIDAgMCAzMCAzMGgzMHYtNy41SDMwQTIyLjQ3IDIyLjQ3IDAgMCAxIDcuNSAzMCAyMi40NyAyMi40NyAwIDAgMSAzMCA3LjUgMjIuNDcgMjIuNDcgMCAwIDEgNTIuNSAzMGMwIDQuMTMtMy4zOCA3LjUtNy41IDcuNWgtMi4xQTE1LjA0IDE1LjA0IDAgMCAwIDQ1IDMwYzAtOC4yNS02Ljc1LTE1LTE1LTE1em0wIDIyLjVhNy41MiA3LjUyIDAgMCAxLTcuNS03LjVjMC00LjEzIDMuMzgtNy41IDcuNS03LjUgNC4xMyAwIDcuNSAzLjM4IDcuNSA3LjUgMCA0LjEzLTMuMzggNy41LTcuNSA3LjV6Ii8+PC9zdmc+);
    }
    .input-text-wrapper .input-text {
      width: 300px;
      padding: 14px;
      border: 0;
      background: transparent;
      background-size: 14px;
      background-repeat: no-repeat;
      background-position: 16px center;
      text-indent: 30px;

      border-radius: 8px;
      border-style: solid;
      border-width: 2px;
      // border-image-source: linear-gradient(to right, #c70bef 4%, #f046a1 47%, #ff9a38 97%);
      border-image-slice: 1;
      border: solid 2px #dee2e9;
    }
    .input-text-wrapper .input-text.success {
      border-color: #77e74f;
    }
    .btn-submit {
      background: black;
      color: white;
      border: 0;
      // border-radius: 8px;
      // text-transform: uppercase;
      // padding: 20px;
      width: 100%;
      // box-shadow: 0 10px 10px -10px rgba(0,0,0,.4);
      font-weight: bold;
      cursor: pointer;
      font-size: 18px;
      padding: 16px 26px;
      border-radius: 8px;
      box-shadow: 0 10px 20px 0 rgba(48, 41, 88, 0.12);
      background-image: linear-gradient(to right, #c407f5, #ff5c82 75%, #ff9d35);
    }
    button.disabled {
      cursor: not-allowed;
      pointer-events: none;
      opacity: 0.6;
    }
    .switch-boxes {
      text-align: center;
      margin: 20px auto 0;
    }
    .input-text-wrapper.message {
      height: 92px;
    }
    .input-text-wrapper.message textarea {
      resize: none;
      /*width: 100%;*/
      height: 100%;
      background: none;
      text-indent: 5px;
    }

  }

  .login-bg-wave {
    position: absolute;
    bottom: -18%;
    left: -12.5%;
    right: 0;
    width: 125%;
    height: 92vh;
    transform: rotate(-10deg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    // background-image: url('images/login-wave.svg');
    // height: 0;
    // border-top: 100vh solid transparent;
    // border-left: 100vh solid transparent;
    // border-right: 100vh solid rgba(0,0,0,0);
  }
}

@media screen and (max-width: 768px){
  .login-page {
    height: auto;
  }
}

@media (max-width: 550px){
  .login-page {
    .login-box {
      width: 100vw;
      height: 100vh;
      border-radius: 0;

      .input-text-wrapper .input-text {
        width: 100%;
      }
    }
  }
}
