/*@font-face {font-family: "HarmoniaSansW01";
    src: url("//db.onlinewebfonts.com/t/0dd7edde115ef38e7def4b16e93b5884.eot");
    src: url("//db.onlinewebfonts.com/t/0dd7edde115ef38e7def4b16e93b5884.eot?#iefix") format("embedded-opentype"),
    url("//db.onlinewebfonts.com/t/0dd7edde115ef38e7def4b16e93b5884.woff2") format("woff2"),
    url("//db.onlinewebfonts.com/t/0dd7edde115ef38e7def4b16e93b5884.woff") format("woff"),
    url("//db.onlinewebfonts.com/t/0dd7edde115ef38e7def4b16e93b5884.ttf") format("truetype"),
    url("//db.onlinewebfonts.com/t/0dd7edde115ef38e7def4b16e93b5884.svg#HarmoniaSansW01-Regular") format("svg");
}*/
/*@font-face {
  font-family: "HarmoniaSansW01";
  src: url("//db.onlinewebfonts.com/t/99f44be299d4608af6fbe99aa38ce446.eot");
  src: url("//db.onlinewebfonts.com/t/99f44be299d4608af6fbe99aa38ce446.eot?#iefix") format("embedded-opentype"),
  url("//db.onlinewebfonts.com/t/99f44be299d4608af6fbe99aa38ce446.woff2") format("woff2"),
  url("//db.onlinewebfonts.com/t/99f44be299d4608af6fbe99aa38ce446.woff") format("woff"),
  url("//db.onlinewebfonts.com/t/99f44be299d4608af6fbe99aa38ce446.ttf") format("truetype"),
  url("//db.onlinewebfonts.com/t/99f44be299d4608af6fbe99aa38ce446.svg#HarmoniaSansW01-Bold") format("svg");
}*/
/*@font-face {
  font-family: 'Avenir Next';
  src: url("/fonts/AvenirNextLTPro-Regular.otf") format("opentype");
  font-style: normal;
}*/
@font-face {
  font-family: 'Avenir Next';
  src: url("/fonts/new/Avenir-Next-LT-Pro.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: 'Avenir Next';
  src: url("/fonts/new/Avenir-Next-LT-Pro-Bold.ttf") format("truetype");
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: 'Avenir Next';
  src: url("/fonts/new/Avenir-Next-LT-Pro-Demi.ttf") format("truetype");
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: 'Avenir Next';
  src: url("/fonts/new/Avenir-Next-LT-Pro-Heavy-Condensed.ttf") format("truetype");
  font-style: italic;
  font-weight: bold;
}

html {
  // background: #c25be4;
  background: #ddeaef;
}
html.freeze {
  overflow: hidden;
}
a, a:hover {
  text-decoration: none;
  color: inherit;
}
iframe[src^="https://apis.google.com"] {
  display: none;
}
button, input, select, textarea, div {
  outline: none;
}
body {
  background-color: #f6f9fc;
  // font-family: 'Source Sans Pro', sans-serif !important;
  // font-family: 'Avenir Next', sans-serif !important;
  font-family: 'Roboto', sans-serif;
  // font-family: 'Inter', sans-serif;
  // font-family: 'Barlow', sans-serif;
  // font-family: 'Poppins', sans-serif;
  /*font-family: 'HarmoniaSansW01', -apple-system, Arial, Sans-Serif !important;*/
  text-rendering: geometricPrecision;
  -webkit-font-smoothing: antialiased;
}
hr {
  margin-top: 15px;
  margin-bottom: 15px;
}
.icon-default { -webkit-font-smoothing: antialiased; text-size-adjust: none; text-rendering: geometricPrecision; }
.bold-text, .bold-text span, .bold-text p { font-weight: bold !important; }
.text-right { text-align: right !important; }
.btn-style {
  margin-top: 15px;
  padding: 8px 24px;
  border: 1px solid black;
  background: none;
  border-radius: 50px;
  cursor: pointer;
  font-family: Barlow;
}
.adjacent-div {
  position: relative;
  display: inline-block;
}
.adjacent-div.adjacent-div-left {
  float: left;
}
.adjacent-div.adjacent-div-right {
  float: right;
}
.main-container {
  max-width: 1500px;
  /*padding-left: 240px;*/
  /*padding-right: 15px;*/
  /*margin-left: 64px;*/
  /*width: calc(100vw - 64px);*/
  /*margin: 5px;*/
  /*transition: all 0.5s ease;*/
  margin-right: auto;
  margin-left: auto;
  padding-left: 72px;
  padding-right: 72px;
}
.section {
  overflow-y: auto;
  overflow-x: hidden;
	margin: 10px 0;
  padding: 0;
}
.header-title {
	position: relative;
	font-size: 15px;
	font-weight: 600;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: 1.4px;
	color: #000000;
	text-align: center;
	width: 100%;
	text-shadow: 1px 1px rgba(0,0,0,.035);
  margin-bottom: 20px;
}
.header-title:before, .header-title:after {
	position: absolute;
  top: 51%;
  overflow: hidden;
  width: calc(50% - 64px);
  height: 1px;
  content: "\A0";
	background-color: #ef8c88;
}
.header-title:before {
	margin-left: calc(-50% + 45px);
	text-align: right;
}
.header-title:after {
	margin-left: 16px;
  text-align: right;
}
.header-title span {
	position: relative;
	z-index: 2;
	font-size: 18px;
	letter-spacing: 1.6px;
}
.header-description {
	color: #576d86;
	font-weight: 100;
}
.stats {
	position: relative;
	margin-top: 10px;
  margin-bottom: 10px;
	font-size: 13px;
	font-weight: 100;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #8ca0b3;
	text-align: center;
}
.products .product {
  margin-bottom: 16px;
  padding-right: 8px;
  padding-left: 8px;
  cursor: pointer;
  min-height: 281px;
  /*max-height: 281px;*/
}
.product:hover .item-shop {
  font-weight: 400;
}
.product:hover .shop-this-arrow {
  width: 12px;
  margin: 0 0 0 10px;
}
.product-container {
  position: relative;
  padding: 24px;
  border-radius: 15px;
	background-color: #ffffff;
	border: solid 1px #e5e5e5;
  min-height: 281px;
  overflow: hidden;
  /*max-height: 281px;*/
}
.product-image {
  position: relative;
  height: 150px;
}
.product-image img {
  position: absolute;
  display: block;
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.product-details {

}
.product-details p {
  margin: 0 0 4px;
  text-align: left;
}
.item-price {
  font-size: 16px;
	font-weight: 600;
  margin-bottom: 12px;
  color: black;
}
.item-name {
  font-size: 14px;
  font-weight: 100;
	/*font-weight: normal;*/
  // color: #373738;
  margin-bottom: 10px;
  line-height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* number of lines to show */
  height: 32px;
  max-height: 32px;
}
.item-brand {
  font-size: 14px;
	font-weight: normal;
  color: #373738;
  margin-bottom: 10px;
  line-height: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1; /* number of lines to show */
  height: 15px;
  max-height: 15px;
}
.item-shop {
  font-size: 14px;
	font-weight: 100;
  letter-spacing: 0.5px;
  line-height: 16px;
  /*overflow: hidden;*/
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  height: 16px;
  max-height: 16px;
  white-space: nowrap;
	/*color: #df0800;*/
  color: #15abff;
  transition: all 0.3s ease;
}
.product-like {
  position: absolute;
  top: 7px;
  right: 27px;
  width: 35px;
  height: 35px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(https://s3-us-west-2.amazonaws.com/weave-img/web-assets/heart_unselected.svg);
}
.description-container-background {
  position: relative;
  width: 100vw;
  max-height: 220px;
  margin-bottom: 65px;
}
.description-container-background .banner {
  position: relative;
  display: block;
  width: 100%;
}
.description-container {
  /*position: relative;*/
  position: absolute;
  height: 100%;
  top: 0;
  bottom: 0;
  margin: 0 auto;
  left: 0;
  right: 0;
}
.youtube-thumbnail img {
  width: 100%;
}
.description-container .page-header {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
}
.video-tile {
  /*width: 582px;*/
	/*height: 130px;*/
  position: absolute;
  display: block;
  width: 200px;
	border-radius: 4px;
	background-color: #ffffff;
	box-shadow: 0 4px 17px 0 rgba(0, 0, 0, 0.1);
	border: solid 1px #f5f5f5;
  overflow: auto;
  top: 30%;
}
.video-tile img {
  width: 100%;
}
.video-tile-details {
  padding: 5px 10px 10px 10px;
}
.video-tile .video-title {
  font-size: 13px;
	font-weight: 400;
  line-height: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* number of lines to show */
  max-height: 50px;
}
.video-tile .youtube-return {
  font-size: 17px;
  letter-spacing: 0.4px;
	color: #df0800;
  cursor: pointer;
  text-decoration: none;
}
.video-tile .row {
  height: 100%;
}
.video-tile .col-xs-4 {
  height: 100%;
}
.channel-thumbnail {
  width: 100%;
  height: 100%;
  padding: 10px 10px 0px 10px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.likes-bar {
  width: 160px;
  height: 2px;
}
.likes-bar div {
  position: relative;
  display: inline-block;
  float: left;
  height: 100%;
}
.likes-bar .likes {
  background: #167ac6;
}
.likes-bar .dislikes {
  background: #ccc;
}
.section-header {
  font-size: 16px;
	font-weight: 600;
	letter-spacing: 2px;
  margin: 25px 0;
}
.description-container .page-header {
  border: none;
}
.shop-this-arrow {
  position: relative;
  margin: 0 0 0 3px;
  transition: all 0.3s ease;
}
.youtube-return-arrow {
  position: relative;
  top: -2px;
  margin: 0 7px 0 0;
  transform: rotate(180deg);
}
.analytics-table {
  position: relative;
  margin: 0 auto;
}
.analytics-table th, .analytics-table td {
  padding: 15px 50px;
}
.analytics-table tr:nth-child(odd) {
  background-color: #f1f1f1;
}
.next-btn {
	border-radius: 2px;
	/*background-color: #df0700;
	box-shadow: 0 2px 4px 0 rgba(100, 100, 100, 0.5);
	border: solid 1px #df0700;*/
	border-radius: 50px;
  border: none;
	/*background-image: linear-gradient(250deg, #e94d47, #ed716c);*/
  background: #dd313c;
	/*box-shadow: 0 2px 4px 0 rgba(100, 100, 100, 0.5);*/
	box-shadow: 0 2px 3px -1px rgba(100, 100, 100, 0.5);
	text-shadow: 1px 1px rgba(0,0,0,.1);
	padding: 11px 18px 10px;
	font-size: 13px;
	line-height: 1.42857;
	font-weight: 600;
	font-style: normal;
	font-stretch: normal;
	letter-spacing: 0.1px;
	text-align: center;
	color: #ffffff !important;
	outline: 0;
	transition: all 0.25s ease;
	user-select: none;
	vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
}
.btn.next-btn:active {
  background-image: linear-gradient(250deg, #e94d47, #ed716c);
}
.view-more p {
  display: block;
  margin: 10px auto;
	font-size: 14px;
	color: #698198;
	background: none;
	outline: none;
  text-align: center;
}
.view-more p span {
	cursor: pointer;
}
.view-more p span img {
	margin-left: 4px;
}

.results-empty-message {
  position: absolute !important;
  width: 325px !important;
  height: 175px !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  margin: auto !important;
  text-align: center !important;
  font-family: Barlow !important;

  h3 {
    font-size: 22px !important;
    font-weight: 700 !important;
    text-transform: initial !important;
  }

  p {
    font-size: 16px !important;
  }
}
.results-empty-message.top {
  top: -50% !important;
}

@media screen and (max-width: 615px){
  .product {
    margin-bottom: 8px;
    padding-right: 4px;
    padding-left: 4px;
  }
  .section-header {
    font-size: 15px;
  }
}


.disabled {
  pointer-events: none;
  opacity: 0.6;
}
