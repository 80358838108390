.status {
  &.complete {
    color: #71b54d !important;
  }

  &.failed {
    color: #c33a3a !important;
  }

  img {
    height: 15px;
    margin-right: 5px;
  }

  .gray {
    opacity: 0.4;
  }
}

.review-link-preview {
  background-color: #f4f4f4;
}

.copied {
  padding: 3px 5px;
  background-color: #71b44dCC;
  border-radius: 5px;
  color: white;
  font-weight: 600;
  opacity: 0;
  position: absolute;
  right: 0;
  bottom: -35px;

  &.active {
    opacity: 1;
  }
}

.review-link-item {
  width: calc(100% - (65px + 12px));
  // margin: auto;

  .review-link-meta {
    width: calc(100% - (95px));
    display: inline-block;
    float: left;

    .truncated {
      height: 60px;
    	max-height: 60px;
    	line-height: 60px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }

  }

  .review-link-row {
    display: inline-block;
    float: right;
    width: 95px;
  }

  &:hover {
    .copy-link img {
      opacity: 0.5;
      transition: opacity 200ms ease-in-out;
    }
  }
}

.content-image-preview-link {
  background-color: #eee;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100px;
  height: 100px;
  border-radius: 3px;
}

.review-link-preview {
  min-width: 65px;
}

.review-link-row {
  margin-bottom: 10px;
  position: relative;
}

.edit-btn {
  img {
    height: 16px !important;
    width: 16px !important;
  }
}

.copy-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;

  &:hover, input:hover {
    cursor: pointer;
  }

  input.clipboard {
    width: calc(100% - 30px);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin: 0;
    padding: 0;
    border: none;
  }

  img {
    height: 16px;
    padding: 0 5px;
    opacity: 0;
  }
}
