

.invite-deliverable {
  display: flex;
  flex-direction: row;

  .green-fg {
    color: #71b54d;
  }

  .red-fg {
    color: #c33a3a !important;
  }

  .gray {
    background: #808080;
  }

  .red {
    background: #c33a3a;
    padding: 3px
  }


  &.full  {
    .icon,.title {
      margin-bottom: 2px;
    }

    .title {
      font-weight: bold;
      font-size: 17px;
    }

    .invite-block-icon {
      margin-top: -10px;
    }
  }

  &.preview {
    margin-top: 5px;
    margin-bottom: 5px;

    .invite-block-icon {
      margin-top: 0;
    }
  }

  .left-panel {
    .deliverable-row {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;


      .deliverable-description {
        flex-basis: 100%;
      }
    }
  }

  .right-panel {
    margin-left: auto;

    align-items: center;
    justify-content: center;
    display: flex;
    min-width: 65px;
  }

  .statuses {
    display: flex;
    justify-content: flex-start;

    .status {
      margin-right: 10px;

      img {
        max-width: 15px;
        max-height: 15px;
      }

      .cross {
        padding: 2px;
      }

    }


  }

  .check, .minus, .cross {
    border-radius: 20px;
    padding: 3px 3px 3px 4px;
    height: 15px;
    vertical-align: -3px;
  }
}
