@import "vars.scss";

.slide-out {
  position: fixed;
  width: $slideout-container;
  max-width: calc(100vw + 100px);
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  top: 0;
  right: 0;
  transform: translateX($slideout-container);
  transition-property: transform;
  transition-duration: 0.65s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  z-index: 101;

  h3 {
    margin: 0;
    font-weight: 700;
    letter-spacing: 0;
  }

  .slide-out-incentives {
    margin: 0 0 0;
    text-align: center;

    .slide-out-incentives-box {
      border: 1px solid #b7b7b7;
      width: 33%;
      display: inline-block;
      margin: 0;
      padding: 25px 0;
      border-radius: 15px;

      h2 {
        text-align: center;
        margin: 0;
        font-size: 42px;
        font-family: Barlow;
        font-weight: 800;
      }
      p {
        text-align: center;
        font-family: Barlow;
        font-weight: 700;
        font-size: 16px;
        margin: 0 0 0;
      }
    }

    .slide-out-incentives-box {
      border: none;
      // background-color: #3abdfe;
      // background-image: -webkit-gradient(linear, left top, left bottom, from(#8e6aff), to(#36aafe));
      // background-image: linear-gradient(to bottom, #8e6aff, #36aafe);
      // background-color: #bb6bd9;
      // background-image: -webkit-gradient(linear, left top, left bottom, from(#f381c1), to(#bb6bd9));
      // background-image: linear-gradient(to bottom, #f381c1, #bb6bd9);

      h2, p {
        // color: white !important;
      }
      p {
        font-weight: 500 !important;
      }
    }

  }

  .slide-out-header {
    // margin-top: 10px;
    // margin-bottom: 25px;

    .slide-out-tag {
      display: inline-block;
      background: #ec4249;
      padding: 2px 6px;
      border-radius: 3px;
      color: white;
      font-family: Barlow;
      font-weight: 500 !important;
      margin-right: 6px;
    }
  }

  .brand-header {
    margin: 0 0 15px 0;

    h4 {
      margin: 0;
    }

    a {
      font-weight: bold;
      color: gray;
      cursor: pointer;
    }

    .brand-logo {
      position: relative;
      display: inline-block;
      float: left;

      img {
        width: 100px;
      }
    }

    .brand-name {
      position: relative;
      display: inline-block;
      float: left;
      padding: 0 15px;
    }

    .brand-offer {
      position: relative;
      display: inline-block;
      padding: 25px 15px 0 15px;
      float: right;

      p {
        float: right;
        font-weight: bold;
      }
    }
  }

  .slide-out-container {
    position: relative;
    // width: 100%;
    width: $slideout;
    max-width: calc(100vw);
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 25px;

    .slide-out-influencer {

      .slide-out-prof-img {
        position: relative;
        display: inline-block;

        img {
          // float: left
          width: 50px;
          border-radius: 50%;
        }
      }
      .slide-out-prof-name {
        position: relative;
        display: inline-block;

        p {

          padding-left: 12px;
          font-weight: 700;
          font-size: 16px;
        }
      }

    }

    .slide-out-inner {
      position: relative;

      .slide-out-video {
        // padding: 10px;
        padding: 10px 0;

        iframe {

        }

        .slide-out-video-lower {
          padding: 20px 0;

          .slide-out-video-title {
            font-size: 18px;
          }

          .slide-out-video-description {
            margin: 15px 0;
          }
        }
      }

    }

  }

  .slide-out-objectives {

    .slide-out-objective {

      .review-link {
        width: 436px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
      }

      .slide-out-objective-title {
        margin-bottom: 8px;

        .slide-out-objective-status {
          position: relative;
          display: inline-block;
          width: 18px;
          height: 18px;
          border-radius: 50%;
          margin-right: 8px;
          float: left;

          .slide-out-objective-status-img {
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            width: 10px;
            height: 10px;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
          }
        }


        .slide-out-objective-status.complete {
          background: #28d628;
          .slide-out-objective-status-img {
            background-image: url(http://weave-img.s3-us-west-2.amazonaws.com/icons/white/ok-icon.svg);
          }
        }
        .slide-out-objective-status.pending {
          background: #b9b9b9;
          .slide-out-objective-status-img {
            background-image: url(http://weave-img.s3-us-west-2.amazonaws.com/icons/white/minus-icon.svg);
          }
        }
        .slide-out-objective-status.failed {
          background: #f00;
          .slide-out-objective-status-img {
            background-image: url(http://weave-img.s3-us-west-2.amazonaws.com/icons/white/close-icon.svg);
            width: 8px;
            height: 8px;
          }
        }
      }


      .slide-out-objective-post {

      }
    }

    .social-info {
      margin-bottom: 12px;
      // padding-left: 6px;
      margin-left: -3px;

      .social-info-img-container {
        position: relative;
        display: inline-block;
        float: left;
        margin-right: 4px;
        background: #eaeaea;
        width: 24px;
        height: 24px;
        border-radius: 50%;

        img {
          position: absolute;
          width: 12px;
          top: 10px;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto;
        }
      }

      .social-info-name {
        position: relative;
        display: inline-block;
        float: left;
        font-weight: 500;
        padding: 2px 0;
      }
    }
  }
}

.slide-out.slide-out-standalone {
  width: 550px;
  left: 0;
  margin: auto;
  transform: translateX(0);
}

.slide-out.active {
  transform: translateX(100px);
  box-shadow: 0 3px 28.7px 1.3px rgba(13, 21, 40, 0.8) !important;
}

.slide-out-instagram-container {
  position: relative;
  display: block;
  width: 320px;
  min-height: 560px;
  margin: 0 auto;

  iframe.instagram-media {

  }
}

.outline {
  background: white;
  border-radius: 8px;
  // box-shadow: 0 3px 32.7px 5.3px rgba(13, 21, 40, 0.1);
  box-shadow: 0 3px 32.7px -0.7px rgba(13, 21, 40, 0.1);
}


.guidelines-container {
  margin-bottom: 30px;

  .guideline {
    margin: 8px 0px;

    .guideline-status {
      position: relative;
      display: inline-block;
      float: left;

      img {
        width: 18px;
        margin-right: 10px;
        margin-top: -4px;
      }
    }

    .guideline-text {
      position: relative;
      display: inline-block;
      float: left;
      max-width: calc(100% - 30px);

      p {
        margin: 0;
        font-family: Barlow;
      }

      .guideline-main {
        font-size: 14px;
      }
      .guideline-sub {
        font-size: 12px;
      }
    }

  }
}


.tabbed-options {
  position: relative;
  display: block;
  width: calc(100% + 50px);
  // margin: 25px -25px 0;
  margin: 35px -25px 35px;

  .tabbed-option {
    position: relative;
    display: inline-block;
    padding: 14px 15px;
    letter-spacing: 1px;
    text-align: center;
    cursor: pointer;
    font-weight: 500;
    text-transform: uppercase;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;


    p {
      margin: 0;
    }

    hr {
      // display: none;
      position: relative;
      width: 30px;
      margin: 8px auto;
      height: 2px;
      background: #136AA5;
      // transition: all 0.25s ease;
      transition-duration: 0.6s;
      transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
      transform: translateX(85px);
    }
  }
  .tabbed-option-first {
    // border-right: 1px solid #eee;
  }
  .tabbed-option.active, .tabbed-option:hover {
    color: #136AA5;
    border-bottom: 1px solid #59a8de !important;

    // hr {
    //   background: #136AA5;
    //   display: block;
    // }
  }
  .tabbed-option.active {
    hr {
      transform: translateX(0);
    }
  }
}

.slide-out-header {
  position: relative;
  // margin-top: 20px;
  // padding-top: 45px;
  text-align: center;

  .restaurant-main-img {
    position: absolute;
    // top: -65px;
    top: -78px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 125px;
    height: 125px;
    border-radius: 50%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 3;
    box-shadow: rgba(0, 0, 0, 0.32) 0px 2px 13px -5px;
  }

  h3 {
    word-wrap: break-word !important;
    word-break: break-word !important;
    overflow-wrap: break-word !important;
    font-weight: bold;
    margin: 0;
    border: none;
    margin-bottom: 6px;
    font-size: 26px;
    font-weight: 700 !important;
    line-height: 1.2em;
    padding-top: 6px;
    padding-bottom: 0;
    margin-bottom: 6px;
    display: inline;
    text-transform: initial !important;
  }
}

.slide-out-restaurant {

  .restaurant-header {
    position: relative;
    margin-top: 20px;
    padding-top: 45px;
    text-align: center;

    .yelp-logo {
      position: absolute;
      width: 115px;
      top: -45px;
      right: 0;
    }
  }

  .restaurant-main-img {
    position: absolute;
    top: -93px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 125px;
    height: 125px;
    border-radius: 50%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  h3 {
    word-wrap: break-word !important;
    word-break: break-word !important;
    overflow-wrap: break-word !important;
    font-weight: bold;
    margin: 0;
    border: none;
    margin-bottom: 6px;
    font-size: 26px;
    font-weight: 700 !important;
    line-height: 1.2em;
    padding-top: 6px;
    padding-bottom: 0;
    margin-bottom: 6px;
    display: inline;
    text-transform: initial !important;
    letter-spacing: initial !important;
  }

  .restaurant-ratings {
    margin: 6px auto 5px;
    width: 250px;
    display: block;
    // display: -webkit-box;
    // display: -ms-flexbox;
    // display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0;
    font-size: 16px;

    .restaurant-stars-container {
      display: inline-block;
      float: left;
      // display: -webkit-box;
      // display: -ms-flexbox;
      // display: flex;
      margin: 0;
      padding: 0;

      .restaurant-stars {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-item-align: center;
        align-self: center;

        img {
          height: 24px;
        }

        // .red-star {
        //   position: relative;
        //   color: #db3843;
        //   font-size: 24px;
        // }
        // .gray-star {
        //   position: relative;
        //   color: #e1e1e1;
        //   font-size: 24px;
        // }
        // .halfstar {
        //   position: absolute;
        //   top: 0;
        //   left: 0;
        //   width: 0;
        //   color: #db3843;
        //   overflow: hidden;
        //   font-size: 24px;
        // }
      }
    }

    .ratings-text {
      display: inline-block;
      color: #666;
      margin: 0 0 0 6px;
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      font-weight: 400;
    }
  }

  .restaurant-sub {
    margin: 3px 0 .5rem;
    color: #666;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 16px;

    .restaurant-dot {
      margin: 0 6px;
      color: #999;
      font-size: 10px;
    }

    .restaurant-price {

    }
    .restaurant-categories {

    }

    // span:not(:first-child) {
    //   margin-left: .25rem;
    // }
  }
}

.restaurant-photos {
  position: relative;
  margin: -25px -25px 0px -25px;

  .res-photo-container {
    position: relative;
    display: inline-block;
    width: 33.33%;
    padding: 0 2px;
    height: 200px;
    transition: all 0.32s ease;

    .res-photo {
      width: 100%;
      height: 100%;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }
  }
}

.slide-out-restaurant.menu-active {
  .res-photo-container {
    height: 0;
  }
}

.slideout-header-minimize {
  .res-photo-container {
    height: 0;
  }
}


h6 {
  margin-top: 18px;
  margin-bottom: 12px;
  font-size: 18px;

  span {
    font-size: 14px;
    font-weight: 400;
    color: #666;
  }
}

.res-review {
  margin: 0 0 32px 0;

  .review-img-container {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 60px;
    margin-right: 15px;
    float: left;

    .review-img {
      width: 100%;
      height: 100%;
      border-radius: 3px;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }

    img {
      height: 14px;
    }
  }

  .review-text {
    position: relative;
    display: inline-block;
    width: calc(100% - 100px);
    float: left;
  }
}

.game-header-banner-darken {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 3;
}

.slide-out-restaurant {

  .game-header-banner-darken {
    height: calc(100% - 5px);
  }
}

.game-header-banner {
  // position: absolute;
  // width: 100%;
  width: calc(100% + 30px);
  // height: 350px;
  height: 225px;
  // top: 0;
  // left: 0;
  // background-color: #392e5c;
  // background-position: 50%;
  background-position: 15% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 2;
}

.game-header {
  position: relative;
  overflow: hidden;
  margin: -25px -25px 0 -25px;
  background: linear-gradient(0deg,#faf9fa 0,rgba(250,249,250,.25)),linear-gradient(90deg,#faf9fa 0,rgba(250,249,250,.25));
  content: "";
  height: 225px;



  .game-header-outer {
    position: relative;
    z-index: 4;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 3rem 3rem 2rem 3rem;

    .game-header-inner {
      display: flex;
      align-items: stretch;

      .game-header-img {
        display: flex;
        align-items: flex-start;
        margin-right: 3rem;

        .game-header-img-container {
          // width: 13rem;
          width: 130px;
          height: 175px;
          // box-shadow: 0 6px 10px 0 rgba(0,0,0,.1),0 1px 8px -3px rgba(0,0,0,.02),0 2px 16px 0 rgba(0,0,0,.04);
          box-shadow: 0 3px 28.7px 1.3px rgba(13, 21, 40, 0.8) !important;
          transition: all 0.32s ease;

          .game-header-img-outer {
            background: rgba(0,0,0,.05);
            width: 100%;
            border-radius: 4px;
            flex-shrink: 0;
            overflow: hidden;

            .game-header-img-inner {
              overflow: hidden;
              position: relative;
              width: 100%;

              .game-header-img-padding {
                padding-bottom: 133.333%;
              }

              // img {
              //   left: 0;
              //   min-height: 100%;
              //   position: absolute;
              //   width: 100%;
              //   top: 0;
              //   max-width: 100%;
              //   vertical-align: top;
              // }

              .game-header-img-div {
                left: 0;
                min-height: 100%;
                position: absolute;
                width: 100%;
                top: 0;
                max-width: 100%;
                vertical-align: top;
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center;
              }
            }
          }
        }
      }

      .game-header-meta {
        display: flex;
        width: 100%;
        justify-content: center;
        flex-direction: column;

        .game-header-name {
          position: relative;
          display: flex;
          margin-bottom: 1rem;
          justify-content: space-between;

          h1 {
            font-weight: 600;
            font-size: 28px;
            transition: all 0.32s ease;
          }
        }

        h3 {
          transition: all 0.32s ease;
        }

        .game-header-meta-items {

          .game-header-meta-item {
            display: inline-block;
            font-family: Barlow !important;
            font-weight: 900;
            text-transform: uppercase;
            font-size: 12px;

            p {
              color: #322f37;
              font-size: 12px;
              color: #BDBBC0 !important;
              font-weight: 600;

              strong {
                font-family: Barlow !important;
                font-weight: 900;
                text-transform: initial;
                font-size: 18px;
                color: white;
                margin-right: 4px;
              }
            }

            .game-header-meta-dot {
              margin: 0 .5rem;
              font-size: 20px;
              line-height: 0px;
            }
          }
        }
      }
    }
  }
}

.game-header.minimize {

  .game-header-outer .game-header-inner .game-header-img .game-header-img-container {
    width: 65px;
    height: 87.5px;
  }

  .game-header-outer .game-header-inner .game-header-meta .game-header-name h1 {
    margin: 0;
  }

  .game-header-meta h3 {
    font-size: 24px !important;
  }
}

.vod-autocomplete {
  cursor: pointer;
  transition: all 0.3s ease;
}

.vod-autocomplete:hover {
  background: #202225;
}

.vod-duration {
  position: absolute;
  bottom: 4px;
  right: 4px;
  background: #00000066;
  padding: 2px 6px;
  border-radius: 3px;
}

.trackable-link {
  position: relative;
  width: 100%;

  .trackable-link-wrapper {
    border-radius: 3px;
    cursor: pointer;
    transition: all 0.26s ease;

    input {
      background: none;
      border: none;
      font-weight: bold;
      font-family: Barlow;
      width: 100%;
      padding: 10px 45px 10px 15px;
      opacity: 0.75;
      cursor: pointer;
    }

    .trackable-link-copy {
      position: absolute;
      right: 0;
      top: 0;
      width: 40px;
      height: 100%;

      .trackable-link-copy-container {
        position: relative;
        width: 100%;
        height: 100%;
        opacity: 0.75;

        img {
          position: absolute;
          width: 12px;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto;
          cursor: pointer;
        }
      }
    }
  }
  .trackable-link-wrapper:hover {
    // background: #151719;

    input, .trackable-link-copy-container {
      opacity: 1;
    }
  }
}

.game-codes {

  .game-code-platforms {

    .game-code-platform {
      width: 70px;
      height: 70px;
      background: #545454;
      border-radius: 50%;
      position: relative;
      cursor: pointer;
      transition: all 0.22s ease;

      img {
        width: 35px;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        opacity: 0.4;
        transition: all 0.22s ease;
      }
    }

    .game-code-platform.steam.active, .game-code-platform.steam:hover {
      background: #00adee;
    }
    .game-code-platform.xbox.active, .game-code-platform.xbox:hover {
      background: #0e7a0d;
    }
    .game-code-platform.playstation.active, .game-code-platform.playstation:hover {
      background: #003791;
    }
    .game-code-platform.nintendo.active, .game-code-platform.nintendo:hover {
      background: #E60012;
    }
    .game-code-platform.ios.active, .game-code-platform.ios:hover {
      background: #000000;
    }
    .game-code-platform.android.active, .game-code-platform.android:hover {
      background: #a4c639;
    }
    .game-code-platform.active, .game-code-platform:hover {
      img {
        opacity: 1;
      }
    }

  }

}

.banner-download {
  position: relative;
  width: 226px;
  height: 40px;
  float: right;

  .banner-download-wrapper {
    position: relative;
    background: #202225;
    border-radius: 3px;
    height: 100%;
    cursor: pointer;
    transition: all 0.26s ease;

    .banner-download-preview {
      position: relative;
      display: inline-block;
      float: left;
      height: 100%;
      padding: 3px;
      border-radius: 3px;
    }

    .banner-download-btn {
      position: relative;
      display: inline-block;
      float: right;
      // top: 15px;
      right: 0;
      font-family: Barlow;
      font-weight: bold;
      padding: 10px 15px 10px 25px;
      cursor: pointer;
      opacity: 0.75;

      .banner-download-icon {
        position: absolute;
        left: 0;
        top: 11px;
        width: 16px;
      }
    }
  }

  .banner-download-wrapper:hover {
    background: #151719;

    .banner-download-btn {
      opacity: 1;
    }
  }
}

.tooltip-icon {
  display: inline-block;
  width: 13px;
  margin: -3px 0 0 5px;
  cursor: pointer;
  opacity: 0.7;
}

.slide-out-menu {
  position: relative;
  font-family: Barlow;

  .menu-header {
    font-size: 18px;
    margin: 0 0 18px 0;
  }

  ul {
    padding: 0;
    list-style: none;
    // height: calc(100vh - 485px);
    height: calc(100vh - 254px);
    margin: 0;
    overflow: scroll;

    li.menu-item {
      padding: 0;
      margin-bottom: 8px;
      background: #202225;
      border-radius: 3px;
      cursor: pointer;

      .menu-text {
        padding: 15px;

        .menu-text-ellipsis {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .menu-ingredients {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          height: 40px;
          max-height: 40px;
        }

        .menu-price {
          color: #00CC99;
          font-weight: 600;
          margin: 0;
          font-size: 13px;
        }
      }

      .menu-img {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 33%;
        max-width: 150px;
        height: 130px;
        float: right;
      }
    }

    li:hover {
      background: #121212;
    }

    li.menu-item.menu-item-simple {
      border-bottom: 6px;
    }
  }

}

.menu-checkout {
  // position: absolute;
  // width: 100%;
  // bottom: 0;
  // left: 0;
  // font-family: Barlow;
  position: fixed;
  left: 0;
  bottom: 0;
  transform: translate(0%, 0%);
  width: $slideout;
  max-width: 100vw;
  z-index: 10;

  .menu-checkout-btn {
    // background: #eb1700;
    color: #fff;
    margin: 0 auto 35px auto;
    width: initial;
    border-radius: 50px;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
    padding: 0;
    overflow: hidden;
    border: none;
    text-transform: uppercase;

    width: 100% !important;
    border-radius: 0 !important;
    margin: 0 !important;

    .menu-checkout-btn-container {
      width: 100%;
      height: 100%;
      padding: 12px 35px 12px 35px;
      // background: black;
      // background: #ff8d32;
      // background: #ec4249;
      background-color: #3abdfe;
      background-image: -webkit-gradient(linear, left top, left bottom, from(#61d8ff), to(#36aafe));
      background-image: linear-gradient(to bottom right, #61d8ff, #36aafe);

      .btn-fill {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transform: translateY(100%);
      }
      .btn-fill.active {
        transform: translateX(0%);
        transform: translateY(0%);
      }

      .btn-fill-1 {
        background: green;
      }
      .btn-fill-2 {
        background: teal;
      }

      .menu-checkout-btn-text {
        position: relative;
        display: inline-block;
        float: left;

        img {
          position: relative;
          display: inline-block;
          float: left;
          width: 16px;
          margin-right: 10px;
          top: 4px;
        }

        span {
          font-family: Barlow;
          // font-size: 18px;
          // font-weight: 500;
          // letter-spacing: 1px;

          font-size: 20px;
          font-weight: 700;
          letter-spacing: initial;
          text-transform: initial;
        }
      }

      .menu-checkout-btn-value {
        position: relative;
        display: inline-block;
        float: right;
        margin-left: 36px;

        b {
          font-size: 18px;
          font-weight: 900;
        }

        span {
          font-size: 16px;
          font-weight: 700;
        }
      }
    }


  }
}

.slideout-close {
  position: absolute;
  top: 25px;
  left: 0;
  width: 20px;
  z-index: 10;
  cursor: pointer;

  img {
    width: 100%;
  }
}

.menu-checkout-accept {

  .menu-checkout-btn {
    margin: 15px auto 38px;
  }

  .menu-checkout-btn .menu-checkout-btn-container {
    // padding: 15px 105px 15px 105px;
    padding: 20px 0;
  }

  .menu-checkout-btn .menu-checkout-btn-container .menu-checkout-btn-text {
    float: initial;
    text-align: center;

    // span {
    //   // font-size: 16px;
    //   font-size: 18px;
    //   font-family: Barlow;
    //   font-weight: 600;
    // }
  }
}

.isMobile {
  .menu-checkout-btn .menu-checkout-btn-container {
    padding: 20px 0 35px;
  }
}

.slide-out-menu-cart {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  transform: translateX($slideout);
  transition: all 0.32s ease;
  background: #2e3135;
  font-family: Barlow;
  // box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
  // padding: 15px;
  // border-radius: 25px 0 0 0;
}

.slide-out-menu-cart.active {
  transform: translateX(0px);
}

.carousel-container {
  overflow: hidden;
  height: 275px;

  .carousel-container-inner {
    width: 100%;
    height: 275px;
    overflow-x: auto;
    padding-left: 13px;
    overflow-y: hidden;

    .restaurant-photos {
      margin: 0;

      .res-photo-container {
        width: 230px;
        height: 275px;
      }

      .res-photo {
        border-radius: 3px;
      }
    }
  }
}

.slide-out-offer-scrollable {
  overflow-x: hidden;
  // overflow-y: auto;
  transition: all 0.32s ease;
  margin: 0 -25px;
  padding: 0 25px 100px;
}

.tag-pill {
  padding: 6px;
  // color: white;
  border-radius: 3px;
  margin-right: 6px;
  font-family: Barlow;
  font-weight: 500;
}

.downloadable-img {
  position: relative;
  width: 85px;
  height: 85px;
  border-radius: 8px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

@mixin slide-out-active {
  background: #97efff2e;

  .select-gift-indicator {
    border-color: #97efff;
    background: #97efff;
  }

  .bounty-non-hover-reveal-img {
    display: none;
  }
  .bounty-hover-reveal-img {
    display: block;
  }
}

.slide-out-select-gifts {

  .select-gift-option {
    padding: 8px 0;
    cursor: pointer;

    &.active-gift {
      @include slide-out-active;
    }

    &:hover {
      @include slide-out-active
    }

    .select-gift-indicator {
      position: absolute;
      width: 12px;
      height: 12px;
      border: 1px solid #cecece;
      border-radius: 50%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
    }

    .select-gift-img {
      max-width: 100%;
      border-radius: 8px;
      max-height: 85px;
      margin: 0 auto;
      display: block;
    }

    .bounty-non-hover-reveal-img, .bounty-hover-reveal-img {
      width: 100%;
      height: 188px;
      background-repeat: no-repeat;
      // background-position: center;
      // background-size: cover;
      background-position: 45% 23%;
      background-size: 215%;
    }

    .bounty-hover-reveal-img {
      display: none;
    }

    .view-product-url {
      height: 20px;
      margin: auto 0;

      img {
        width: 13px;
        opacity: 0.5;
        margin-left: 4px;
        margin-top: -1px;
      }
    }
  }

  @media screen and (min-width: 500px) {
    .view-product-url {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
    }
}

}

.bounty-choose-size {
  padding: 15px 0;
  border: 3px solid #ddd;
  border-radius: 8px;
  cursor: pointer;
  font-family: Barlow;
  font-weight: 700;
}

.bounty-choose-size.active {
  border-color: #3daffd;
  color: #3daffd;
}

.submission-posts {

  .submission-post {
    // width: 365px;
    width: 100%;
    padding: 12px;

    .submission-title {
      margin: 0;
    }

    .submission-increment {

      .submission-down, .submission-up, .submission-value {
        position: relative;
        display: inline-block;
        float: left;
        padding: 1px 10px;
        -moz-user-select: -moz-none;
        -khtml-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
        border: none;
        font-size: 18px;
        font-family: Barlow;
      }

      .submission-down:disabled {
        opacity: 0.35;
      }

      .submission-down, .submission-up {
        background: #eeeeee;
        border-radius: 3px;
        cursor: pointer;
      }

      .submission-value {
        padding-top: 3px;
        width: 27px;
        text-align: center;
        font-size: 16px;
      }
    }
  }

  .submission-post.active {
    // border-color: #81cefc;
    border-color: #3daffd;
  }
}

// .slide-out-offer-scrollable.slide-out-offer-restaurant {
//   // height: ~'calc(100vh - 413px)';
//   height: ~'calc(100vh - 347px)';
// }
//
// .slide-out-offer-scrollable.slide-out-offer-restaurant.maximize {
//   // height: ~'calc(100vh - 225px)';
//   height: ~'calc(100vh - 159px)';
// }
//
// .slide-out-offer-scrollable.slide-out-offer-product {
//   height: ~'calc(100vh - 140px)';
// }
//
// .slide-out-offer-scrollable.slide-out-offer-product.maximize {
//   height: ~'calc(100vh - 140px)';
// }
//
// .slide-out-offer-scrollable.slide-out-offer-game {
//   // height: ~'calc(100vh - 316px)';
//   height: ~'calc(100vh - 250px)';
// }
//
// .slide-out-offer-scrollable.slide-out-offer-game.maximize {
//   // height: ~'calc(100vh - 242px)';
//   height: ~'calc(100vh - 176px)';
// }

.shipping-tracking {
  // padding: 25px 0;

  .shipping-section {
    position: relative;
    display: inline-block;
    padding: 0px;
    height: 40px;
    float: left;

    .shipping-label {
      display: none;
      position: absolute;
      margin: 19px auto 0 -6px;
      text-transform: uppercase;
      font-weight: 600;
      font-family: Barlow;
      font-size: 12px;
      left: 0;
      right: 0;
      width: 30px;
      text-align: center;
    }
  }

  .shipping-meta {
    text-align: center;

    h4.shipping-meta-title {
      font-size: 20px;
      margin: 0 0 5px 0;
      font-weight: 700;
      // text-transform: uppercase;
      font-family: Barlow;
    }

    .shipping-meta-desc {
      font-size: 16px;
      font-family: Barlow;
    }
  }

  .shipping-stages {
    // padding: 10px 0;
    // margin-bottom: 25px;

    .shipping-stages-inner {

      .shipping-line {
        height: 40px;

        hr {
          position: absolute;
          border: none;
          height: 3px;
          width: 100%;
          top: 0;
          bottom: 0;
          margin: auto;
        }
      }

      .shipping-stage.inactive {
        .shipping-label {
          display: block;
        }
      }
    }
  }

  .shipping-stages.stages-3 {
    .shipping-line {
      width: calc(50% - 45px);
    }
  }

  .shipping-stages.stages-4 {
    .shipping-line {
      width: calc(33% - 35px);
    }
  }
}

.shipping-stage {
  position: relative;
  width: 20px;

  .shipping-stage-inner {
    // margin-top: 85%;
    margin-top: 50%;
    height: 20px;
    position: relative;

    .shipping-stage-dot {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 1;
      border-radius: 50%;
      box-shadow: rgba(0, 0, 0, 0.22) 0px 1px 6px -2px;
    }

    .shipping-stage-dot-accent {
      display: none;
      opacity: 0.3;
      background: #4bc0fe;
      width: 100%;
      height: 100%;
      transform: scale(1.25);
      border-radius: 50%;
    }

    // .shipping-stage-accent {
    //   position: absolute;
    //   display: none;
    //   // width: 150%;
    //   // padding-bottom: 150%;
    //   // top: -25%;
    //   // left: -25%;
    //   border-radius: 50%;
    //   z-index: 0;
    //   transition: all 1.5s ease;
    //   animation-name: blinking-accent;
    //   animation-duration: 1.75s;
    //   animation-iteration-count: infinite;
    // }

    img {
      position: absolute;
      width: 10px;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      z-index: 2;
    }
  }

}

.shipping-stage.active {
  width: 40px;

  .shipping-stage-dot {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 1;
    border-radius: 50%;
  }

  .shipping-stage-dot-accent {
    display: block;
  }

  .shipping-stage-inner {
    width: 100%;
    height: 40px;
    margin: 0;
  }

  img {
    display: block;
    width: 45%;
  }

  .shipping-stage-accent {
    display: block;
  }
}

.shipping-tracking.small {
  .shipping-line hr {
    height: 2px;
  }

  .shipping-stage {
    img {
      width: 10px;
    }
  }

  .shipping-stage.active {
    img {
      width: 14px !important;
    }
  }

  .shipping-stage {
    // width: 15px;

    .shipping-stage-inner {
      // height: 15px;

      .shipping-stage-dot {
        // height: 15px;
      }
    }
  }

  .shipping-stage.active {
    width: 40px;

    img {
      width: 18px !important;
    }

    .shipping-stage-inner {
      // height: 30px;

      .shipping-stage-dot {
        height: 40px;

        // &:before {
        //   content: '';
        //   position: absolute;
        //   width: 50%;
        //   height: 50%;
        //   top: 50%;
        //   left: 50%;
        //   transform: translateX(-50%) translateY(-50%);
        //   background: #4bc0fe;
        //   border-radius: 50%;
        //   // transition: all 1.5s ease;
        //   animation: beacon-accent-small 1.5s infinite linear;
        //   animation-fill-mode: forwards;
        // }
        // &:after {
        //   content: '';
        //   width: 50%;
        //   height: 50%;
        //   position: absolute;
        //   top: 50%;
        //   left: 50%;
        //   transform: translateX(-50%) translateY(-50%);
        //   border: 2px solid #4bc0fe;
        //   border-radius: 50%;
        //
        //   animation: beacon-small 1.5s infinite linear;
        //   animation-fill-mode: forwards;
        // }
      }

      .shipping-stage-dot-accent {
        display: block;
        background: #4bc0fe;
      }
    }
  }
}

.shipping-tracking.animated {
  .shipping-stage.active {
    .shipping-stage-dot {
      // // position: absolute;
      // // width: 100%;
      // // height: 100%;
      // // top: 50%;
      // // left: 50%;
      // // transform: translateX(-50%) translateY(-50%);
      // // z-index: 1;
      // // border-radius: 50%;
      // animation: beacon-shipping 1.5s infinite linear;
      // animation-fill-mode: forwards;
      //
      // &:before {
      //   content: '';
      //   position: absolute;
      //   width: 50%;
      //   height: 50%;
      //   top: 50%;
      //   left: 50%;
      //   transform: translateX(-50%) translateY(-50%);
      //   background: #4bc0fe;
      //   border-radius: 50%;
      //   // transition: all 1.5s ease;
      //   animation: beacon-accent 1.5s infinite linear;
      //   animation-fill-mode: forwards;
      // }
      // &:after {
      //   content: '';
      //   width: 50%;
      //   height: 50%;
      //   position: absolute;
      //   top: 50%;
      //   left: 50%;
      //   transform: translateX(-50%) translateY(-50%);
      //   border: 3px solid #4bc0fe;
      //   border-radius: 50%;
      //
      //   animation: beacon 1.5s infinite linear;
      //   animation-fill-mode: forwards;
      // }
    }
  }
}

.shipping-tracking.shipping-tracking-product {
  .shipping-line.inactive { hr {  } }
  // .shipping-line.pending { hr { background: #fd8d31; } }
  .shipping-line.pending { hr { background: #4bc0fe; } }
  // .shipping-line.visited { hr { background: #fd8d31; } }
  .shipping-line.visited { hr { background: #4bc0fe; } }
  .shipping-line.finished { hr { background: #00c17b; } }
  .shipping-line.failed { hr { background: #fc417a; } }
  .shipping-stage.inactive { .shipping-stage-dot {  } }
  .shipping-stage.visited {
    .shipping-stage-dot { background: #4bc0fe; }
    .shipping-stage-accent { background: rgba(0, 114, 206, 0.42); }
  }
  .shipping-stage.pending {
    .shipping-stage-dot { background: #4bc0fe; }
    .shipping-stage-accent { background: rgba(0, 114, 206, 0.42); }
  }
  .shipping-stage.finished {
    // .shipping-stage-dot { background: #00c17b; }
    // .shipping-stage-accent { background: rgba(0, 193, 123, 0.42); }
    .shipping-stage-dot { background: #00c17b; }
    .shipping-stage-accent { background: rgba(0, 114, 206, 0.42); }
  }
  .shipping-stage.failed {
    .shipping-stage-dot { background: #fc417a; }
    .shipping-stage-accent { background: rgba(252, 65, 122, 0.42); }
  }
}

.shipping-tracking.shipping-tracking-game {
  .shipping-line.inactive { hr {  } }
  // .shipping-line.pending { hr { background: #fd8d31; } }
  .shipping-line.pending { hr { background: #4bc0fe; } }
  // .shipping-line.visited { hr { background: #fd8d31; } }
  .shipping-line.visited { hr { background: #4bc0fe; } }
  .shipping-line.finished { hr { background: #00c17b; } }
  .shipping-line.failed { hr { background: #fc417a; } }
  .shipping-stage.inactive { .shipping-stage-dot {  } }
  .shipping-stage.visited {
    .shipping-stage-dot { background: #4bc0fe; }
    .shipping-stage-accent { background: rgba(0, 114, 206, 0.42); }
  }
  .shipping-stage.pending {
    .shipping-stage-dot { background: #4bc0fe; }
    .shipping-stage-accent { background: rgba(0, 114, 206, 0.42); }
  }
  .shipping-stage.finished {
    // .shipping-stage-dot { background: #00c17b; }
    // .shipping-stage-accent { background: rgba(0, 193, 123, 0.42); }
    .shipping-stage-dot { background: #00c17b; }
    .shipping-stage-accent { background: rgba(0, 114, 206, 0.42); }
  }
  .shipping-stage.failed {
    .shipping-stage-dot { background: #fc417a; }
    .shipping-stage-accent { background: rgba(252, 65, 122, 0.42); }
  }
}

.shipping-tracking.shipping-tracking-restaurant {
  .shipping-line.inactive { hr {  } }
  // .shipping-line.pending { hr { background: #fd8d31; } }
  .shipping-line.pending { hr { background: #4bc0fe; } }
  // .shipping-line.visited { hr { background: #fd8d31; } }
  .shipping-line.visited { hr { background: #4bc0fe; } }
  .shipping-line.finished { hr { background: #00c17b; } }
  .shipping-line.failed { hr { background: #fc417a; } }
  .shipping-stage.inactive { .shipping-stage-dot {  } }
  .shipping-stage.visited {
    .shipping-stage-dot { background: #4bc0fe; }
    .shipping-stage-accent { background: rgba(0, 114, 206, 0.42); }
  }
  .shipping-stage.pending {
    .shipping-stage-dot { background: #4bc0fe; }
    .shipping-stage-accent { background: rgba(0, 114, 206, 0.42); }
  }
  .shipping-stage.finished {
    // .shipping-stage-dot { background: #00c17b; }
    // .shipping-stage-accent { background: rgba(0, 193, 123, 0.42); }
    .shipping-stage-dot { background: #00c17b; }
    .shipping-stage-accent { background: rgba(0, 114, 206, 0.42); }
  }
  .shipping-stage.failed {
    .shipping-stage-dot { background: #fc417a; }
    .shipping-stage-accent { background: rgba(252, 65, 122, 0.42); }
  }
}

// .blinking-dot {
//   position: relative;
//   display: inline-block;
//   margin-left: 15px;
//
//   .shipping-stage {
//     width: 12px;
//
//     .shipping-stage-inner {
//       height: 12px;
//     }
//
//     .shipping-stage-dot {
//       transition: all 1.15s ease;
//       animation-name: blinking-dot;
//       animation-duration: 1.75s;
//       animation-iteration-count: infinite;
//       background: #00c17b;
//     }
//     // .shipping-stage-accent { background: rgba(0, 193, 123, 0.42); }
//     .shipping-stage-accent { background: rgba(0, 193, 123, 0.42); }
//   }
// }

.blinking-dot-tile {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 145px;
  right: 35px;
  z-index: 3;
}

.blinking-dot {
  position: relative;
  display: inline-block;
  width: 17.5px;
  height: 17.5px;
  margin-left: 15px;

  .blinking-dot-container {
    position: relative;
    width: 20px;
    height: 20px;


    .blinking-dot-dot {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: #dd90fd;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      margin: 0;
      border: 0;
      border-radius: 50%;
      padding: 0;
      outline: 0;
      animation: beacon-dot 1.5s infinite linear;
      animation-fill-mode: forwards;

      &:before {
        content: '';
        width: 50%;
        height: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        // transform: translateX(-50%) translateY(-50%);
        background: #dd90fd;
        border-radius: 50%;
        // transition: all 1.5s ease;
        animation: blinking-accent 1.5s infinite linear;
        animation-fill-mode: forwards;
      }
      &:after {
        content: '';
        width: 50%;
        height: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        border: 3px solid #dd90fd;
        border-radius: 50%;

        animation: blinking 1.5s infinite linear;
        animation-fill-mode: forwards;
      }
    }
  }

  @keyframes beacon-shipping {
    0%   {
      width: 75%;
      height: 75%;
      opacity: 0.85;
    }
    25%  {
      width: 100%;
      height: 100%;
      opacity: 1;
    }
    50% {
      width: 100%;
      height: 100%;
      opacity: 1;
    }
    75% {
      width: 100%;
      height: 100%;
      opacity: 1;
    }
    100% {
      width: 100%;
      height: 100%;
      opacity: 1;
    }
  }

  @keyframes beacon-dot {
    0%   {
      width: 25%;
      height: 25%;
      opacity: 0.55;
    }
    25%  {
      width: 87.5%;
      height: 87.5%;
      opacity: 0.8;
    }
    50% {
      width: 87.5%;
      height: 87.5%;
      opacity: 1;
    }
    75% {
      width: 87.5%;
      height: 87.5%;
      opacity: 0.5;
    }
    100% {
      width: 87.5%;
      height: 87.5%;
      opacity: 0;
    }
  }

  @keyframes blinking {
    0%   {
      width: 50%;
      height: 50%;
      opacity: 1;
    }
    25%  {
      width: 100%;
      height: 100%;
      opacity: 0.7;
    }
    50%  {
      width: 150%;
      height: 150%;
    }
    75%  {
      width: 200%;
      height: 200%;
      opacity: 0.45;
    }
    100% {
      width: 250%;
      height: 250%;
      opacity: 0;
    }
  }

  @keyframes blinking-accent {
    0%   {
      width: 50%;
      height: 50%;
      opacity: 1;
    }
    25%  {
      width: 100%;
      height: 100%;
      opacity: 0.6;
    }
    50%  {
      width: 150%;
      height: 150%;
    }
    75%  {
      width: 200%;
      height: 200%;
      opacity: 0.3;
    }
    100% {
      width: 250%;
      height: 250%;
      opacity: 0;
    }
  }

  @keyframes beacon {
    0%   {
      width: 75%;
      height: 75%;
      opacity: 1;
    }
    25%  {
      width: 100%;
      height: 100%;
      opacity: 0.7;
    }
    50%  {
      width: 125%;
      height: 125%;
    }
    75%  {
      width: 150%;
      height: 150%;
      opacity: 0.45;
    }
    100% {
      width: 175%;
      height: 175%;
      opacity: 0;
    }
  }

  @keyframes beacon-accent {
    0%   {
      width: 50%;
      height: 50%;
      opacity: 1;
    }
    25%  {
      width: 100%;
      height: 100%;
      opacity: 0.6;
    }
    50%  {
      width: 125%;
      height: 125%;
    }
    75%  {
      width: 150%;
      height: 150%;
      opacity: 0.3;
    }
    100% {
      width: 175%;
      height: 175%;
      opacity: 0;
    }
  }

  @keyframes beacon-small {
    0%   {
      width: 100%;
      height: 100%;
      opacity: 1;
    }
    20%  {
      width: 115%;
      height: 115%;
      opacity: 0.7;
    }
    40%  {
      width: 130%;
      height: 130%;
    }
    60%  {
      width: 145%;
      height: 145%;
      opacity: 0.45;
    }
    80% {
      width: 160%;
      height: 160%;
      opacity: 0;
    }
    100% {
      width: 160%;
      height: 160%;
      opacity: 0;
    }
  }

  @keyframes beacon-accent-small {
    0%   {
      width: 100%;
      height: 100%;
      opacity: 1;
    }
    20%  {
      width: 115%;
      height: 115%;
      opacity: 0.6;
    }
    40%  {
      width: 130%;
      height: 130%;
    }
    60%  {
      width: 145%;
      height: 145%;
      opacity: 0.3;
    }
    80% {
      width: 160%;
      height: 160%;
      opacity: 0;
    }
    100% {
      width: 160%;
      height: 160%;
      opacity: 0;
    }
  }

  // @keyframes beacon {
  //   0%   {
  //     width: 0;
  //     height: 0;
  //     opacity: 1;
  //   }
  //   25%  {
  //     width: 10px;
  //     height: 10px;
  //     opacity: 0.7;
  //   }
  //   50%  {
  //     width: 20px;
  //     height: 20px;
  //   }
  //   75%  {
  //     width: 30px;
  //     height: 30px;
  //     opacity: 0.5;
  //   }
  //   100% {
  //     width: 40px;
  //     height: 40px;
  //     opacity: 0;
  //   }
  // }
  //
  // @keyframes beacon-accent {
  //   0%   {
  //     width: 0;
  //     height: 0;
  //     opacity: 1;
  //   }
  //   25%  {
  //     width: 10px;
  //     height: 10px;
  //     opacity: 0.6;
  //   }
  //   50%  {
  //     width: 20px;
  //     height: 20px;
  //   }
  //   75%  {
  //     width: 30px;
  //     height: 30px;
  //     opacity: 0.35;
  //   }
  //   100% {
  //     width: 40px;
  //     height: 40px;
  //     opacity: 0;
  //   }
  // }
}



// @keyframes blinking-dot {
//   0% {
//     width: 100%;
//     height: 100%;
//     top: 0%;
//     left: 0%;
//     opacity: 0.4;
//   }
//   50% {
//     width: 125%;
//     height: 125%;
//     top: 12.5%;
//     left: 12.5%;
//     opacity: 0.65;
//   }
//   100% {
//     width: 100%;
//     height: 100%;
//     // top: -25%;
//     // left: -25%;
//     opacity: 0.4;
//   }
// }

@keyframes blinking-accent {
  0% {
    width: 100%;
    padding-bottom: 100%;
    top: 0%;
    left: 0%;
    opacity: 1;
  }
  50% {
    width: 175%;
    padding-bottom: 175%;
    top: -37.5%;
    left: -37.5%;
    opacity: 0;
  }
  100% {
    width: 100%;
    padding-bottom: 100%;
    top: 0%;
    left: 0%;
    opacity: 0;
  }
}

@media (max-width: 550px){
  .hashtags-container {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;

    .hashtag:first-child {
      margin-bottom: 6px;
    }
  }

  .game-header {
    height: 160px;
  }

  .slide-out-incentives {
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-wrap: wrap;

    .slide-out-incentives-box {
      width: auto !important;
      margin: 0 10px !important;
    }
  }

  .submission-post {
    max-width: 100%;

    div.row > div {
      padding: 10px;
    }

    .submission-increment {
      display: flex;
      justify-content: flex-end;
    }
  }

  .menu-checkout button.menu-checkout-btn {
    width: 100%;
    border-radius: 0;
    margin: 0;
  }
}

.content-for-review {
  > div:not(:last-child) {
    margin-bottom: 20px;
  }

  &.disabled {
    opacity: 0.6;
    pointer-events: none;
  }

  .add-content-link {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    &:hover {
      cursor: pointer;
    }

    .review-link-add {
      width: 65px;
      height: 65px;
      border: 1px solid #808080;
      border-radius: 8px;
      position: relative;
      margin-right: 12px;

      img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        // margin-right: 10px;
        height: 12px;
        width: 12px;
      }
    }


  }
  .platform-grid, .content-type-grid {
    display: flex;
    flex-wrap: wrap;

    > *:not(:last-child) {
      margin-right: 10px;
    }
  }

  .platform-tile, .content-type-tile {
    background-color: #eeeeee;
    border: 1px solid #eeeeee;

    &.active {
      border: 1px solid rgb(1, 211, 109);
      background-color: transparent;
    }
  }
  .platform-tile {
    padding: 10px;
    border-radius: 5px;

    img {
      height: 20px;
      width: 20px;
    }
  }

  .content-type-tile {
    padding: 5px 10px;
    border-radius: 5px;
    font-weight: 500;
  }

  textarea, .review-input {
    background-color: #eeee;
    border-radius: 5px;
  }
  textarea:disabled,
  input:disabled {
    opacity: 0.5;
  }

  textarea {
    padding: 10px;
    height: 100px;
  }

  .review-input {
    padding: 5px 10px;
  }

  .upload-file-toggle {
    font-size: 12px;
    color: #808080;
    &:hover {
      color: black;
    }
  }

  .actions {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    > * {
      margin-right: 10px;
    }
  }

  .preview-img {
    height: 100px;
    width: 100px;
    border-radius: 6px;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #eee;
  }

  .dzu-dropzone {
    display: flex;
    width: fit-content;
    margin-bottom: 5px;

    input {
      display: none;
    }

    &:hover, label:hover {
      cursor: pointer;
    }

    .dzu-previewContainer {
      width: 100px;
    }

    .dzu-previewImage, label {
      height: 100px;
      width: 100px;
      border-radius: 6px;
    }

    .dzu-inputLabelWithFiles {
      content: '';
    }

    .dzu-previewStatusContainer {
      progress {
        max-width: 100%;
      }
    }

    label {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #eee;
    }

    label::before {
      content: '';
      background-image: url("../../images/icons/upload-black.svg");
      background-size: 20px;
      background-position: center;
      background-repeat: no-repeat;
      height: 25px;
      width: 25px;
      opacity: 0.2;
    }
  }

  .error-msg {
    margin-left: 0;
  }

  .twitch-msg {
    margin-bottom: 20px;
  }

  button.add-content {
    background-color: transparent;
    padding: 5px 10px;
    border-radius: 5px;
    border: 1px solid black;

    &:disabled {
      opacity: 0.5;
    }

    &.green {
      border-color: rgb(0, 128, 96);
      background: rgb(0, 128, 96);
      color: white;
    }
  }

  .review-link-preview {
    position: relative;
    display: inline-block;
    float: left;
    margin: 0 12px 0 0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    // background-image: url('https://blog.mysubscriptionaddiction.com/wp-content/uploads/2021/06/14/Insta_072420_1.jpg');
    width: 65px;
    height: 65px;
    border-radius: 8px;
  }

  .review-link-item {
    .review-link-row {
      display: flex;
      align-items: center;
      justify-content: space-between;

      > *:not(:last-child) {
        margin-right: 5px;
      }
    }

    .edit-btn {
      opacity: 0;
      pointer-events: none;

      img {
        height: 18px;
        width: 18px;
      }
    }

    &:hover {
      .edit-btn {
        opacity: 0.5;
        pointer-events: all;
        transition: opacity 200ms ease-in-out;

        &:hover {
          opacity: 1;
        }
      }
    }
  }

  .edit-btn {
    background-color: transparent;
    border: none;
  }
}
