.invite-block {
  padding: 25px 0px;
  text-align: initial;

  div.sub-text {
    text-align: left;
  }

  h4 {
    margin: 0px;
    font-size: 18px !important;
  }

  border-top: 1px solid #eee;
  //border-bottom: 1px solid red;

  .header-row {
    margin-bottom: 20px;

    display: flex;
    flex-direction: row;
    align-items: center;

    .action {
      margin-left: auto;
    }

    .icon {
      width: 20px;
    }
  }

  .column {
    display: flex;
    align-items: center;
  }


  .invite-btn {
    padding: 6px 12px;
    border-radius: 17.5px;
    border: solid 1px #8f8f8f;
    background: transparent;
  }

}

.invite-block-icon {
  position: relative;
  display: inline-block;
  background: #e5e5e5;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  float: left;
  margin: -6px 10px 0 0;

  .invite-block-img {
    position: absolute;
    width: 16px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
  }
}


.invite-block {
  position: relative;

  .action-arrow {
    position: absolute;
    margin: auto 0;
    top: 0;
    bottom: 0;
    right: 0;
  }
}
