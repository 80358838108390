.dynamic-fields {
  padding: 0;
  list-style: none;

  li {
    padding: 4px 0;
    font-family: Barlow;
    font-weight: 500;

    .dark-input {
      cursor: pointer;
    }

    input {
      position: absolute;
      opacity: 0;
      z-index: -1;
    }
  }
}

.message-template {

  label {
    font-family: Barlow;
    font-weight: 500;
    font-size: 16px;
    margin: 0;
  }

  .message-template-stats {
    padding: 0;
    width: 650px;

    .message-template-stats-inner {
      max-width: 650px;
      text-align: center;

      .col-md-2 {
        border-top: 3px solid rgba(0,0,0,0);
        padding: 15px;
        transition: all 0.32s ease;
        cursor: pointer;
      }

      .col-md-2.active, .col-md-2:hover {
        border-color: #019f6e;
        background: #fafafa;
      }


      h3 {
        font-size: 26px;
        padding: 0;
        margin-top: 12px;
      }
    }
  }
}

.create-message-days {
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;

  .create-message-day {
    flex: 0 0 auto;
    min-width: 0;
    min-height: 0;
    margin-right: 10px;

    input {
      margin-top: 0;
      margin-right: 5px;
    }
    span {
      font-family: Barlow;
    }
  }
}

.drip-bounty-assignment {
  // padding: 25px;
  font-family: Barlow !important;

  .drip-bounty-title {
    background: none;
    border: none;
    border-bottom: 1px dashed #eee;
    margin: 0;
  }
  .drip-bounty-title:focus {
    border-color: #bbb;
  }

  .drip-bounty-sections {

    .drip-bounty-section {
      // padding: 0 75px;

      .drip-bounty-section-inner {
        position: relative;
        display: block;
        width: 228px;
        // margin: 0 auto;
      }
    }
  }

  .drip-bounty-meta {
    position: relative;
    display: block;
    width: 100%;
    height: 165px;
    margin: 15px auto;

    .drip-bounty-influencer {
      padding: 0 6px 6px 0;

      .drip-bounty-influencer-img {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
      }

    }
  }
}
