.standalone-invite {
    font-family: 'Circular Std Book', sans-serif;
    //font-family: 'Circular Std Book Italic', sans-serif;
    //font-family: 'Circular Std Medium', sans-serif;
    //font-family: 'Circular Std Medium Italic', sans-serif;

  h1, h2, h3, h4, h5 {
    // font-family: 'Circular Std Bold', sans-serif;
    font-family: Barlow, sans-serif;
    font-weight: bold;
  }

  .sub-text {
    font-size: 14px;
  }
    //font-family: 'Circular Std Bold Italic', sans-serif;
    //font-family: 'Circular Std Black', sans-serif;
    //font-family: 'Circular Std Black Italic', sans-serif;


}
