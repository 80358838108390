.social-header {
  position: relative;
  padding: 20px 10px 20px 50px;
  background: rgb(254, 255, 255);
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 2px 9px 0px;
}
.social-header .color-bar {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 15px;
  background: purple;
}
.social-header .social-label {
  position: relative;
  width: 100%;
  height: 50px;

  img {
    width: 30px;
    margin-left: 15px;
    margin-top: 9px;
  }
}
.social-header .social-img {
  display: inline-block;
  width: 50px;
  float: left;
}
.social-header .platform-label {
  display: inline-block;
  margin: 0;
  font-size: 22px;
  font-weight: 600;
  margin-left: 15px;
  color: black;
  overflow: hidden;
  line-height: 50px;
  float: left;
}
.social-header .video-tile-container {
  float: left;
  display: inline-block;
  width: 25%;
  padding: 3px;
  cursor: pointer;
}
.social-header .video-tile-img-container {
  position: relative;
  width: 100%;
  overflow: auto;
}
.social-header .video-tile-img {
  display: block;
  width: 100%;
  margin: 0 auto;
}
.social-header .video-title {
  display: inline-block;
  width: 100%;
  margin: 8px 0;
  font-size: 16px;
  font-weight: 400;
  padding: 0;
  color: black;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  height: 40px;
}
.social-header .video-tile-container:hover .video-title {
  color: #2793e6;
}
.social-header .video-date {
  color: #9d9d9d;
}
.social-header .video-tile-container:hover .video-date {
  color: #444343;
}
.social-header .social-change {
  position: absolute;
  top: 25px;
  right: 25px;
  cursor: pointer;
  color: #9a9a9a;
}
.social-header .social-change:hover {
  color: black;
}

.tips {
  position: relative;
  padding: 35px 25px;
  background: rgb(254, 255, 255);
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 2px 9px 0px;
  margin-top: 10px;
}
.tips li {
  margin: 8px 0;
}

.search-container {
  border: none;
  box-shadow: none;
  margin-top: 0;
  background: none;
}
.list-links-header {
  position: relative;
  display: block;
  width: 100%;
  padding: 25px 15px;
}
.list-links {
  padding: 0;
  list-style: none;
  background: rgb(254, 255, 255);
  /*border: 1px solid rgba(0, 0, 0, 0.06);*/
  /*border-radius: 3px;*/
  /*box-shadow: rgba(0, 0, 0, 0.02) 0px 2px 9px 0px;*/
}
.list-links li {
  list-style: none;
  background: white;
  border-bottom: 1px solid #eaeaea;
  padding: 15px 30px;
  transition: height 0.25s ease;
  height: 125px;
  overflow: hidden;
  /*cursor: pointer;*/
}
.list-links li:hover {
  /*background: #f9f9f9;*/
}
.list-links li.active {
  background: #f9f9f9;
  height: 125px;
}
.dark {
  position: absolute;
  /*opacity: 0.6;*/
  opacity: 0;
  transform: scale(0.6);
  transition: all 0.35s ease;
  transition-delay: 1s;
}

.i1 {
  top: 15px;
  left: 130px;
}
.i2 {
  top: 75px;
  left: 15px;
}
.i3 {
  top: 35px;
  left: 180px;
}
.i4 {
  top: 45px;
  left: 300px;
}
.i5 {
  top: 55px;
  left: 230px;
}
.i6 {
  top: 5px;
  left: 400px;
}
.label-section {
  position: relative;
  display: block;
  overflow: auto;
  width: 100%;
  padding: 7px 0;
  margin-top: 25px;
}
.list-links .label-section {
  padding: 0;
  margin: 0;
}
.label-container {
  font-size: 16px;
  line-height: 24px;
  width: 256px;
  height: 72px;
  display: inline-block;
  position: relative;
  background-color: transparent;
  transition: height 200ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  cursor: auto;
}
.list-links .label-container {
  font-size: 14px;
  width: 100%;
  height: 35px;
}
.label-for-input {
  position: absolute;
  line-height: 22px;
  top: 38px;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  z-index: 1;
  transform: scale(0.75) translate(0px, -28px);
  transform-origin: left top 0px;
  pointer-events: none;
  user-select: none;
  color: rgb(218, 218, 218);
  /*font-weight: 400;*/
  font-weight: 600;
  text-transform: uppercase;
}
.label-input {
  padding: 0px;
  position: relative;
  width: 100%;
  border: none;
  outline: none;
  background-color: rgba(0, 0, 0, 0);
  color: rgba(0, 0, 0, 0.87);
  cursor: inherit;
  font-style: inherit;
  font-variant: inherit;
  font-weight: inherit;
  font-stretch: inherit;
  font-size: inherit;
  line-height: inherit;
  font-family: inherit;
  /*-webkit-text-fill-color: rgba(0, 0, 0, 0.87);*/
  /*-webkit-text-fill-color: rgb(255, 173, 173);*/
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  height: 100%;
  box-sizing: border-box;
  margin-top: 14px;
  -webkit-appearance: textfield;
}
.list-links .label-input {
  height: initial;
  margin: 0;
}
.label-input::placeholder {
  color: rgb(255, 173, 173);
}
.input-fill hr.back {
  border-top: none rgb(239, 239, 239);
  border-left: none rgb(239, 239, 239);
  border-right: none rgb(239, 239, 239);
  border-bottom: 1px solid rgb(239, 239, 239);
  bottom: 8px;
  box-sizing: content-box;
  margin: 0px;
  position: absolute;
  width: 100%;
}
.input-fill hr.fill {
  border-top: none rgb(0, 188, 212);
  border-left: none rgb(0, 188, 212);
  border-right: none rgb(0, 188, 212);
  border-bottom: 2px solid rgb(9, 132, 191);
  bottom: 8px;
  box-sizing: content-box;
  margin: 0px;
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}
.input-fill hr.saved {
  border-top: none #18ce18;
  border-left: none #18ce18;
  border-right: none #18ce18;
  border-bottom: 2px solid #18ce18;
  bottom: 8px;
  box-sizing: content-box;
  margin: 0px;
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}
.input-fill hr.fill.active {
  transform: scaleX(1);
}
.input-fill hr.saved.active {
  transform: scaleX(1);
}
.list-links-header button {
  position: relative;
  font-weight: 500;
  font-size: 16px;
  font-family: Barlow;
  padding: 6px 12px;
  width: 115px;
  display: inline-block;
  float: right;
  outline: none;
  line-height: initial;
  border-radius: 50px;
  text-align: center;
  color: white;
  transition: box-shadow 0.2s ease, background-color 0.2s ease, color 0.2s ease;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 18px 18px;
  border: 1px solid #ff8e31;
  /*background-color: #ff8e31;*/
  color: #ff8e31;
  background: none;
  cursor: pointer;
  margin: 0 3px;
  border: 1px solid #ff8e31;
  color: white;
  background: #ff8e31;
}

.convert-links-header {
  position: relative;
  padding: 23px 18px 23px 30px;
  border-bottom: 1px solid #eaeaea;
  margin: 0;
}
.convert-links-header h4 {
  margin: 0;
}

.list-links .name {
  color: #585858;
  margin-bottom: 3px;
  font-size: 14px;
}
.list-links .link {
  /*color: #505050;*/
  color: #ee6123;
  font-size: 13px;
  margin: 0;
  font-weight: 500;
  background: none;
  border: none;
  cursor: text;
  display: initial;
  padding: 0;
  width: initial;
}
/*.list-links .link.copied {
  color: green !important;
}*/
.list-links .upper {
  position: relative;
  display: block;
  width: 100%;
}
.list-links .lower {
  position: relative;
  display: block;
  width: 100%;
  margin-top: 7px;
}
.list-links img {
  display: block;
  margin: 0 auto;
  max-width: 50px;
  max-height: 50px;
  border-radius: 3px;
  /*margin-top: 5px;*/
}
.list-links .lower button {
  outline: none;
  background: none;
  border: 1px solid red;
  border-radius: 3px;
  color: red;
}
.main-container .search-container {
  min-height: 250px;
  /*margin-bottom: 10px;*/
  border-radius: 3px;
  /*overflow: auto;*/
}
.main-container .search-box {
  min-height: 150px;
  z-index: 10;
  padding: 20px 35px;
}
.main-container .search-box.search.active {
  background: white;
  background-color: transparent !important;
}
.main-container .search-box-btn {
  position: relative;
  bottom: initial;
  display: inline-block;
  font-family: Barlow;
  width: 115px;
  /*float: right;*/
  margin: 0;
  border-radius: 50px;
  border: none;
  /* background-image: linear-gradient(250deg, #e94d47, #ed716c); */
  /*border: 1px solid #ff8e31;*/
  /*background-color: #ff8e31;*/
  background: none;
  border: 1px solid #ff8e31;
  text-shadow: none;
  /*box-shadow: 0 2px 3px -1px rgba(100, 100, 100, 0.5);*/
  box-shadow: none;
  /*text-shadow: 1px 1px rgba(0,0,0,.1);*/
  padding: 10px 16px 9px;
  font-size: 16px;
  line-height: 1.42857;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  text-align: center;
  /* color: #ff8e31 !important; */
  outline: 0;
  transition: all 0.25s ease;
  user-select: none;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  font-size: 16px;
  padding: 6px 12px;
  border: 1px solid #ff8e31;
  color: white;
  background: #ff8e31;
	font-weight: 500;
}

.search-module {
  /*border-radius: 3px;
  box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.02);
  border: 1px solid rgba(0, 0, 0, 0.06);
  background: white;*/
}

.youtube-des-container {
  border-radius: 3px;
  box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.02);
  border: 1px solid rgba(0, 0, 0, 0.06);
  background: white;
  margin-top: 10px;
  padding: 10px;
}
.youtube-des-header {
  border-bottom: 1px solid #eaeaea;
}
.copy-link-btn {
  position: relative;
  font-weight: 500;
  font-size: 14px;
  padding: 6px 12px;
  display: inline-block;
  float: right;
  outline: none;
  line-height: initial;
  border-radius: 3px;
  text-align: center;
  color: white;
  /*transition: box-shadow 0.2s ease, background-color 0.2s ease, color 0.2s ease;*/
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 18px 18px;
  border: 1px solid #ff8e31;
  /*background-color: #ff8e31;*/
  background: none;
  cursor: pointer;
  margin: 0 3px;
}
.icon-img {
  display: inline-block;
  margin-right: 10px;
  border: 1px solid #ff8e31;
  background-color: #ff8e31;
  box-shadow: 0 2px 3px -1px rgba(100, 100, 100, 0.5);
  text-shadow: 1px 1px rgba(0,0,0,.1);
  padding: 9px 16px 9px;
  border-radius: 3px;
  width: 50px;
}
.search-ul-container {
  max-height: 420px;
  overflow: scroll;
}
.search-container.search-active .search {
  display: none;
}
.search-dropdown {
  display: none;
  position: relative;
  z-index: 99999;
  /*top: 78px;*/
  top: -1px;
  left: 0;
  background: white;
  background: transparent;
  width: 100%;
  min-height: 250px;
  /*border: 1px solid #ddd;*/
  /*max-height: 395px;
  overflow: scroll;*/
}
.search-dropdown.active {
  display: block;
}
.placeholder {
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  height: 20px;
  font-weight: 600;
}
.hidden {
  display: none !important;
}
.search-dropdown ul {
  padding: 0;
  list-style: none;
  /*background: rgb(254, 255, 255);
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 2px 9px 0px;*/
}
.search-dropdown li {
  list-style: none;
  background: white;

  border-bottom: 1px solid #eaeaea;
  padding: 10px 30px;
  transition: height 0.25s ease;
  /*height: 72px;*/
  overflow: hidden;
}
.search-dropdown li:hover, .search-dropdown li:hover p {
  /*background: #f9f9f9;*/
  background: #489aff;
  cursor: pointer;
  color: white !important;
}
.search-icon {
  margin-right: 15px;
  cursor: pointer;
}
.search-icon.border {
  border: 1px solid #ff8e31;
  padding: 7px;
  border-radius: 3px;
  width: 32px;

  border: 1px solid #ff8e31;
  color: white;
  background: #ff8e31;
}
.search-overlay {
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: white;
  background: transparent;
  z-index: 99999;
  border-bottom: 1px solid #eee;
}
.search-active .search-overlay {
  display: block;
}
.search-overlay img {
  position: absolute;
  top: 27px;
  left: 21px;
  z-index: 9;
  width: 22px;
}
.search-overlay input {
  position: absolute;
  width: 100%;
  height: 65px;
  top: 0;
  left: 0;
  padding-left: 60px;
  padding-right: 60px;
  border: none;
  font-size: 16px;
}

.search-overlay span {

}
.result-row img {
  display: block;
  width: 100%;
  height: 100%;
  max-width: 70px;
  max-height: 70px;
  margin: 0 auto;
}
.result-row .item-name {
  margin: 10px 0 0 0;
}
.result-row .item-domain {
  text-align: left;
  /*margin-top: 15px;*/
}
.result-row .add-btn-center {
  text-align: center;
}
.search-example-container {
  position: relative;
  height: 100%;
}
.search-example-container .search-example {
  position: absolute;
  width: 100%;
  height: 70px;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.search-example-container .search-example img {
  position: absolute;
  margin: auto;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}
.search-example-container .search-example p {
  text-align: center;
  margin-top: 15px;
}
.search-example-container .search-example .example-img {
  position: relative;
  height: 40px;
  width: 100%;
}
.original-url {
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  height: 40px;
  max-height: 40px;
}

.link-menu-container {
  position: absolute;
  top: -10px;
  right: -10px;
  z-index: 10;
}
.link-menu-ellipsis {
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.6px;
  color: #484848;
  border-radius: 5px;
  cursor: pointer;
}
.link-menu-ellipsis:hover {
  color: rgba(21, 171, 255, 0.74);
}
.link-menu-container.active .link-menu-ellipsis {
  color: #15abff;
}
.link-menu {
  position: absolute;
  display: none;
  top: 30px;
  right: -8px;
  width: 115px;
  /*height: 60px;*/
  border-radius: 5px;
  background-color: #f5f4f9;
  padding: 0;
  overflow: hidden;
  box-shadow: 0 0 5px -2px #191919;
}
.link-menu-container.active .link-menu {
  display: block;
}
.list-links .link-menu li {
  height: initial;
  padding: 0 0 0 15px;
  text-align: left;
  line-height: 38px;
  border-bottom: 1px solid #eee;
  color: #7f7f7f;
  cursor: pointer;
}
.list-links .link-menu li:hover {
  background: #eee;
}
.copy-link-module {
  border: 1px solid #ee6123;
  border-radius: 3px;
  padding: 4px 6px;
  margin-left: 7px;
  font-size: 10px;
  color: #ee6123;
  cursor: pointer;
}
.copy-link-module:hover {
  background: #ee6123;
  color: white;
}
.coupon-popup-container {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 999999999999;
}
.coupon-popup {
  position: absolute;
  width: 550px;
  height: 650px;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: white;
  border-radius: 8px;
  overflow: hidden;
}
.coupon-popup-padding {
  position: relative;
  width: 550px;
  height: 650px;
}
.coupon-popup-header {
  position: relative;
  width: 100%;
  height: 60px;
  border-bottom: 1px solid #eee;
  line-height: 60px;
}
.coupon-popup-header p {
  font-size: 18px;
  margin: 0;
  padding-left: 25px;
  font-weight: 600;
}
.coupon-popup-header span {
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  right: 25px;
  cursor: pointer;
  font-size: 24px;
}
.coupon-list {
  position: relative;
  height: 590px;
  width: 100%;
  padding: 0;
  list-style: none;
  margin: 0;
  overflow: auto;
}
.coupon-list li {
  margin: 0;
  padding: 15px 25px;
  border-bottom: 1px solid #eee;
  list-style: none;
}
.coupon-list li .coupon-dates {
  color: #a4a4a4;
  font-size: 12px;
  margin: 10px 0 0 0;
}
.product-image-choose {
  position: absolute;
  max-width: 90%;
  max-height: 90%;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
}
.like-dislike-container {
  position: relative;
  width: 100%;
  height: 2px;
  background: #88888866;
  margin-top: 8px;
}
.likes-bar {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 2px;
  background: #2793e6;
}
.yt-thumb {
  text-align: center;
}
.yt-thumb img {
  position: relative;
  display: inline-block;
  width: 20px;
  opacity: 0.35;
}
.yt-thumb-dislike img {
  top: 3px;
  transform: rotate(180deg);
}
.yt-thumb p {
  position: relative;
  display: inline-block;
  padding: 5px;
  color: #888888;
  font-weight: 500;
  top: 4px;
  left: 7px;
}
.social-tile {
  // border: 1px solid #fff;
  border-radius: 3px;
  padding: 15px 10px;
  height: 200px;
  cursor: pointer;
  transition: all 0.3s ease;
}
.social-tile:hover {
  box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.02);
  // border: 1px solid rgba(0, 0, 0, 0.06);
  // background: white;
  // background: #35393e;
}
#update-yt-description {
  display: block;
  border: 1px solid #ff8e31;
  color: white;
  background: #ff8e31;
  padding: 6px 12px;
  cursor: pointer;
  float: right;
  top: 0;
}
