$image-size: 70px;


.invite-product-preview, .invite-products {

  .price {
    opacity: 0.5;
  }

  .product-image {
    width: $image-size;
    height: $image-size;
    background-size: $image-size $image-size;
    background-position: center center;
    margin-right: 10px;
    border-radius: 8px;
  }

  .selected {
    background-color: #15abff26;
  }

  .invite-product-preview-row {
    display: flex;
    flex-direction: row;
    padding: 10px 20px 10px 20px;
    text-align: left;

    .heading {
      padding-top: 20px;
    }

    &.selectable-product {
      cursor: pointer;
    }
    &.selectable-product:hover {
      background-color: #15abff26;
    }
  }


  .product-description {
    align-items: flex-start;
  }
}

.invite-product-preview {
  .invite-product-preview-row {
    padding: 0;
  }
}

// todo: move
.column {
  display: flex;
  flex-direction: column;
}
