.invite-panel {
  margin: 20px 30px;
  // overflow-x: hidden;

  .title {
    font-weight: 600;
  }

  h3 {
    margin-top: 0px;
  }

  hr {
    width: 100%;
    margin-top: 20px;
  }
}
