.search-filters-module-container {
  position: relative;
  height: 100vh;
  width: calc(100% + 10px);
  padding-right: 10px;
  overflow-y: auto;
  overflow-x: hidden;
}

.search-filters-title-container {
  position: relative;
  padding: 15px 10px;

  .search-filters-title-text, .search-filters-title-filters {
    position: relative;
    display: inline-block;
    float: left;
  }
  .search-filters-title-text {
    padding-right: 12px;

    h3 {
      margin: 0;
      padding: 0;
    }
  }
  .search-filters-title-filters {
    width: 45px;

    .pill-tag.pill-tag-clear-all {
      top: -1px;
      right: 0;
      left: 0;
    }
  }
}

.pill-tag {
	position: relative;
	display: inline-block;
	float: left;
	margin: 0 6px 4px 0;
	padding: 0px 6px 0px 6px;
	border-radius: 3px;
	// background: #bee9ff;
	font-family: Barlow;
  font-size: 13px;

  .pill-tag-text {
    margin-top: 0px;
    margin-bottom: 0;
  }

	.pill-tag-delete {
		position: absolute;
		top: 7px;
		right: 6px;
		width: 8px;
		cursor: pointer;
		opacity: .82;
		transition: all 0.32s ease;
	}
	.pill-tag-delete:hover {
		opacity: 1;
	}
}

.pill-tag.pill-tag-with-delete {
  padding: 0px 20px 2px 6px;
}

.pill-tag.pill-tag-clear-all {
  position: absolute;
  right: 35px;
  top: -2px;
  background: none;
  border: 1px solid #e8eef4;
  border-radius: 50px;
  padding-left: 10px;

  span {
    color: #5d6a7e !important;
  }
}

.search-filter-container {
  position: relative;
  // padding: 12px 0;
  border-bottom: 1px solid #e8eef4;
  text-align: left;

  .search-filter-main-search {
    width: 100%;
    padding: 12px 7px 12px 32px;
    border-left: none;
    border-right: none;
    background: white;
  }
  .search-filter-main-search::placeholder {
    color: #949fb1;
  }

  .search-filter-header {
    position: relative;
    cursor: pointer;
    padding: 12px 0;

    .search-filter-header-text {
      color: #242D3E !important;
      text-align: left;

      .search-filter-header-icon-container {
        position: relative;
        display: inline-block;
        float: left;
        padding: 0 12px;
        width: 44px;
        height: 20px;

        .search-filter-header-icon {
          position: absolute;
          top: 0px;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto;
          width: 16px;
          height: 16px;

          &.icon-ampersand {
            font-size: 19px;
            line-height: 11px;
            left: -1px;
            top: 1px;
          }
        }
      }
      .search-filter-header-title {
        position: relative;
        display: inline-block;
        float: left;
      }
    }
    .search-filter-header-caret {
      text-align: right;

      img.search-filter-caret-img {
        width: 14px;
      }
    }
  }

  .search-filter-header:hover {
    background-color: #f7f9fb;
  }

  .search-filter-body {
    position: relative;
    min-height: 125px;
    padding: 12px 10px 12px 10px;

    label {
      margin-bottom: 0px;
      font-size: 13px;
      color: #252d3e;
      font-weight: 500;
    }

  }

  &.active {
    border-top: 1px solid #1991EB;
    border-bottom: 1px solid #1991EB;
    margin-top: -1px;
    margin-bottom: -1px;
    z-index: 5;

    .search-filter-header {

      .search-filter-header-text {
        color: #1991EB !important;
      }
    }
  }
}

input.dark-input.search-filter-input {
  background: white !important;
  padding: 6px;
  border-color: #d3dae3 !important;
  margin-bottom: 8px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
}

.outreach-list-dropdown {
  position: absolute;
  width: 100%;
  min-height: 125px;
  top: -10px;
  left: 0;
  background: white;
  text-align: initial;
  border: 1px solid #d3dae3;
  border-radius: 3px;
  // box-shadow: rgba(0,0,0,.32) 0px 3px 10px -4px;
  // box-shadow:0 0 2px 0 rgba(53,64,83,.4);
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.1);
  z-index: 10;
  overflow: hidden;

  .outreach-list-dropdown-inner {
    position: relative;
    background: white;
    max-height: 350px;
    overflow-x: hidden;
    overflow-y: auto;

    .outreach-list-ul {
      padding: 0;
      list-style: none;

      .outreach-list-item {
        position: relative;
        padding: 8px;
        border-bottom: 1px solid #e8eef4;
        cursor: pointer;
        background: white;
      }
      .outreach-list-item:hover {
        background: #fafafa;
      }
    }
  }
}

.crm-toggle-entity-container {
  position: relative;
  display: inline-block;
  float: left;
  margin-left: 8px;

  .crm-toggle-entity {
    position: relative;
    display: inline-block;
    float: left;
    cursor: pointer;
    text-align: center;
    width: 116px;

    .crm-toggle-entity-slider {
      position: absolute;
      width: 100%;
      height: 1px;
      left: 0;
      bottom: -1px;
      transition: all 0.32s ease;

      .crm-toggle-entity-slider-bar {
        width: 100%;
        height: 100%;
        background: #1991EB;
        border-radius: 2px;
      }
    }

    .crm-toggle-entity-inner {
      position: relative;
      padding: 4px 0 11px 0;

      .crm-toggle-entity-text {
        color: #5d6a7e;
      }

      img {
        margin-top: -3px;
        margin-right: 10px;
      }
    }
  }
  .crm-toggle-entity.active {
    .crm-toggle-entity-text { color: #1991EB; }
  }

  &.brands .crm-toggle-entity-slider { transform: translateX(0%); padding: 0 6px; }
  // &.contacts .crm-toggle-entity-slider { transform: translateX(100%); padding: 0 3px; }
  &.influencers .crm-toggle-entity-slider { transform: translateX(100%); padding: 0 0px; }
  &.lists .crm-toggle-entity-slider { transform: translateX(200%); padding: 0 10px; }
  &.saved .crm-toggle-entity-slider { transform: translateX(300%); padding: 0 0px; }
  &.insights .crm-toggle-entity-slider { transform: translateX(400%); padding: 0 3px; }
}

.crm-action-btn-container {
  position: relative;
  // box-shadow: rgba(0,0,0,.22) 0px 3px 10px -7px;
  &.crm-action-btn-4 { width: 125px; }
  &.crm-action-btn-container-extended {
    &.crm-action-btn-4 { width: 141px; }
  }

  &.crm-action-btn-container-attached {
    .crm-action-btn {
      &:first-child { border-radius: 0 0 0 3px; }
      &:last-child { border-radius: 0 0 3px 0; }
    }
  }

  .crm-action-btn {
    position: relative;
    display: inline-block;
    float: left;
    background: white;
    border: 1px solid #d3dae3;
    // border-width: 1px 1px 1px 0;
    width: 32px;
    height: 32px;
    cursor: pointer;
    text-align: center;

    &:first-child { border-radius: 3px 0 0 3px; }
    &:last-child { border-radius: 0 3px 3px 0; }
    &:first-child { &:last-child { border-radius: 3px } }
    &:not(:first-child) { margin-left: -1px; }

    img, span {
      position: absolute;
      margin: auto;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      height: 18px;
    }
    img {
      width: 16px;
      filter: invert(67%) sepia(10%) saturate(485%) hue-rotate(178deg) brightness(92%) contrast(89%);
    }
    span {
      height: 15px;
      font-size: 12px;
      color: #808080;
    }

    &.crm-action-btn-extended {
      width: 48px;

      img {
        left: 9px;
        right: initial;
      }
      .crm-action-btn-stat {
        position: absolute;
        right: 9px;
        top: 0;
        bottom: 0;
        margin: auto;
        height: 20px;
        font-weight: 500;
        color: #5d6a7e;
      }
    }
  }

  .crm-action-btn:hover, .crm-action-btn.active {
    border-color: #1991EB;
    z-index: 3;
    // &:first-child:hover {
    //
    // }
    img { filter: invert(44%) sepia(98%) saturate(600%) hue-rotate(168deg) brightness(94%) contrast(96%); }
    span { color: #1991EB; }
  }
}

.search-tip-icon {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 60px;
  cursor: pointer;
  opacity: 0.5;
  transition: all 0.32s ease;
}
.search-tip-icon:hover {
  opacity: 1;
}

.search-tip-dropdown {
  visibility: hidden;
  position: absolute;
  width: 400px;
  height: 675px;
  padding: 20px 25px;
  top: 65px;
  left: 60px;
  border-radius: 15px;
  box-shadow: 0px 9px 9px 0px rgba(0, 0, 0, 0.13);
  z-index: 99;
  opacity: 0;
  transition: all 0.32s ease;
  transform: translateY(50px);
  background: white;

  .search-tip-point {
    font-family: Roboto;

    .search-tip-title {
      margin-bottom: 6px;
      font-weight: bold;
      font-size: 14px;
    }

    p {
      font-weight: normal;
    }

    .search-tip-example {
      margin-bottom: 20px;
      font-size: 14px;
    }
    .search-tip-query {
      padding: 4px 10px;
      border-radius: 25px;
    }
  }
}

.search-tip-dropdown.active {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}

.filter-option-container {
  position: relative;
  cursor: pointer;
  margin-bottom: 8px;

  // & div::after {
  //   display: none;
  // }

  .filter-option-inner {
    position: relative;
  }

  .filter-option-inner {
    // padding: 8px;
    // border: 1px solid #e8eef4;
    // border-radius: 6px;
  }
}

.filter-option-container.disabled {
  cursor: not-allowed;
  opacity: 0.5;

  input, label, div, span, p, a, button {
    cursor: not-allowed;
  }
}

.filter-option-selector-container {
  position: relative;
  display: inline-block;
  float: left;
  width: 22px;
  height: 22px;

  .filter-option-selector {
    position: relative;

    .filter-checkbox {
      position: relative;
      margin-top: 2px;
      width: 16px;
      height: 16px;
      border: 1px solid #949fb1;
      border-radius: 50%;
      cursor: pointer;

      .filter-checkbox-inner {
        position: absolute;
        display: none;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        height: 8px;
        width: 8px;
        border-radius: 50%;
        background: white;
      }
    }
  }
  .filter-option-title {
    position: relative;
    display: inline-block;
    float: left;
    width: calc(100% - 24px);
    height: 24px;
  }
}

.filter-option-selector-container.active, .filter-option-selector-container:hover,
.filter-option-toggle-selector.active, .filter-option-toggle-selector:hover {
  .filter-checkbox {
    background: #1991eb;
    border-color: #1991eb;
    .filter-checkbox-inner { display: block; }
  }
  &:hover { .filter-checkbox { opacity: 0.72; } }
}

.filter-option-container.min-max {

  .filter-option-selector-container {
    width: 28px;

    .filter-checkbox {
      margin-top: calc(50%);
    }
  }


  .filter-option-selector-input {
    position: relative;
    display: inline-block;
    float: left;
    width: calc((100% - (28px + 32px)) / 2);
    // padding-left: 6px;
    // height: 22px;
  }

  .filter-option-selector-input-to {
    position: relative;
    display: inline-block;
    float: left;
    width: 32px;
    text-align: center;
    padding-top: 11px;
    font-weight: 500;
    font-size: 16px;
    color: #5d6a7e !important;
  }
}

.filter-option-container.filter-option-toggle-selector {
  position: relative;
  cursor: pointer;
  background: white !important;
  padding: 8px 6px 4px 6px;
  border: 1px solid #d3dae3 !important;
  box-shadow: 0 1px 3px 0 rgba(0,0,0,.10);
  border-radius: 3px;

  label {
    cursor: pointer;
  }
}

.pagination-container {
  position: relative;
  width: 100%;
  height: 40px;
  border-top: 1px solid #eee;
  background: #fafafa;

  .pagination-container-outer {
    .pagination-container-inner {

    }
  }
}
