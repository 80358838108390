input {
  margin-bottom: 15px;
}

.connect-socials-container {

}

.social-section {
  margin: 30px 0;
  position: relative;

  .close {
    position: absolute;
    right: 0;
    top: 2px;
  }
}

.invite-socials-form  {
  &.disabled {
    opacity: 0.3;
    pointer-events: none;
  }
}
