@import 'vars.scss';

.twitch-stream-module-2 {

}

.bounty-objective.module {
  position: relative;
  width: 100%;
  height: 79px;
  margin: 8px 0 0 0;
  transition: all 0.32s ease;
  padding: 0;

  .bounty-objective-row {
    position: relative;
    margin: 0;
    height: 79px;
    padding: 0 10px;
  }
}

.bounty-objective.bounty-objective-slideout {
  border-radius: 3px 3px 0 0;
  cursor: initial;
  height: initial;

  .module {
    border: none !important;
    box-shadow: none !important;
  }

  .bounty-objective-row {
    // background: #202329;
    padding: 0;
  }

  .bounty-objective-container {
    position: relative;
    height: 100%;
    padding: 0 20px;
    margin: 0;

    .bounty-objective-add-post {
      width: 100%;
      padding-bottom: 82%;
      border: 10px solid #f9f9f9;
      border-radius: 3px;
      background: #eee;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;

      svg {
        position: absolute;
        width: 18px;
        height: 18px;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        opacity: 0.48;
      }
    }
  }

  .bounty-objective-expanded {
    position: relative;
    // background: #292B31;
    padding: 10px 0 15px;
  }

  .bounty-progress {
    top: 0;
    border-radius: 0 !important;
  }
}
// .bounty-objective.bounty-objective-slideout.active {
//   height: initial;
// }

.bounty-objective-video {
  position: relative;
  margin: 0;
  padding: 15px 10px 15px 0;

  .bounty-objective-video-img {
    position: relative;
    display: inline-block;
    margin-right: 15px;
    float: left;

    img {
      width: 100px;
      border-radius: 3px;
    }
  }
  .bounty-objective-video-text {
    position: relative;
    display: inline-block;
    float: left;
  }
}

.bounty-objective-value {
  float: right;
}

.bounty-platform-icon {
  position: relative;
  height: 100%;
  max-width: 75px;

  img {
    position: absolute;
    height: 55%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    border-radius: 50%;
  }
}

.bounty-objective-text {
  position: relative;
  height: 100%;
  padding: 0;

  .bounty-objective-p {
    margin-top: 22px;
    font-weight: 500;
    margin-left: 10px;
    font-family: Barlow;

    b {
      font-weight: 500;
    }
  }
}

.bounty-objective-status {
  // margin-top: 22px;
  // font-weight: 500;
  // margin-left: -6px;
  margin-top: 26px;
  margin-bottom: 0;
  text-align: right;

  b {
    font-size: 20px;
  }

  span {
    font-size: 16px;
  }
}

.bounty-requirement-missing {
  text-align: right;
  color: #c33a3a;
  margin: 0;
  font-weight: 500;
}

.bounty-platform-color {
  position: absolute;
  display: none;
  top: 0;
  left: 0;
  width: 6px;
  height: 100%;
}

.bounty-platform-color-twitch {
  background: purple;
}

.bounty-platform-color-youtube {
  background: #ff0000;
}

.bounty-progress {
  position: relative;
  width: 100%;
  // height: 3px;
  height: 15px;
  top: -4px;
  left: 0;
  // border-radius: 0 0 3px 3px;
  border-radius: 50px;
  overflow: hidden;
  z-index: 2;

  .bounty-progress-outer {
    position: relative;
    width: 100%;
    height: 100%;

    .bounty-progress-inner {
      position: absolute;
      // background: #9664C9;
      // background: #ff8e30;
      background: rgb(0,255,179);
      background: linear-gradient(90deg, rgba(0,255,179,1) 0%, rgba(79,247,132,1) 35%, rgba(131,255,77,1) 100%);
      width: 0%;
      height: 100%;
      top: 0;
      left: 0;
      border-radius: 50px;
      transition: all 0.85s ease;
    }
  }
}

.bounty-progress.complete {

  .bounty-progress-inner {
    background: #14ae37;
    width: 100%;
  }
}

.bounty-progress-labels {
  margin-top: 6px;
  font-family: Barlow;
  font-weight: 700;
  font-size: 16px;
}

.bounty-progress-reward {
  position: absolute;
  width: 50px;
  text-align: center;
  padding: 3px;
  background: #2eafe4;
  background: -webkit-linear-gradient(#5acfff, #2eafe4);
  border-radius: 10px 10px 10px 10px;
  bottom: -10px;
  color: white;
  // transform: rotate(28deg);
  // margin-left: -52px;
  margin-left: -37px;
}

.bounty-progress-arrow-down-container {
  position: relative;
  width: 100%;

  .bounty-progress-arrow-down {
    position: absolute;
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 12px solid #2eafe4;
    margin: -1px auto 2px auto;
    left: 0;
    right: 0;
  }
}

.bounty-progress-reward.left-side {
  .bounty-progress-arrow-down {
    margin: -1px auto 2px -2px;
  }
}

.bounty-progress-reward.right-side {
  margin-left: -43px;

  .bounty-progress-arrow-down {
    margin: -1px 1px 2px auto;
  }
}

.bounty-progress-reward.completed {
  background: #14c311;
  background: -webkit-linear-gradient(#68e449, #14c311);
}

.bounty-progress-label {
  position: absolute;
  width: 55px;
  text-align: right;
  margin-left: -58px;
}

.bounty-progress-tick {
  position: absolute;
  width: 1px;
  height: 100%;
  margin-left: -12px;

  .bounty-progress-tick-inner {
    width: 100%;
    height: 100%;
    background: #505050;
  }
}

.bsr-4-1 { left: calc(25%); }
.bsr-4-2 { left: calc(50%); }
.bsr-4-3 { left: calc(75%); }
.bsr-4-4 { left: calc(100%); }

.bsr-5-1 { left: calc(20%); }
.bsr-5-2 { left: calc(40%); }
.bsr-5-3 { left: calc(60%); }
.bsr-5-4 { left: calc(80%); }
.bsr-5-5 { left: calc(100%); }

.bounty-tile-icon {
  padding: 12px;
  position: relative;
  display: inline-block;
  border-radius: 50%;
  background: #40caff42;
  z-index: 1;

  &:before, &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    border-radius: 50%;
    z-index: -1;
  }
  &:before {
    width: 82%;
    height: 82%;
    background: #40caff99;
  }
  &:after {
    width: 60%;
    height: 60%;
    background: #40caff;
  }

  img {
    width: 20px;
  }
}
