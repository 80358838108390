.invite-compensation-preview {
  display: flex;
  flex-direction: column;
  padding: 20px 30px;

  .payout {
    font-size: 42px;
    font-family: Barlow;
    font-weight: 800;
  }

  .payout-block {
    padding: 25px 0;
    text-align: center;
  }

  hr {
    width: 100%;
  }
}
