// $main-bg: #36393f;
// $module: #2e3136;
$subtext: #BDBBC0;
$hover: #202225;

// $main-bg: #25282d;
// $module: #202225;
$main-bg: #202225;
$module: #25282d;
$black-btn: #292a2d;

$primary: #1b85f8;
// $primary: #c495fd;
$error: #CF6679;

$dark-input: #2e3036;
$dark-input-focus: #212226;
$dark-input-darker: #212226;

$slideout-container: 650px;
$slideout: 550px;


.mainApp.themeLight {

}

.mainApp.themeDark {

}
