.location-search-input {
  padding: 15px;
  border: 1px solid #fff7f7;
}

.autocomplete-dropdown-container {
  max-height: 500px;
  position: absolute;
  background-color: white;
  z-index: 9999;

  border: 1px solid rgba(0, 0, 0, 0.04);
  box-shadow: rgba(0,0,0,.32) 0px 2px 35px -24px;
  cursor: pointer;

  &:empty {
    max-height: 0;
  }

  &:not(:empty) {
    padding: 15px;
    border: 1px solid #fff7f7;

    .suggestion-item {
      padding: 10px;
    }
  }
}
