// .offer-box-page {
//   width: 100vw;
//   height: 100vh;
//   background: #f5f4f9;
//   display: flex;
//   -webkit-box-align: center;
//   -ms-flex-align: center;
//   align-items: center;
//   -webkit-box-pack: center;
//   -ms-flex-pack: center;
//   justify-content: center;
//   /* width: 100%; */
//   /* height: 100%; */
//   padding: 0;
//   margin: 0;
//   color: #4a4a4a;
//
//   .offer-box {
//     margin: auto;
//     width: 350px;
//     max-width: 100%;
//     background: white;
//     overflow: hidden;
//     border-radius: 8px;
//     box-shadow: 0 10px 20px -10px rgba(0, 0, 0, 0.4);
//
//     .offer-box-inner {
//       position: relative;
//       width: 100%;
//       height: 100%;
//
//       header {
//         height: 100px;
//         background: #05d5ff;
//         background: linear-gradient(118deg, #5d3dff, #2a95ff 32%, #05d5ff 59%, #12d9fb 62%, #20ddf6 66%, #22ddf6 67%, #a6ffcb);
//         display: -webkit-box;
//         display: -ms-flexbox;
//         display: flex;
//         -webkit-box-align: center;
//         -ms-flex-align: center;
//         align-items: center;
//         -webkit-box-pack: center;
//         -ms-flex-pack: center;
//         justify-content: center;
//       }
//
//       .offer-box-lower {
//         position: relative;
//         padding: 25px;
//
//         .offer-box-upper-half {
//           position: absolute;
//           top: 0;
//           left: 0;
//           width: 0;
//           height: 0;
//           border-top: 0 solid transparent;
//           border-bottom: 300px solid transparent;
//           border-left: 300px solid #cfd9e8;
//         }
//
//         .offer-box-lower-half {
//           position: absolute;
//           bottom: 0;
//           right: 0;
//           width: 0;
//           height: 0;
//           border-bottom: : 0 solid transparent;
//           border-top: 300px solid transparent;
//           border-right: 300px solid #cfd9e8;
//         }
//
//         img {
//           width: 200px;
//           display: block;
//           margin: 0 auto;
//         }
//
//         h2 {
//           text-align: center;
//         }
//       }
//     }
//   }
// }







.offer-box-page {
  width: 100vw;
  height: 100vh;
  // background: #f5f4f9;
  background: #fff;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  /* width: 100%; */
  /* height: 100%; */
  padding: 0;
  margin: 0;
  color: #4a4a4a;
  transition: all 0.6s ease;

  .offer-box {
    margin: auto;
    width: 350px;
    max-width: 100%;
    border-radius: 8px;
    transition: all 0.6s ease;
    height: 730px;
    padding-top: 140px;
    // background: white;
    // overflow: hidden;
    // box-shadow: 0 10px 20px -10px rgba(0, 0, 0, 0.4);

    .offer-box-inner {
      position: relative;
      width: 100%;
      height: 100%;
      transition: all 0.6s ease;

      .offer-box-lower {
        position: relative;
        padding: 25px;
        transition: all 0.6s ease;

        .offer-prof-imgs {
          margin-bottom: 50px;
          transition: all 0.6s ease;

          .offer-box-upper-half {
            position: relative;

            .offer-box-upper-half-bg {
              position: absolute;
              top: 40px;
              left: 20px;
              width: 300px;
              height: 4px;
              border-radius: 9px;
              background-image: linear-gradient(119deg, #4b7fb3, #fc4405);
              transform: rotate(-45deg);
              opacity: 0;
              transition: all 0.6s ease;
              // position: absolute;
              // top: 0;
              // left: 0;
              // width: 0;
              // height: 0;
              // border-top: 0 solid transparent;
              // border-bottom: 300px solid transparent;
              // border-left: 300px solid #cfd9e8;
            }
            .offer-box-upper-half-div {
              position: relative;
              z-index: 2;
              float: left;
              width: 200px;
              height: 200px;

              img {
                position: absolute;
                top: -100px;
                left: -25px;
                width: 150px;
                display: block;
                margin: 0 auto;
                opacity: 0;
                transform: translateX(-600px);
                transition: all 0.6s ease;
              }
            }
          }

          .offer-box-lower-half {
            position: relative;

            .offer-box-lower-half-bg {
              position: absolute;
              bottom: 0;
              right: 0;
              width: 0;
              height: 0;
              // border-bottom: : 0 solid transparent;
              // border-top: 300px solid transparent;
              // border-right: 300px solid #cfd9e8;
            }
            .offer-box-lower-half-div {
              position: relative;
              z-index: 2;
              float: right;
              width: 200px;
              height: 200px;

              img {
                position: absolute;
                top: 55px;
                width: 115px;
                display: block;
                margin: 0 auto;
                right: 0;
                opacity: 0;
                transform: translateX(600px);
                transition: all 0.6s ease;
                border-radius: 5px;
              }
            }
          }
        }

        .offer-prof-imgs.offer-prof-diagonal {

          .offer-box-upper-half {

            .offer-box-upper-half-bg {

            }
            .offer-box-upper-half-div {

              img {

              }
            }
          }
        }

        .offer-prof-imgs.offer-prof-horizontal {
          margin-bottom: -10px;

          .offer-box-upper-half {

            .offer-box-upper-half-bg {
              top: 90px;
              left: 120px;
              width: 115px;
              transform: rotate(-90deg);
            }
            .offer-box-upper-half-div {

              img {
                top: 20px;
                left: -25px;
              }
            }
          }

          .offer-box-lower-half {

            .offer-box-lower-half-bg {

            }

            .offer-box-lower-half-div {

              img {
                top: 13px;
                right: -20px;
              }
            }
          }
        }

        .offer-box-name-container {
          display: block;
          margin: 25px auto 25px -20px;
          text-align: center;
          width: 400px;
          // max-width: 100%;

          .name-left {
            transform: translateX(-400px);
          }
          .name-right {
            transform: translateX(400px);
          }

          .offer-box-name {
            position: relative;
            display: inline-block;
            padding: 8px 25px;
            background: black;
            color: white;
            margin-right: 10px;
            opacity: 0;
            transition: all 0.6s ease;
            // border-radius: 3px;

            .offer-box-name-point {
              position: absolute;
              bottom: 0;
              right: -14px;
              width: 0;
              height: 0;
              border-bottom: 0 solid transparent;
              border-top: 29px solid transparent;
              border-right: 29px solid red;
              transform: rotate(-45deg);
              top: 6px;
            }

            .offer-box-name-point-invert {
              position: absolute;
              bottom: 0;
              left: -15px;
              width: 0;
              height: 0;
              border-bottom: 0 solid transparent;
              border-top: 29px solid transparent;
              border-right: 29px solid #fff;
              transform: rotate(-45deg);
              top: 6px;
            }

            p {
              margin: 0;
              font-size: 18px;
              text-transform: uppercase;
              // letter-spacing: 1.4px;
              font-family: Barlow;
              font-weight: 900;
            }
          }
        }

        h2 {
          text-align: center;
        }
        .offer-box-message {
          opacity: 0;
          transition: all 0.6s ease;

          p {
            margin: 15px 0 25px;
            font-size: 16px;
            text-align: center;
            font-family: Barlow;
            font-weight: 600;
          }
        }
        .offer-box-button {
          opacity: 0;
          transition: all 0.6s ease;

          button {
            font-family: Barlow;
            font-weight: 900;
            letter-spacing: initial;
            padding: 12px 15px;
          }
        }
      }
    }
  }
}

.offer-box.active {

  img {
    opacity: 1 !important;
    transform: translateX(0px) !important;
  }
  .offer-box-upper-half-bg.active {
    opacity: 1 !important;
  }
  .offer-box-name-container.active .offer-box-name {
    opacity: 1 !important;
    transform: translateX(0px) !important;
  }
  .offer-box-message.active {
    opacity: 1 !important;
  }
  .offer-box-button.active {
    opacity: 1 !important;
  }

}


.offer-box-page.offer-page-2 {

  h2 {
    font-family: Barlow;
    font-weight: 900;
  }

  .offer-points {
    margin-bottom: 32px;

    .offer-point {
      font-family: Barlow;
      font-weight: 900;
      margin-left: -200px;
      opacity: 0;
      transition: all 0.4s ease;

      .offer-point-dot {
        display: inline-block;
        font-size: 24px;
        margin-right: 20px;
      }

      .offer-point-text {
        display: inline-block;
        font-size: 24px;
        text-transform: uppercase;
      }
    }

    .offer-point.active {
      display: block;
      opacity: 1;
      margin-left: 0;
    }
  }

  .offer-value-container {
    position: relative;
    display: inline-block;
    width: 80px;
    height: 30px;
  }

  .blur {
    position: absolute;
    background: url('https://images.unsplash.com/photo-1486723312829-f32b4a25211b?dpr=2&auto=format&fit=crop&w=1500&h=1000&q=80&cs=tinysrgb&crop=&bg=') no-repeat center center fixed;
    background-size: cover;
    overflow: hidden;
    filter: blur(13px);
    width: 100%;
    height: 100%;
  }
}

.offer-connect-btns {
  opacity: 0;
  transition: all 0.4s ease;

  .integration-btn {
    opacity: 0;
    transition: all 0.4s ease;
    margin-left: -200px;
  }

}

.offer-connect-btns.active {
  opacity: 1;

  .integration-btn {
    opacity: 1;
    margin-left: 0;
  }
}

.offer-box-page.offer-page-2 {
  .offer-box {
    padding-top: 0;
  }
}


.start-bounty-container {
  margin-top: 30px;

  .start-bounty-btn {
    font-family: Barlow;
    font-weight: 900;
    width: initial;
    padding: 12px 50px !important;
    background: none;
    overflow: hidden;

    .start-bounty-btn-bg {
      background: #000;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 0;
    }

    .start-bounty-btn-fill {
      position: absolute;
      background: #783bc3;
      width: 0%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 0;
      transition: all 0.38s ease;
    }

    span {
      position: relative;
    }
  }

  .start-bounty-btn:hover {
    .start-bounty-btn-fill {
      width: 100%;
    }
  }
}

@media (max-width: 700px) {

  .offer-box-page.offer-page-2 {
    .offer-box {
      .offer-prof-imgs {
        margin-top: -15px;
      }
    }
  }

}
