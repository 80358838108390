.landing {
  overflow-x: hidden;
  font-family: Barlow !important;

  .section {
    position: relative;
    width: 100vw;
    padding: 70px 0;
    margin: 0;
    background: none;
    overflow: initial;
  }

  .section.hero {

    .row {
      align-items: left;
      justify-content: left;
    }

    .title {
      max-width: 465px;
      /*float: left;*/
      text-align: left;
    }
    .title h1 {
      margin-top: 55px;
      /*margin-bottom: 70px;*/
      font-weight: 900;
      font-size: 48px;
      // transition: 0.3s ease;
      // transition-delay: 1s;
      opacity: 1;
      text-align: left;
      // color: white;
    }
    .title p {
      font-size: 22px;
      text-align: left;
      // transition: 0.3s ease;
      // transition-delay: 1s;
      opacity: 1;
      // color: white;
      margin-top: 35px;
      margin-bottom: 35px;
      max-width: 700px
    }
    .container {
      position: relative;
      /*padding-left: 0;*/
      // padding-top: 125px;
      padding-top: 185px;
    }
  }

  .how-it-works {

    .how-it-works-text {
      p {
        margin: 0;
        height: 140px;
      }
    }
    .screenshot {

      img {

      }
    }
  }
}

.background-strip {
  position: absolute;
  width: 1000px;
  height: 90vh;
  min-height: 1075px;
  top: -230px;
  right: -109px;
  overflow: hidden;
  transform: skewY(0deg);
  transform-origin: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url(https://s3-us-west-2.amazonaws.com/weave-img/web-assets/landing/home/weavebackground.svg);
  background-size: contain;
  height: 110vh;
}

.background-strip2 {
  position: absolute;
  width: 51vw;
  height: 90vh;
  min-height: 575px;
  top: -390px;
  left: -109px;
  overflow: hidden;
  transform: skewY(0deg);
  transform-origin: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url(https://s3-us-west-2.amazonaws.com/weave-img/web-assets/landing/home/weavebackground.svg);
  background-size: contain;
  height: 110vh;
}

.landing {
  overflow-x: hidden;
}
.landing .row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display:         flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: 0;
}
.landing .row > [class*='col-'] {
  display: flex;
  flex-direction: column;
}

.landing .container {
  position: relative;
  max-width: 1024px !important;
}
.landing .section {
  position: relative;
  width: 100vw;
  padding: 70px 0;
  margin: 0;
  background: none;
  overflow: initial;
}
.landing .section h2, .landing .section h3, .landing .section h4 {
  // color: #2a95ff;
  margin: 0 auto 20px auto;
  max-width: 500px;
  width: 100%;
  font-weight: 800;
  color: black;
}
.landing .section p {
  font-size: 17px;
  line-height: 26px;
  // color: #6b7c93;
  color: #808080;
  margin: 0 auto 50px auto;
  max-width: 500px;
  width: 100%;
}
.hero .logo-hero {
  position: absolute;
  top: 0;
  left: 25px;
  width: 168px;
}
.landing .section.hero {
  .hero-icons {
    text-align: right;

    img {
      // width: 150%;
      max-height: 600px;
      margin-top: -85px;
    }
  }
}
.hero .login-container {
  position: absolute;
  display: block;
  top: 0;
  right: 25px;
  width: 200px;
  height: 30px;
  font-size: 18px;
}
.hero .login-container-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: right;
}
.hero .login-container-inner span {
  color: white;
  cursor: pointer;
  font-size: 18px;
}
.weave {
  font-size: 36px;
	font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  color: black;
	font-weight: 300;
	letter-spacing: -2.3px;
  margin-left: 8px;
}
.btn-container {
  position: relative;
  display: inline-block;
  float: left;

  .get-started {
    border-radius: 50px;
    // background-color: #F5A623;
    // background-image: -webkit-gradient(linear, left top, left bottom, from(#ff2135), to(#c617d2));
    background-image: linear-gradient(135deg, #c617d2, #ff2135);
    margin-right: 20px;
    margin-bottom: 15px;
    // text-shadow: 0 1px 3px rgb(215, 145, 28);
  	font-weight: 500;
  	text-align: center;
    color: white;
    border: none;
    box-shadow: 0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08);
    cursor: pointer;
    font-size: 20px;
  }

  .get-started.learn-more {
    background: none;
    color: black;
    border: 1px solid #6b7c93;
    box-shadow: none;
  }
}

.get-started-header {
  position: relative;
  display: inline-block;
  float: right;
  width: 139px;
	height: 47px;
}
.get-started-top {
  position: absolute;
  top: 0;
  right: 0;
  width: 139px;
	height: 47px;
	font-size: 13px;
  letter-spacing: 0.3px;
}
.get-started-hero {
  width: 265px;
	height: 68px;
  font-size: 17px;
  letter-spacing: 0.4px;
  box-shadow: 0 1px 2px 0 black;
}
.get-started-body {
  margin: 50px auto;
}
.get-started {
  position: relative;
  display: inline-block;
  width: 200px;
  padding: 12px 20px;
}
.get-started a {
  width: 100%;
  height: 100%;
  color: white;
  font-size: 18px;
}
.footer {
  position: relative;
  padding: 50px 0;
  z-index: 4;
  color: white;
}
.footer-left {
  display: inline-block;
  margin-top: -21px;
  float: left;
}
.footer-right {
  float: right;
  font-size: 15px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: right;
  margin: 28px 0 0;
}
.footer a {
  margin: 0 15px;
  color: white;
  display: inline-block;
  cursor: pointer;
  font-weight: 300;
}
.footer a.copyright {
  position: absolute;
  margin: 0 auto;
  left: 0;
  text-align: center;
  right: 0;
  bottom: 10px;
  color: white;
}
footer .logo-container {
  max-width: 175px;
  width: 175px
}
footer .logo {
  margin: 0;
  width: 168px;
}
.footer .col-md-3 {
  padding: 0;
}

// @media (min-width: 0px) {
//   .background-strip {
//     height: 450px;
//   }
// }
// @media (min-width: 670px) {
//   .background-strip {
//     height: 600px;
//   }
// }
// @media (min-width: 880px) {
//   .background-strip {
//     height: 760px;
//   }
// }
/*.background-strip.gray {
  background: #f9f7f9;
}*/
/*.background-strip:before, .background-strip:after {
  background: linear-gradient(-45deg, #fff 10px, transparent 0), linear-gradient(45deg, #fff 10px, transparent 0);
  background-position: left-bottom;
  background-repeat: repeat-x;
  background-size: 20px 20px;
  content: " ";
  display: block;
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 20px;
}
.background-strip:before {
  top: -20px;
  background: linear-gradient(-45deg, #f6f9fc 10px, transparent 0), linear-gradient(45deg, #f6f9fc 10px, transparent 0);
  background-position: left-bottom;
  background-repeat: repeat-x;
  background-size: 20px 20px;
}*/

.landing .container {
  /*background: white;*/
}
.landing .categories-choose {
  overflow: hidden;
}
.landing .categories-choose-outer {
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
  -webkit-overflow-scrolling: touch;
  -webkit-transform: translateZ(0px);
  -webkit-transform: translate3d(0,0,0);
  -webkit-perspective: 1000;
}
.landing .categories-choose-inner {
  min-width: 768px;
}
.landing .categories-choose .category-select {
  display: inline-block;
  float: left;
  text-align: center;
  width: 14.285714285714285714285714285714%;
  cursor: pointer;
  border-bottom: 4px solid #edeced;
}
.landing .categories-choose .category-select p {
  color: #999999;
  font-size: 16px;
  margin: 15px auto;
  height: 44px;
  transition: .5s ease-out all;
}
.landing .categories-choose .category-select.fashion.active {
  border-color: #f74c2d;
}
.landing .categories-choose .category-select.gaming.active {
  border-color: #f8ae38;
}
.landing .categories-choose .category-select.fitness.active {
  border-color: #3bc3ff;
}
.landing .categories-choose .category-select.product_reviews.active {
  border-color: #66C6B9;
}
.landing .categories-choose .category-select.education.active {
  border-color: #7ed321;
}
.landing .categories-choose .category-select.diy.active {
  border-color: #f8e71c;
}
.landing .categories-choose .category-select.other.active {
  border-color: #62DD9F;
}
.landing .categories-choose .category-select.active p, .landing .categories-choose .category-select:hover p {
  color: black;
}
.landing .categories-choose img {
  width: 80px;
  height: 80px;
}
.shadow {
  -webkit-filter: drop-shadow( 4px 9px 4px rgba(0, 0, 0, 0.22) );
  filter: drop-shadow( 4px 9px 4px rgba(0, 0, 0, 0.22) );
}
.shard-1 {
	position: absolute;
  // background: #6cb6ff;
  background: #46b7e2;
  /*opacity: 0.55;*/
	width: 150vw;
	height: 765px;
	transform: rotate(-10deg);
	bottom: -190px;
  /*top: 0;*/
	left: -50px;
	z-index: 3;
}
.shard-2 {
	position: absolute;
	// background: #2a95ff;
  background: #72d7f5;
	/*opacity: 0.2;*/
	width: 150vw;
	height: 685px;
	transform: rotate(8deg);
	bottom: -100px;
  /*top: 0;*/
  left: -175px;
  z-index: 2;
}
.section.sliver:before {
  content: "";
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 80%;
  top: 100px;
  transform: skewY(-12deg);
  background: #f6f9fc;
}
/*.section.sliver:after {
  content: "";
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 180%;
  top: 35px;
  transform: skewY(-12deg);
  background: #f6f9fc;
}*/
.section.jagged:before {
  background: linear-gradient(-45deg, #fff 10px, transparent 0), linear-gradient(45deg, #fff 10px, transparent 0);
  background-position: left bottom;
  background-repeat: repeat-x;
  background-size: 20px 20px;
  content: " ";
  display: block;
  position: absolute;
  top: -20px;
  left: 0px;
  width: 100%;
  height: 20px;
}
.section.jagged-blue:before {
  background: linear-gradient(-45deg, #11d6f4 10px, transparent 0), linear-gradient(45deg, #11d6f4 10px, transparent 0);
  background-position: left bottom;
  background-repeat: repeat-x;
  background-size: 20px 20px;
  content: " ";
  display: block;
  position: absolute;
  top: -20px;
  left: 0px;
  width: 100%;
  height: 20px;
}
.section.jagged-blue:after {
  background: linear-gradient(-45deg, #11d6f4 10px, transparent 0), linear-gradient(45deg, #11d6f4 10px, transparent 0);
  background-position: left bottom;
  background-repeat: repeat-x;
  background-size: 20px 20px;
  content: " ";
  display: block;
  position: absolute;
  bottom: -20px;
  left: 0px;
  width: 100%;
  height: 20px;
  transform: rotate(180deg);
}
.landing .col-sm-6.feature-text {
  height: 100%;
  /*padding: 75px 25px 25px;*/
  /*display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;*/
}
.landing .col-sm-6.icon-display {
  text-align: center;
}
.landing .col-sm-6.icon-display img {
  max-width: 615px;
  width: 125%;
  // transform: translateY(-20%);
}
.landing .col-sm-6.icon-display.icon-display-left img {
  transform: translateX(-20%);
}


.section .retailer-icons img {
  position: relative;
  display: block;
  width: 100%;
  // width: 175%;
  // left: -75%;
  // transform: rotate(-4deg);
}
.split-features .split-left {
  padding: 35px 70px 70px 0;
  border-right: 2px solid #f6f9fc;
}
.split-features .split-right {
  padding: 35px 0 70px 70px;
}
.split-features .split-img {
  display: block;
  max-width: 500px;
  margin: 0 auto 25px;
  text-align: left;
}
.split-features .split-img img {
  width: 80px;
}
.final-get-started {
  padding: 150px 0px;
  text-align: center;
  z-index: 99;
}
.landing .section .earnings-container {
  padding: 100px 0 0;
}

.adjust-left {
  img {
    transform: translateX(-15%);
  }
}

.category-direct {
  border: 1px solid #f3f3f3;
  border-radius: 15px;
  position: relative;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 9px 0px;
  margin-bottom: 25px;

  .category-direct-img {
    position: relative;
    width: 100%;
    height: 250px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .category-direct-meta {
    padding: 25px;
  }
}

.how-it-works {

  .how-point-small {
    display: none;
  }

  .col-sm-9 {
    height: 425px;
  }
}

@media (max-width: 991px) {
  .section .category-sections .col-sm-6.feature-text {
    padding: 0 35px;
  }
  .landing .col-sm-6.feature-text {
    padding: 0px 25px 25px;
  }
  .final-get-started {
    padding: 150px 0px 50px;
  }
  footer .logo-container {
    display: block;
    /*max-width: 140px;*/
    margin: 0 auto;
    float: initial;
  }
  .footer-right {
    width: 100%;
  }
  .footer a {
    text-align: center;
    display: block;
    margin: 15px auto;
  }
}

@media screen and (max-width: 767px) {
  .btn-container {
    display: block;
    float: initial;
    width: 100%;

    .get-started {
      display: block;
      margin: 0 auto 15px auto !important;
    }
  }

  .col-sm-3.how-3points {
    display: none !important;
  }

  .landing .col-sm-6.icon-display.icon-display-left img {
    transform: translateX(-10%);
  }
  .background-strip {
    display: none;
  }
  .background-strip2 {
    width: 73vw;
    top: -369px;
    left: -22px;
  }
  .landing .section.hero {
    padding: 40px 0 0;
  }
  .landing .section.hero .container {
    padding-top: 75px;
    padding-left: 0;
    padding-right: 0;
  }
  .landing .section.hero .hero-icons img {
    transform: translateX(-12%);
    width: 140%;
    max-height: 600px;
    margin-top: 25px;
    // width: 100%;
    // padding-top: 55px;
    // display: none;
  }
  .hero-icons.brand-product-hero {

    img {
      display: block;
      margin: 50px auto 0 auto !important;
      width: 300px !important;
      max-width: 100%;
      transform: initial !important;
    }
  }
  .landing .section.hero .title h1 {
    font-size: 40px;
  }

  .how-it-works {
    .col-sm-9 {
      height: initial;
    }

    .row {
      text-align: center !important;

      .how-point-small {
        display: block;
        margin-bottom: 15px;
      }
    }


    .screenshot {

      img {
        display: none;
      }
    }
  }

  .category-direct {
    width: calc(100vw - 50px);
    max-width: 450px;
  }

  .landing .row {
    align-items: center !important;
    justify-content: center !important;
  }

  .hero .login-container-inner span {
    color: #000 !important;
  }

  .landing .col-sm-6.icon-display img {
    // max-width: 550px;
    max-width: 615px;
    width: 125%;
    transform: translateX(-10%);
  }

  .section.sliver {
    background: #f6f9fc;
  }
  .section.sliver:before {
    transform: skewY(0deg);
    background: linear-gradient(-45deg, #f6f9fc 10px, transparent 0), linear-gradient(45deg, #f6f9fc 10px, transparent 0);
    background-position: left bottom;
    background-repeat: repeat-x;
    background-size: 20px 20px;
    content: " ";
    display: block;
    position: absolute;
    top: -20px;
    left: 0px;
    width: 100%;
    height: 20px;
  }
  .section.sliver:after {
    background: linear-gradient(-45deg, #f6f9fc 10px, transparent 0), linear-gradient(45deg, #f6f9fc 10px, transparent 0);
    background-position: left bottom;
    background-repeat: repeat-x;
    background-size: 20px 20px;
    content: " ";
    display: block;
    position: absolute;
    bottom: -20px;
    left: 0px;
    width: 100%;
    height: 20px;
    transform: rotate(180deg);
  }

  .adjust-left {

    img {
      transform: translateX(-5%);
    }
  }

  .section .retailer-icons img {
    // width: 100%;
    // max-width: 500px;
    width: 120%;
    max-width: 550px;
    left: -10%;
    margin: 0 auto;
    display: block;
    left: initial;
  }
  .landing .section .earnings-container {
    padding: 100px 0 0;
  }
  .split-features .split-left, .split-features .split-right {
    padding: 0 25px;
  }
}

@media screen and (max-width: 599px) {
  .signup {
    display: none;
  }

  .adjust-left {

    img {
      transform: translateX(-15%);
    }
  }
}

@media screen and (max-width: 500px) {
  .signup {
    display: none;
  }


}
