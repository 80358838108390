

.invite-deliverables {
  height: 100%;

  .invite-panel {
    margin-top: 0;
    margin-bottom: 0;
  }

  .invite-deliverables-preview {
    .sub-text {
      text-align: left;
    }
  }
  .invite-deliverables-full {
    padding: 40px 0;
    height: 100%;
    overflow-y: auto;
  }

  .invite-deliverable {
    display: flex;
    flex-direction: row;
  }

  .icon {
    margin-right: 5px;
    img {
      height: 20px;
      max-width: 20px;
    }
  }

  .statuses {
    margin-top: 10px;

    .status {
      margin-right: 12px !important;

      span:not(.green-fg) {
        color: #808080;
      }

      img {
        margin-left: 2px;
      }
    }
  }

  .title {

  }
}

.custom-content-btn {
  display: flex;
  align-items: center;

  .add-img-container {
    width: 65px;
    height: 65px;
    border: 1px solid #808080;
    border-radius: 8px;
    position: relative;
    margin-right: 12px;

    img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      // margin-right: 10px;
      height: 12px;
      width: 12px;
    }
  }
}
