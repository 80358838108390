.invite-account {

  h5 {
    a {
      color: #569ff7 !important;
      //border-bottom: 1px solid #569ff7;
      padding: 0px;
    }
  }

  // todo: generic button styles!
  button {
    width: 100%;
  }

  .invite-account-form,.signup-info {
    margin: 20px;

    ul {
      padding-left: 20px;
    }
  }

  .invite-account-preview {
    .sub-text {
      text-align: left;
    }
  }
}
