
.triangle {
  position: absolute;
  top: -8px;
  right: -38px;
  transform: rotate(45deg);
  width: 0;
  height: 0;
  border-left: 60px solid transparent;
  border-right: 60px solid transparent;
  border-bottom: 60px solid #26c74b;
  z-index: 99;
}
.cp-banner {
  position: absolute;
  top: 0;
  right: 0;
}
.cp-banner p {
  position: relative;
  line-height: 60px;
  color: white;
  z-index: 100;
  width: 50px;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
}
.merchants th, td {
  padding: 15px 50px;
}
.merchant-tile {
  padding: 10px;
  // padding-right: 8px;
  // padding-left: 8px;
}
.merchant-tile .visit-store-btn {
  // background-color: #005fd5;
  // color: white;
  // padding: 8px 18px;
  // margin: 8px 0 18px 0;
  // border: none;
  // border-radius: 3px;
  // outline: none;
  // text-transform: uppercase;
	// letter-spacing: 1px;
	// font-weight: 500;
}
.merchant-tile-inner {
	position: relative;
	/*border-radius: 15px;*/
	/*background-color: #ffffff;*/
	cursor: pointer;
	transition: all 0.3s ease;
	/*box-shadow: 0 1px 6px -1px rgba(0, 0, 0, 0.29);*/
	// overflow: hidden;
	border-radius: 0px;
	box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.02);
	border: 1px solid rgba(0, 0, 0, 0.06);
	background: white;
	/*max-height: 235px;*/
	/*max-height: 250px;*/
  padding: 25px;
  text-align: center;
  min-height: 200px;
}
