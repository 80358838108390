@import 'vars.scss';

.onboard-page {
  width: 100vw;
  height: 100vh;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0;
  margin: 0;
}
.onboard-container {
  margin: auto;
  width: 350px;
  max-width: 100%;
  overflow: hidden;
  text-align: center;
}
.onboard-status-container {
  position: relative;
  width: 100%;
  height: 30px;
  overflow: hidden;
  border-radius: 30px;
  margin: 25px auto;
}
.onboard-status-outerbar {
  position: relative;
  width: 100%;
  height: 100%;
  background: #eee;
}
.onboard-status-innerbar {
  position: absolute;
  width: 0;
  height: 100%;
  top: 0;
  left: 0;
  transition: all 0.4s ease;
  background-image: linear-gradient(118deg, #5d3dff, #2a95ff 32%, #05d5ff 59%, #12d9fb 62%, #20ddf6 66%, #22ddf6 67%, #a6ffcb);
}
.onboard-container .input-text-wrapper {
  background: #F5F4F7;
  background-size: 14px;
  background-repeat: no-repeat;
  background-position: 16px center;
  height: 46px;
  margin-bottom: 20px;
  border-radius: 8px;
}
.onboard-container .input-text-wrapper .input-text {
  width: 300px;
  padding: 14px;
  border: 0;
  background: transparent;
  background-size: 14px;
  background-repeat: no-repeat;
  background-position: 16px center;
  text-indent: 30px;
}
.onboard-container #submit {
  background: black;
  color: white;
  border: 0;
  border-radius: 8px;
  text-transform: uppercase;
  padding: 20px;
  width: 100%;
  box-shadow: 0 10px 10px -10px rgba(0,0,0,.4);
  font-weight: bold;
  cursor: pointer;
}
.onboard-youtube-prof-card {
  position: relative;
  width: 100%;
  height: 300px;
  overflow: hidden;
  transition: all 0.5s ease;
  opacity: 0;
  transform: translateY(200px);
  background: white;
  border-radius: 15px;
  box-shadow: 0 10px 20px -10px rgba(0,0,0,.4);
}
.onboard-youtube-prof-card.loaded {
  opacity: 1;
  transform: translateY(0);
}
.onboard-youtube-prof-card.loaded.loaded2 {
  opacity: 0;
  transform: translateY(-200px);
}
.onboard-youtube-prof-card .onboard-banner {
  position: relative;
  width: 100%;
  height: 150px;
  // background: blue;
  // background: rgb(100, 65, 164);
  background-image: linear-gradient(119deg, rgb(100, 65, 164), rgb(45, 0, 128));
  background-repeat: repeat;
  background-position: center;
  background-size: cover;
}
.onboard-youtube-prof-card .onboard-bottom {
  position: relative;
  width: 100%;
  height: 150px;
  // background: @module !important;
}
.onboard-youtube-prof-card .onboard-prof-pic {
  position: absolute;
  z-index: 999999;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  top: -50px;
  left: 25px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.onboard-youtube-prof-card .onboard-channel {
  position: absolute;
  left: 150px;
  top: 15px;
  font-weight: bold;
  font-size: 18px;
  font-size: 24px;
  // color: white !important;
  font-family: Barlow;
  font-weight: 700;
}
.onboard-youtube-prof-card .onboard-channel-approved {
  position: absolute;
  bottom: 65px;
  left: 0;
  right: 0;
  font-size: 16px;
}
.onboard-youtube-prof-card .onboard-channel-approved img {
  width: 27px;
  margin: -3px 10px;
  padding-bottom: 1px;
}


.loading-status-text {
  position: absolute;
  opacity: 0;
  transform: translateX(-400px);
  transition: all 0.52s ease;
  // color: white !important;
  font-family: 'Barlow';
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  width: 100%;
  margin: 0;
  top: 25px;
}

.loading-status-text.active {
  opacity: 1;
  transform: translateX(0);
}

.loading-status-text.active.completed {
  opacity: 0;
  transform: translateX(400px);
}
