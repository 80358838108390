// .popup-container {
//   position: fixed;
//   width: 100vw;
//   height: 100vh;
//   background-color: rgba(0, 0, 0, 0.2);
//   top: 0;
//   left: 0;
//   z-index: 9999999;
//   overflow: hidden;
//
//   .popup-module {
//     position: fixed;
//     max-width: 673px;
//     max-height: 650px;
//     width: 95%;
//     height: 95%;
//     border-radius: 3px;
//     background-color: #ffffff;
//     box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.24);
//     margin: auto;
//     top: 0;
//     right: 0;
//     bottom: 0;
//     left: 0;
//     overflow: hidden;
//     padding: 25px;

    .compensation-tags {
      position: relative;
      display: inline-block;
      padding: 6px 12px;
      background: #1ab953;
      color: white;
      margin-right: 8px;
      border-radius: 3px;
      text-transform: uppercase;
      font-weight: bold;
    }

    .minimum-requirements {
      margin: 12px 0;
      position: relative;
      display: block;
      text-transform: uppercase;
      letter-spacing: 1px;
      color: #8a8a8a;
    }

    .min-require-options {
      ul {
        padding: 0;
        margin: 0;
      }

      li {
        list-style: none;
      }

      .min-option {
        position: relative;
        // padding: 8px 12px 8px 28px;
        padding: 25px 15px;
        border-radius: 15px;
        margin: 5px 0;
        font-weight: bold;
        cursor: pointer;
        overflow: hidden;
        height: 150px;

        .min-bullet {
          position: absolute;
          top: 13px;
          left: 10px;
          width: 10px;
          height: 10px;
          border-radius: 10px;
          border: 1px solid #504f52;
        }

        .min-price-offer {
          position: absolute;
          width: 70px;
          height: 100%;
          top: 0;
          left: 0;
          text-align: center;
          padding: 9px 0;

          span {
            font-family: Barlow;
            font-weight: 700;
            font-size: 17px;
          }
        }

        .min-option-img-container {
          position: relative;
          display: block;
          height: 70px;
          width: 50px;
          margin: 0 auto 15px auto;

          .min-option-img {
            position: absolute;
            width: 50px;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
          }
        }


        p {
          text-align: center;
          font-size: 16px;
          font-family: Barlow;
          font-weight: 500;
        }
      }

      .min-option.active {

        p {
          color: #fff;
        }

        .min-bullet {
          border-color: #fff;
          background: #fff;
        }
      }
    }

    .min-require-options.instagram {
      .min-option:hover {
        border-color: #b900b4;

        .min-bullet {
          border-color: #b900b4;
        }
      }

      .min-option.active, .min-option:hover.active {
        border: none;
        background-image: linear-gradient(-135deg, #1400c8,#b900b4,#f50000) !important;
        // padding: 9px 12px 9px 28px;

        .min-bullet {
          border-color: white;
        }
      }
    }

    .min-require-options.youtube {
      .min-option:hover {
        border-color: #ff0000;

        .min-bullet {
          border-color: #ff0000;
        }
      }
      .min-option.active, .min-option:hover.active {
        border: none;
        // background-image: linear-gradient(-135deg,#1400c8,#b900b4,#f50000) !important;
        background: #ff0000 !important;
        // padding: 9px 12px 9px 28px;

        .min-bullet {
          border-color: white;
        }
      }
    }

    .min-require-options.twitch {
      .min-option:hover {
        border-color: #6441A4;

        .min-bullet {
          border-color: #6441A4;
        }
      }
      .min-option.active, .min-option:hover.active {
        border: none;
        // background-image: linear-gradient(-135deg,#1400c8,#b900b4,#f50000) !important;
        background: #6441A4 !important;
        // padding: 9px 12px 9px 28px;

        .min-bullet {
          border-color: white;
        }
      }
    }

    .min-require-options.mixer {
      .min-option:hover {
        border-color: #212c3d;

        .min-bullet {
          border-color: #212c3d;
        }
      }
      .min-option.active, .min-option:hover.active {
        border: none;
        // background-image: linear-gradient(-135deg,#1400c8,#b900b4,#f50000) !important;
        background: #212c3d !important;
        // padding: 9px 12px 9px 28px;

        .min-bullet {
          border-color: white;
        }
      }
    }

    .product-link {
      position: relative;
      display: block;

      .product-tag {
        position: absolute;
        left: 15px;
        top: 16px;
        width: 20px;
        z-index: 2;
      }

      .product-delete {
        position: absolute;
        right: 15px;
        top: 17px;
        width: 17px;
        opacity: 0.7;
      }
    }

    textarea, input {
      position: relative;
      display: block;
      width: 100%;
      border: 1px solid #eee;
      border-radius: 3px;
      padding: 15px 45px 15px 45px;
      margin: 5px 0;
    }

    textarea.dark-input  {
      height: 100px;
      resize: none;
      padding: 10px 45px 10px 45px;
    }

    .textarea-container {
      position: relative;
    }

    .textarea-container img {
      position: absolute;
      top: 13px;
      left: 15px;
      width: 18px;
      z-index: 2;
    }

    // button {
      // position: relative;
      // display: block;
      // margin: 15px auto;
      // background: black;
      // color: white;
      // padding: 12px 15px 12px 15px;
      // border: 1px solid rgba(0, 0, 0, 0.08);
      // border-radius: 3px;
      // box-shadow: rgba(0, 0, 0, 0.05) 0px 2px 9px 0px;
      // text-transform: uppercase;
      // letter-spacing: 1px;
      // font-color: #333;
      // font-size: 16px;
      // font-weight: 500;
      // cursor: pointer;
      // width: 100%;
    // }
//   }
// }

// .popup-container {
//   .popup-module {
//     overflow: hidden;
//
//     .popup-module-outer {
//       position: relative;
//       width: 100%;
//       height: 100%;
//       overflow: auto;
//
//       .popup-module-inner {
//         position: relative;
//       }
//     }
//   }
// }
