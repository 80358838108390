body {
	background: #f6f9fc;
}
canvas {
	min-height: 400px;
}
.products {
	position: relative;
	overflow: auto;
}
.section {
	/*display: none;*/
	/*border-radius: 2px;*/
	/*background-color: #ffffff;*/
	/*border: solid 1px rgba(105, 129, 152, 0.15);*/
	/*margin: 4px 0;*/
  /*padding: 5px 20px;*/
}
.section-controls, .section-tables {
	background: none;
	border: none;
	overflow: initial;
}
.section-view {
	background: none;
	border: none;
	box-shadow: none;
	padding: 10px 0;
}
.section-controls .dropdown {
	cursor: pointer;
	/*height: 50px;*/
	/*overflow: auto;*/
	height: 105px;
	font-size: 13px;
	font-weight: 600;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: 1.2px;
	background: white;
	color: #698198;
	padding: 18px;
	background-color: rgba(255, 255, 255, 0.96);
	/*box-shadow: 0 4px 7px 0 rgba(0, 0, 0, 0.05);*/
	/*border: solid 1px #cfd8dc;*/
	border: solid 1px rgba(105, 129, 152, 0.15);
}
.dropdown:hover {
	/*background: #f6f9fc;*/
}
.dropdown-video {
	width: 400px;
	/*margin: 30px 0;*/
	margin: 0;
	/*border: 1px solid #f6f9fc;*/
	border-radius: 4px;
}
.dropdown-toggle {
	height: 100%;
}
.dropdown-menu {
	position: absolute;
	padding: 0;
	margin: 0;
	width: 100%;
	background-color: rgba(255, 255, 255, 0.96);
	box-shadow: 0 4px 7px 0 rgba(0, 0, 0, 0.05);
	border: solid 1px #cfd8dc;
}
.dropdown-chosen img {
	max-height: 65px;
}
.dropdown ul {
	padding: 0;
	margin: 0;
}
.dropdown ul li {
	position: relative;
	list-style: none;
	padding: 0;
	margin: 0;
	width: 100%;
	background: white;
	border-bottom: 1px solid #f6f9fc;
	text-align: center;
	line-height: 25px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* number of lines to show */
  height: 65px;
  max-height: 65px;
	/*padding: 15px;*/
	cursor: pointer;
}
.dropdown ul li:hover {
	background: #f6f9fc;
}
.dropdown ul li div {
	height: 100%;
}
.dropdown ul li div.col-md-8 {
	text-align: left;
  padding: 7px;
}
.dropdown ul li.dropdown-all {
	line-height: 65px;
}
.dropdown-result img {
	position: absolute;
	margin: auto;
	top: 0;
  left: 0;
  right: 0;
  bottom: 0;
	max-width: 80%;
	max-height: 50px;
}
.dropdown .dropdown-menu-dates li {
	height: 45px;
  max-height: 45px;
	line-height: 45px;
}
.custom-dates {
	/*position: absolute;*/
	/*display: none;*/
	position: relative;
	/*padding: 10px;*/
	background: white;
	/*box-shadow: 0 4px 7px 0 rgba(0, 0, 0, 0.05);*/
	/*border: solid 1px #cfd8dc;*/
	overflow: auto;
}
.ui-datepicker {
	background: white;
	box-shadow: 0 4px 7px 0 rgba(0, 0, 0, 0.05);
	border: solid 1px #cfd8dc;
}
.ui-datepicker-calendar {
	min-width: 300px;
}
input.hasDatepicker {
	font-size: 18px;
	font-weight: 300;
	padding: 5px;
}
.ui-datepicker-header .ui-corner-all {
	position: relative;
  display: inline-block;
  float: left;
  padding: 10px 20px;
	cursor: pointer;
}
.ui-datepicker-header span {
	margin-left: 10px;
}
.ui-datepicker-title {
	position: relative;
  display: inline-block;
  float: right;
  padding: 10px 20px;
}
table.ui-datepicker-calendar {
  border-collapse: collapse;
	text-align: center;
}
.ui-datepicker-calendar th {
	text-align: center;
}
.ui-datepicker-calendar tr {
	height: 40px;
	border-radius: 2px;
	background-color: #ffffff;
	border: solid 1px rgba(105, 129, 152, 0.15);
	padding-top: .5em;
	padding-bottom: .5em;
}
.ui-datepicker-calendar td {
	padding-top: .5em;
	padding-bottom: .5em;
}
.ui-datepicker .ui-state-disabled {
	color: #c4c4c4;
}
.date-filter-container {
	width: 100%;
	height: 50px;
	/*border-top: 1px solid gray;*/
}
.date-filter {
	width: 100%;
	height: 100%;
	background: none;
  border: none;
  /*border-right: 1px solid gray;*/
}
button#update {
	border-radius: 2px;
	background-color: #df0700;
	box-shadow: 0 2px 4px 0 rgba(100, 100, 100, 0.5);
	border: solid 1px #df0700;
	margin-top: 22px;
	font-size: 12px;
	font-weight: 600;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: 0.2px;
	text-align: center;
	color: #ffffff;
	padding: 9px 13px;
	outline: 0;
}
/*.col-md-5 {
	width: 20%;
}*/
.section-tables {
	padding: 0;
}
.analytics-table {
	width: 100%;
}
.analytics-table tr:nth-child(odd){
	background: none;
}
.analytics-table tr.table-headers {
	border-bottom: 1px solid #ddd;
}
.analytics-table th {
  padding: 15px 20px;
}
.analytics-table td {
	padding: 5px 20px;
}
.analytics-table .table-cell {
	height: 60px;
	max-height: 60px;
	line-height: 60px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1; /* number of lines to show */
}
.analytics-table td .link-product-img {
	display: none;
	max-width: 50px;
	max-height: 100%;
}
.analytics-table td .link-video-img {
	max-height: 100%;
	/*max-width: 30px;
	max-height: 30px;*/
}
.analytics-table td .content-video-img {
	display: none;
	max-width: 50px;
	max-height: 100%;
}
.analytics-table td .products-product-img {
	display: none;
	/*max-width: 30px;
	max-height: 30px;*/
	max-height: 100%;
}
.section-stats, .section-controls, .section-chart, .section-tables {
	display: none;
}
.section-chart {
	margin-top: 40px;
}
.section-stats {
	padding: 0;
	margin: 0;
}
.section-tables {
	position: relative;
	min-height: 850px;
}
.stats h5 {
	font-size: 15px;
	margin: 0 0 10px 0;
}
.stats h3 {
	font-size: 22px;
	/*line-height: 65px;*/
	margin: 0;
}
.stats {
	color: black;
	padding-top: 20px;
  padding-bottom: 20px;
	border-right: 1px solid #ddd;
	border-bottom: 1px solid #ddd;
	margin: 0;
	cursor: pointer;
}
.stats.active, .stats:hover {
	border-bottom: 1px solid #000;
	color: #000;
}

.product .clicks {
	position: absolute;
	top: 10px;
  left: 20px;
}
.product .clicks img {
	width: 15px;
	margin-top: -2px;
}
.product .clicks span {
	margin: 0 0 0 5px;
	color: #F6A623;
}

.performance-filters {
	position: relative;
	display: block;
	overflow: auto;
	margin: 0 auto;
	width: 531px;
}
.performance-filters .performance-filter {
	display: inline-block;
	float: left;
	width: 175px;
	height: 53px;
	border: solid 1px #000000;
	background: white;
	text-align: center;
	line-height: 53px;
	cursor: pointer;
}
.performance-filters .performance-filter:hover, .performance-filters .performance-filter.active {
	//background-color: rgba(0, 0, 0, 0.8);
	//color: white;
}
.performance-filters .performance-filter.merchant-filter {
	border-left: none;
	border-right: none;
}
// .dropdown-module {
//
// }
#calendar {
  // font-size: 16px;
  box-shadow: rgba(0, 0, 0, 0.22) 0 2px 4px 0;
  padding: 10px 25px;
  border-radius: 15px;
  // box-shadow: rgba(0 0 0 / 32%) 0px 2px 35px -24px;
  border: 1px solid rgba(0,0,0,0);
}
#calendar:hover {
	//border-color: rgba(154, 159, 167, 0.64);
}
.flatpickr-calendar {
	margin-top: 4px;
	border-radius: 3px;
	padding: 30px;
	width: 540px;
  background: #fff !important;
	box-shadow: 0 8px 35px -14px rgba(0, 0, 0, 0.5);
	border: 1px solid rgba(0, 0, 0, 0.06);
}
.flatpickr-calendar .flatpickr-month {
	width: 309px;
  float: right;
}
.flatpickr-innerContainer {
	float: right;
}
.flatpickr-calendar .shortcuts {
	display: none;
	position: relative;
  display: inline-block;
	width: 150px;
	height: 100%;
  padding: 15px 15px 0 15px;
  float: left;
}
.flatpickr-calendar .shortcuts li {
	line-height: 29px;
  padding: 7.2px 24px;
	font-size: 14px;
	list-style: none;
  padding: 0;
  margin: 0;
	cursor: pointer;
}
.flatpickr-calendar .shortcuts li a {
  color: #707383 !important;
	padding: 8px 15px;
	transition: color .15s ease;
}
.flatpickr-calendar .shortcuts li:hover a {
	color: #000;
}
.flatpickr-calendar .shortcuts li a.active {
	background: #000;
  border-radius: 1000px;
	color: white !important;
}
.flatpickr-day.selected, .flatpickr-day.startRange, .flatpickr-day.endRange, .flatpickr-day.selected.inRange, .flatpickr-day.startRange.inRange, .flatpickr-day.endRange.inRange, .flatpickr-day.selected:focus, .flatpickr-day.startRange:focus, .flatpickr-day.endRange:focus, .flatpickr-day.selected:hover, .flatpickr-day.startRange:hover, .flatpickr-day.endRange:hover, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.endRange.nextMonthDay {
	background: #000;
	border-color: #000;
}
.flatpickr-day.inRange, .flatpickr-day.prevMonthDay.inRange, .flatpickr-day.nextMonthDay.inRange, .flatpickr-day.today.inRange, .flatpickr-day.prevMonthDay.today.inRange, .flatpickr-day.nextMonthDay.today.inRange, .flatpickr-day:hover, .flatpickr-day.prevMonthDay:hover, .flatpickr-day.nextMonthDay:hover, .flatpickr-day:focus, .flatpickr-day.prevMonthDay:focus, .flatpickr-day.nextMonthDay:focus {
	//background: #ffa6a6;
	//border-color: #ffa6a6;
	//box-shadow: -5px 0 0 #ffa6a6, 5px 0 0 #ffa6a6;
}
.analytics-module-container {
	position: relative;
	display: inline-block;
}
.module-body {
	min-height: 300px;
	max-height: 300px;
	overflow: auto;
}
.analytics-module table {
	width: 100%;
  table-layout: fixed;
}
.analytics-module th, .analytics-module td {
  padding: 15px 0;
	word-wrap: break-word;
	width: 100%;
}

.analytics-header {
  display: flex;
  align-items: center;
}

.table-row {
  display: flex;
  align-items: center;

  > div {
    padding-left: 0;
    padding-right: 0;
  }
}

@media screen and (min-width: 1200px){
	.tile-container .col-lg-2 {
		width: 20%;
		margin: 0 0 12px 0;
	}
}
