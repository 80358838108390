.intercom-duplicate {
  height: calc(100vh - 192px);
  overflow: hidden;

  .intercom-background {
    opacity: .65;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    position: absolute;
    z-index: 1;
    background-image: url(https://static.intercomassets.com/ember/assets/images/messenger-backgrounds/background-1-99a36524645be823aabcd0e673cb47f8.png);
  }

  .chat-messages-container {
    overflow: auto;
    padding: 18px 18px 125px 18px;
    height: calc(100vh - 78px);
  }

  .chat-bubble-container {
    position: relative;
    padding-left: 46px;
    max-width: calc(100% - 69px);
    margin: 10px 0;
    z-index: 3;

    img.chat-profile {
      position: absolute;
      width: 32px;
      top: 10px;
      left: 0;
      border-radius: 50px;
    }

    .chat-bubble {
      padding: 14px;
      border-radius: 8px;
      word-break: break-word;

      p {
        line-height: 18px;
        color: #222;
        font-size: 14px;
        font-weight: 400;
      }
      a {
        padding: 0;
      }
    }
    .chat-timestamp {
      span {
        color: #888;
        font-size: 13px;
        line-height: 20px;
      }
    }
  }
  .chat-bubble-container.admin {
    float: left;

    .chat-bubble {
      background: #525252;
    }
    .chat-timestamp {
      float: left;
    }
  }
  .chat-bubble-container.user {
    float: right;

    .chat-bubble {
      background: #1982FC;
    }
    .chat-timestamp {
      float: right;
    }
  }

  .chat-input-container {

    .chat-input {
      border: 1px solid rgba(0,0,0,.2);
      border-radius: 4px;
      box-sizing: border-box;
      transition: box-shadow linear 40ms,border-color linear .2s;
      position: relative;
      box-shadow: 0 2px 8px 0 rgba(0,0,0,.1);
      margin: 0 15px 15px;
      overflow: hidden;
      position: absolute;
      bottom: 25px;
      width: calc(100% - 50px);
      margin: 0 auto;
      z-index: 3;
      left: 0;
      right: 0;

      .chat-input-text {
        padding: 14px;

        // textarea {
        //   border: none;
        //   resize: none;
        //   width: 100%;
        //   height: 120px;
        //   margin: 0;
        //   padding: 14px;
        // }
      }

      .chat-input-actions {
        padding: 10px;
        text-align: right;

        button {
          width: 100px;
          border: 1px solid;
          border-color: rgba(0,0,0,.2);
          border-radius: 4px;
          padding: 5px 12px 7px;
          display: inline-block;
          cursor: pointer;
          -webkit-appearance: none;
          -webkit-touch-callout: none;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          box-sizing: border-box;
          transition: box-shadow linear 40ms;
          background-color: #286efa;
          color: #fff;
          font-weight: 500;
          margin-left: 10px;
        }
      }
    }

    .view-in-intercom {
      position: absolute;
      bottom: 6px;
      left: 12px;

      a {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 10px;
        color: #b3b3b3;
      }
      a:hover {
        color: #868585;
      }
    }
  }

}

.intercom-duplicate.intercom-template {
  padding: 0;

  .dark-input.templates-subject {
    width: 100%;
    margin-bottom: 8px;
    border: 1px solid rgba(0, 0, 0, 0.2) !important;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }

  .chat-input-container .chat-input {
    position: relative;
    margin: 0;
    bottom: initial;
    width: 100%;
    border-radius: 8px;
  }
}


@media (max-width: 767px){
  .chat-messages-container {
    padding-bottom: 205px !important;
  }

  .chat-input {
    bottom: 125px !important;
  }

  .page-with-side-navigation article {
    overflow: initial !important;
  }
}
