@import "vars.scss";

/*

Material Design Guidelines:
https://material.io/design/color/dark-theme.html#

*/

.module {
  // background: @module !important;
  // color: white !important;
  border: 1px solid rgba(0, 0, 0, 0.04);
  // box-shadow: rgba(0, 0, 0, 0.22) 0px 2px 5px -2px;
  box-shadow: rgba(0, 0, 0, 0.32) 0px 2px 35px -24px;
}

.sub-text {
  color: #BDBBC0;
}
.st0 { margin: 0 }

.brand-game {

  .brand-meta {

    h3 {
      // color: white;
    }
  }

}

.twitch-stream-module {
  transition: all 0.32s ease;
  cursor: pointer;

  .twitch-stream-row {
    position: relative;
    height: 100px;
    left: -25px;
    z-index: 3;

    .twitch-stream-img {
      width: 125px;
      height: 100%;
      float: left;
      display: inline-block;

      .twitch-stream-img-obj {
        position: relative;
        width: 100%;
        height: 140px;
        margin-top: -15px;
        border-radius: 2px 0 0 2px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }
    }
  }

  .gradient-fade {
    width: 250px;
    height: 100%;
    top: 0;
    z-index: 9;
    left: 34px;
    background-image: linear-gradient(to right, $module + '00', $module);
  }
}

.twitch-stream-module:hover {
  // transform: translateY(-12px);
  transform: translateY(-8px);

  h4 {
    text-decoration: underline;
  }
}

.stream-tile {
  min-height: 250px;

  .stream-display {
    height: 125px;
    position: relative;
    display: inline-block;

    // .stream-game-background {
    //   position: absolute;
    //   width: 100%;
    //   top: 0;
    //   left: 0;
    // }
    .stream-game-img {
      width: 95px;
      border-radius: 3px;
    }
  }

  .stream-title {
    position: relative;
    display: inline-block;
    height: 125px;
    padding-left: 10px;
    max-width: 170px;

    h5 {
      width: 100%;
      overflow: hidden;
      line-height: 1;
      height: 14px;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    p {
      color: rgb(189, 187, 192);

      span {

      }
    }
  }

  .stream-stat {
    align-items: center;
    font-size: 24px;
    font-weight: bold;
    line-height: 20px;
  }

  .stream-stat-label {
    text-transform: uppercase;
    font-weight: 500;
    color: #bdbbc0;
    letter-spacing: 0.6px;
    font-size: 12px;
  }

}

// .stream-tile {
//   min-height: 300px;
//
//   .stream-display {
//     height: 180px;
//
//     .stream-game-background {
//       position: absolute;
//       width: 100%;
//       top: 0;
//       left: 0;
//     }
//     .stream-game-img {
//       position: absolute;
//       width: 95px;
//       margin: 0 auto;
//       top: 100px;
//       left: 0;
//       right: 0;
//       border-radius: 3px;
//     }
//   }
//
//   .stream-stats {
//     position: absolute;
//     top: 5px;
//     left: 5px;
//
//     .stream-stats-container {
//       position: relative;
//
//       .stream-stat {
//         position: relative;
//         display: inline-block;
//         align-items: center;
//         font-size: 14px;
//         background: rgba(17, 17, 17, 0.8);
//         border-radius: 3px;
//         padding: 4px 8px;
//         margin-right: 4px;
//         font-weight: bold;
//       }
//     }
//   }
//
//   .stream-meta {
//
//   }
//
// }

// .game-header {
//   background: linear-gradient(0deg, #00000091 0, rgba(0, 0, 0, 0.05)), linear-gradient(90deg, #000000b5 0, rgba(0, 0, 0, 0.05));
// }

.dark-input {
  position: relative;
  padding: 10px;
  border-radius: 3px;
  border: 1px solid $dark-input;
  transition: all 0.24s ease;
  // border-radius: 100px;

  &.error, &.error:hover, &.error:focus {
    border-color: $error !important;
  }
}
.dark-input.darker {
  background-color: $dark-input-darker;
}
.dark-input:hover, .dark-input:focus {
  background: $dark-input-focus !important;
  border-color: $primary !important;
}
.dark-input.dark-input-btn {
  background: #005aff !important;
  background: linear-gradient(to left top, #005aff, #0089ff) !important;
  color: white !important;
  cursor: pointer;
}
.dark-input.dark-input-btn:hover, .dark-input.dark-input-btn:focus {
  background: linear-gradient(to left top, #014fdf, #0783ed) !important;
}

.dark-input-container {
  position: relative;
  font-size: 18px;
  font-weight: bold;

  .dark-input-preicon {
    position: absolute;
    top: 7px;
    left: 8px;
    z-index: 3;
    color: #808080;
  }
  input.dark-input {
    padding: 6px 6px 8px 22px;
    margin-bottom: 0;
  }
}

.slide-out {

  .game-header {
    background: linear-gradient(0deg, #2d3135 0, rgb(14, 40, 86)), linear-gradient(90deg, #194597 0, rgb(41, 74, 180));
  }
}

.game-header-meta-item {
  color: white !important;

  p {
    color: white !important;

    strong {
      color: white !important;
    }
  }
}

.left-menu-circle {
  display: none;
  position: absolute;
  width: 48px;
  height: 48px;
  margin-top: -4px;
  z-index: 0;
  top: -5px;
  left: -8px;
  border-radius: 30px;
  transition: all 0.28s ease;
}

.nav-sidenav img {
  margin-top: 5px;
}

#nav-menu-options {
  li {
    img {
      z-index: 9;
      position: relative;
    }
  }

  li#dashboard {
    img {
      width: 26px;
      margin-left: 3px;
      // width: 20px;
      // margin-left: 6px;
      margin-right: 20px;
    }
  }

  li:hover {

    .left-menu-circle {
      display: none;
      // background: #ff8e31;
      background-color: #3abdfe;
      background-image: -webkit-gradient(linear, left top, left bottom, from(#61d8ff), to(#36aafe));
      background-image: linear-gradient(to bottom right, #61d8ff, #36aafe);
      border-radius: 15px;
      opacity: .69;
    }
  }

  li.active {

    .left-menu-circle {
      display: none;
      // background: #ff8e31;
      background-color: #3abdfe;
      background-image: -webkit-gradient(linear, left top, left bottom, from(#61d8ff), to(#36aafe));
      background-image: linear-gradient(to bottom right, #61d8ff, #36aafe);
      border-radius: 15px;
    }
  }
}

.main-container {

  h3 {
    border: none;
    margin-bottom: 0;

    // span, a {
    //   font-weight: 600;
    //   font-size: 12px;
    // }
  }
}

.white-logo-container {
  display: none;
}

.achievement-container {
  position: relative;
  padding: 10px 10px 15px 10px;
  // margin-bottom: 10px;
  font-family: Barlow;

  .achievement-text {
    margin: 0;
  }

  img {
    width: 55px;
    margin-top: 3px;
  }

  .achievement-progress {
    position: relative;

    .achievement-bar {
      position: relative;
      display: inline-block;
      width: 300px;
      max-width: 75%;
      height: 8px;

      .achievement-outer {
        position: relative;
        width: 100%;
        height: 100%;
        border: 1px solid white;
        border-radius: 2px;
        transform: skewX(-20deg);
        overflow: hidden;

        .achievement-inner {
          position: absolute;
          top: 0;
          left: 0;
          width: 0;
          height: 100%;
          background: white;
          transition: all 0.85s ease;
        }
      }
    }
  }

  .achievement-num {
    position: relative;
    display: inline-block;
    padding-left: 10px;
    width: 20px;
    height: 20px;

    span {
      position: absolute;
      font-weight: bold;
      font-size: 16px;
      margin-top: 5px;
      font-family: 'Muli';
      font-weight: 900;
    }
  }
}

.achievement-container.bounty-progress {
  margin-top: 15px;

  .achievement-progress {

    .achievement-bar {
      height: 16px;
    }
  }
}

.achievement-container.bounty-progress.bounty-offline {
  margin-top: 15px;

  .achievement-progress {

    .achievement-bar {

      .achievement-outer {
        border-color: #dadada;

        .achievement-inner {
          background: #dadada;
        }
      }
    }
  }

  .achievement-num {
    color: #dadada;
  }
}



.bounty-details {
  position: absolute;
  display: none;
  width: 100%;
  height: 600px;
  top: 150px;
  left: 0;
  background: #202225;
  padding: 25px;
}

.bounty-details.active {
  display: block;
}

.live-status {
  float: left;
}

.live-timecode {
  position: relative !important;
  display: inline-block !important;
  float: left !important;
  margin-left: 0px !important;
  margin-top: 1px !important;
}


.integration-btn {
  display: flex;
  height: 100%;
  width: 100%;
  -webkit-box-align: center;
  align-items: center;
  text-align: left;
  color: rgba(255, 255, 255, 0.65);
  box-shadow: rgba(0, 0, 0, 0.02) 0px 5px 5px 0px;
  cursor: pointer;
  border-radius: 4px;
  transition: box-shadow 100ms ease-in-out 0s,
              -webkit-box-shadow 100ms ease-in-out 0s,
              -moz-box-shadow 100ms ease-in-out 0s;
  background-color: #6441a4;

  .integration-btn-text-container {
    position: relative;
    -webkit-box-flex: 1;
    z-index: 0;
    flex: 1 1 0%;
    padding: 18px 24px 18px 42px;
    overflow: hidden;

    .integration-btn-text {
      display: flex;
      box-sizing: border-box;
      -webkit-box-flex: 0;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      flex-direction: row;
      flex-wrap: wrap;
      margin-right: -12px;
      margin-left: -12px;
      flex: 0 1 auto;
      -webkit-box-align: center;
      align-items: center;

      .integration-btn-text-left {
        box-sizing: border-box;
        -webkit-box-flex: 0;
        min-width: 0px;
        padding-right: 12px;
        padding-left: 12px;
        flex: 0 0 auto;
        -webkit-box-flex: 1;
        flex-grow: 1;
        flex-basis: 0px;
        max-width: 100%;

        .integration-btn-img-container {
          display: inline-flex;
          -webkit-box-align: center;
          align-items: center;
          -webkit-box-pack: center;
          justify-content: center;
          color: rgb(255, 255, 255);
          border-radius: 4px;
          position: absolute;
          z-index: -1;
          top: 50%;
          left: 0px;
          transform: translateX(-18%) translateY(-50%);
          opacity: 0.2;
          pointer-events: none;
          width: 144px !important;
          height: 144px !important;
          font-size: 6rem !important;
          background-color: transparent !important;
          color: rgb(255, 255, 255) !important;

          .integration-btn-img {
            width: 55%;
            height: 55%;
            background-size: contain;
            background-position: center center;
            background-repeat: no-repeat;
            background-image: url(data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgOTcgOTAiIGZpbGw9Im5vbmUiI…N0b3AtY29sb3I9IndoaXRlIi8+CjwvcmFkaWFsR3JhZGllbnQ+CjwvZGVmcz4KPC9zdmc+Cg==);
          }
        }

        .integration-btn-title {
          font-size: 20px;
          color: white !important;
          font-weight: bold;
        }
        .integration-btn-sub {
          color: rgba(255, 255, 255, 0.65);
          font-size: 16px;
        }
      }

      .integration-btn-text-right {
        box-sizing: border-box;
        -webkit-box-flex: 0;
        min-width: 0px;
        padding-right: 12px;
        padding-left: 12px;
        flex: 0 0 auto;

        button {
          -webkit-appearance: button;
          -webkit-writing-mode: horizontal-tb !important;
          text-rendering: auto;
          color: buttontext;
          letter-spacing: normal;
          word-spacing: normal;
          text-transform: none;
          text-indent: 0px;
          text-shadow: none;
          display: inline-block;
          text-align: center;
          align-items: flex-start;
          background-color: buttonface;
          box-sizing: border-box;
          margin: 0em;
          font: 400 11px system-ui;
          padding: 1px 7px 2px;
          border-width: 1px;
          border-style: solid;
          border-color: rgb(216, 216, 216) rgb(209, 209, 209) rgb(186, 186, 186);
          border-image: initial;
          color: inherit;
          -webkit-appearance: button;
          user-select: none;
          background: none;
          border-width: 0px;
          border-style: initial;
          border-color: initial;
          border-image: initial;
          font: inherit inherit inherit inherit inherit inherit inherit inherit inherit;
          overflow: visible;
          padding: 0px;
          font-weight: 700;
          color: white;
          cursor: pointer;
          text-decoration: none;
          transition: color 100ms ease-in-out 0s;
          font-size: 16px;
          border: none;
        }
      }
    }
  }
}


.brand-tag {
  /* border: 1px solid #dad8de; */
  background: #5a5a5a !important;
  border-radius: 4px;
  padding: 3px 5px;
  margin-right: 6px;
  color: white !important;
  border: none !important;
  font-family: Barlow !important;
  font-weight: 900 !important;
  text-transform: uppercase !important;
  font-size: 12px !important;
}

.analytics-module {
  // background: #2d3135 !important;
}

.main-container {
  .search-container {
    // background: #2d3135 !important;
  }
}

.tips {

}

.main-container .section-1, .main-container .section-2 {

}

.earnings-cashout {
  text-transform: uppercase;
  color: #BDBBC0;
  font-size: 14px;
}

.see-withdrawal-history {
  color: #BDBBC0 !important;
}

//#calendar {
//  border-color: #BDBBC0;
//  color: #BDBBC0;
//}
//
//#calendar:hover {
//  border-color: #dddddd;
//  color: #dddddd;
//}
//
//#calendar.flatpickr-input.active {
//  border-color: #fff;
//  color: #fff;
//}

.flatpickr-calendar {
  //background: #202225 !important;
  //color: white !important;
}

.flatpickr-calendar.arrowTop:before {
  //background: #202225 !important;
}

.flatpickr-month {
  //color: white !important;
}

.flatpickr-prev-month svg, .flatpickr-next-month svg {
  //fill: white !important;
}

.flatpickr-calendar .shortcuts li a {
  //color: #dedede !important;
}

.flatpickr-calendar .shortcuts li a.active, .flatpickr-weekday, .flatpickr-day {
  //color: white !important;
}

.flatpickr-day.inRange {
  //color: #202225 !important;
}

.row.paypal-balance-minimum h3 {
  color: #e6e6e6;
}


.bounty-countdown {

  .countdown-day {
    position: relative;
    display: inline-block;
    padding: 0 4px;

    .stream-stat {
      margin: 0px;
      font-family: 'Barlow';
      font-weight: 900;
      font-size: 32px;
      line-height: 36px;
    }
    .stream-stat-label {
      font-family: 'Barlow';
      font-weight: 900;
      font-size: 14px;
    }
    .stream-stat.stream-stat-sub {
      position: absolute;
      font-size: 14px;
      font-weight: 500;
      bottom: -36px;
      white-space: nowrap;
      right: 0;
      padding: 0 4px;
    }
  }

  .countdown-times {
    display: inline-block;
    padding: 0 4px;

    .stream-stat {
      margin: 0;
      font-family: 'Barlow';
      // font-weight: 900;
      font-weight: 400;
      font-size: 20px;
    }
    .stream-stat-label {
      font-family: 'Barlow';
      // font-weight: 900;
      font-weight: 400;
      font-size: 10px;
    }
  }
}

.available-text {
  text-transform: uppercase;
  color: #BDBBC0;
}

.beta-logo-txt {
  font-size: 12px;
  font-family: Barlow;
  font-weight: 100;
  position: absolute;
  margin: 27px 0 0 5px;
  text-align: center;
}


.offer-box-page {
  background: #36393f;
  color: white;

  .offer-box-name-point-invert {
    border-right-color: #36393f !important;
  }
}

.message-container .message-column ul .message-tab.active .message-tab-container .message-tab-header h3.message-tab-layout-name {
  font-family: Barlow !important;
  font-weight: 700 !important;
}

.intercom-duplicate .chat-input-container .chat-input .chat-input-actions button {
  font-family: Barlow !important;
  font-weight: 900 !important;
  margin-right: 15px !important;
}

.message-container .message-column ul li .message-tab .message-tab-container .message-tab-header .message-tab-wrapper .message-tab-avatar-container .message-tab-avatar .message-tab-avatar-media {
  font-family: Barlow !important;
  font-weight: 900 !important;
  background-color: #cc395e !important;
}

.tabbed-options .tabbed-option {
  font-family: Barlow !important;
}

.tabbed-options .tabbed-option.active, .tabbed-options .tabbed-option:hover {
  color: #59a8de !important;
}

.tabbed-options .tabbed-option hr {
  height: 3px !important;
  background: #59a8de !important;
  border: none !important;
}

.paypal-top .close-paypal {
  opacity: 1 !important;

  img {
    width: 16px !important;
  }
}


.restaurant-meta {

  h3 {
    font-family: Barlow;
    font-weight: 500;
  }

  .restaurant-ratings {
    span.ratings-text {

    }
  }

  .restaurant-sub {
    .restaurant-price {
      font-weight: 900 !important;
      font-family: Barlow !important;
    }
  }
}

.slide-out-restaurant h3 {
  font-family: Barlow;
  font-weight: 500;
}

.slide-out-restaurant .restaurant-ratings .ratings-text {

}

.slide-out-restaurant {
  .restaurant-price {
    font-weight: 900 !important;
    font-family: Barlow !important;
  }

  .restaurant-sub {
    .restaurant-dot, .restaurant-categories, .restaurant-city {

    }
  }
}

.plaid-container {

  button {
    font-family: Barlow !important;
    font-weight: 900 !important;
    text-transform: uppercase !important;
  }
}

.stream-tile.start-bounty {

  .start-bounty-container {
    margin-top: 30px;

    .start-bounty-btn {
      font-family: Barlow;
      font-weight: 900;
      width: initial;
      padding: 12px 30px 12px 30px;
      background: none;
      overflow: hidden;

      .start-bounty-btn-bg {
        background: #8e5ace;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 0;
      }

      .start-bounty-btn-fill {
        position: absolute;
        background: #783bc3;
        width: 0%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 0;
        transition: all 0.38s ease;
      }

      span {
        position: relative;
      }
    }

    .start-bounty-btn:hover {
      .start-bounty-btn-fill {
        width: 100%;
      }
    }
  }
}

.search-container.live-links {

  .list-links-container {

    ul.list-links {
      background: none;

      li {
        background: none;
        // border-color: #BDBBC0;

        .link-menu-ellipsis {
          // color: #BDBBC0;
        }

        // .label-input {
        //   color: white;
        // }

        .input-fill hr.back {
          // border-color: #BDBBC0;
        }
      }
    }
  }
}
