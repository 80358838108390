// tabs: todo, move
.react-tabs {
  -webkit-tap-highlight-color: transparent;
}

[aria-hidden=true] {
  max-height: 100vh;
}

.react-tabs__tab-list {
  border-bottom: 1px solid #f0f0f0;
  margin: 0 0 10px;
  padding: 0;
  font-size: 18px;
}

.react-tabs__tab {
  display: inline-block;
  border: 1px solid transparent;
  border-bottom: none;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 6px 12px;
  cursor: pointer;
  margin-right: 2px;
  margin-bottom: 1px;
  background-color: #fafafa;

  &:hover {
    background-color: #45b9fe26;
  }
}

.react-tabs__tab--selected {
  //background: #fff;
  //border-color: #aaa;
  //color: black;
  //border-radius: 5px 5px 0 0;
  background-color: #45b9fe26;

  &::after {
    content: ' ';
    position: absolute;
    background-color: #3abdfe;
    background-image: linear-gradient(to top, #61d8ff, #36aafe);
    width: 100%;
    height: 4px;
    border-radius: 6px 6px 0px 0;
    left: 0;
    bottom: 0;
  }

}

.react-tabs__tab--disabled {
  color: GrayText;
  cursor: default;
}

.react-tabs__tab-panel {
  display: none;
}

.react-tabs__tab-panel--selected {
  display: block;
}



.invitation-bg-darken {
  position: fixed;
  width: 100vw;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  // background-color: rgba(255,255,255,0.52);
  background-color: #ffffff5c;
  backdrop-filter: blur(20px);
}

.invitation {
  width: calc(100vw + 70px);
  min-height: 100vh;
  // background: #f5f4f9;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  // align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  /* width: 100%; */
  /* height: 100%; */
  padding: 25px;
  margin: 0 0 0 -35px;
  color: #4a4a4a;
  transition: background 0.62s ease;

  .react-tabs {
    margin-bottom: 200px;

    .invitation-btn {
      margin: 20px 0px 40px 0px;

      // todo: check if needed
      background-color: rgb(0, 0, 0) !important;
      color: rgb(255, 255, 255) !important;
    }
  }

  .logo-hero {
    position: absolute;
    top: 50px;
    left: 0;
    right: 0;
    margin: auto;
  }

  .invitation-module {
    margin: auto;
    width: 550px;
    max-width: 100%;
    // overflow: hidden;
    border-radius: 15px;
    // box-shadow: 0 10px 20px -10px rgba(0, 0, 0, 0.4);
    // box-shadow: 0 10px 42px 1px rgba(0, 0, 0, 0.4);
    box-shadow: 0 21px 42px 0 rgba(48, 41, 88, 0.1);
    overflow: hidden;
    background: white;
    transition: all 0.52s ease;
    margin-top: 25%;
    opacity: 0;
    z-index: 2;

    .invitation-inner {
      opacity: 0;
      transition: all 0.52s ease;
    }
    .invitation-inner.active {
      opacity: 1;
    }

    .game-header {
      // box-shadow: 0 10px 20px -10px rgba(0, 0, 0, 0.4);
      margin: 0;
      border-radius: 15px 15px 0 0;
      transition: all 0.52s ease;
      opacity: 0;
      transform: translateY(-35px);

      .game-header-banner {
        // height: 100%;
        height: 240px;
        margin: -15px -15px 0 -15px;
        // margin: -25px -25px 0 -25px;
      }
    }

    .invitation-brand-img {
      position: absolute;
      top: -80px;
      left: 0;
      right: 0;
      margin: 0 auto;
      // width: 125px;
      // height: 125px;
      width: 150px;
      height: 150px;
      border-radius: 50%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      z-index: 3;
      box-shadow: 0 10px 20px -10px rgba(0, 0, 0, 0.4);
      background-color: white;
      // border: 6px solid white;
      border: 8px solid #fff;
      transition: all 0.52s ease;
      opacity: 0;
      transform: translateY(35px);
    }

    .invitation-meta {
      position: relative;
      padding: 25px;
      // height: 500px;
      text-align: center;
      font-family: Barlow;

      p {
        font-size: 16px;
        word-break: break-word;
      }

      .invitation-meta-inner {
        padding-top: 30px;
        transition: all 0.52s ease;
        opacity: 0;
        transform: translateY(35px);
      }

      .invitation-overview {
        transition: all 0.52s ease;
        // opacity: 0;
        // transform: translateX(250px);
      }

      .invitation-btn-container {
        transition: all 0.52s ease;
      }

      // .invitation-btn-container-1 {
      //   transition: all 0.52s ease;
      //   opacity: 0;
      //   transform: translateX(175px);
      // }

      .invitation-socials {
        transition: all 0.52s ease;
        opacity: 0;
        transform: translateX(175px);
        // transition: all 0.32s ease;
        // opacity: 0;
        // transform: translateX(300px);
      }

      hr {
        opacity: .1;
        margin: 35px auto;
      }

      h3 {
        font-weight: 900;
        font-size: 28px;
      }

      h4 {
        font-weight: 700;
        font-size: 24px;
      }

      .brand-tagline {
        margin: 0;
      }

      .invitation-user {

        img {
          border-radius: 50%;
          width: 85px;
          box-shadow: 0 10px 20px -10px rgba(0, 0, 0, 0.4);
          // border: 4px solid rgba(0,0,0,0);
        }

        h5 {
          font-size: 22px;
          font-weight: 700;
        }
      }

      .invitation-rewards-container {
        transition: all 0.52s ease;
        opacity: 0;
        transform: translateX(175px);
        transition-delay: 0.1s;
      }

      ul.invitation-rewards {
        text-align: left;
        padding-left: 100px;
        list-style: none;
        margin-bottom: 0;

        li {

          .invitation-reward {
            // font-size: 24px;
          }
        }
      }

      .invitation-btn-container {
        // margin: 50px auto 0;

        p {
          margin-bottom: 35px;
        }
      }

      .invitation-btn {
        position: relative;
        font-family: Barlow;
        font-weight: 700;
        color: #fff;
        background-color: #4084bd;
        padding: 10px 64px;
        font-size: 18px;
        border: none;
        border-radius: 50px;
        left: 0;
        right: 0;
        bottom: 0;
        // margin: 50px auto 0;
        box-shadow: 0 10px 20px -10px rgba(0, 0, 0, 0.4);

        // new styles below from zeplin
        // padding: 16px 126px;
        // border-radius: 8px;
        // box-shadow: 0 10px 20px 0 rgba(48, 41, 88, 0.12);
        // background-image: linear-gradient(to right, #c407f5, #ff5c82 75%, #ff9d35);
      }

      .login-separator {
        font-size: 16px;
      }

      .dark-input-large {
        font-size: 18px;
      }

      .invitation-email-signup {
        cursor: pointer;
      }
    }
  }


  .invitation-bg-slide {
    position: absolute;
    bottom: 0;
    right: 0;
    // width: 100vw;
    // height: 50vh;
    width: 0;
    height: 0;
    border-top: 100vh solid transparent;
    border-left: 100vh solid transparent;
    border-right: 100vh solid rgba(0,0,0,0);
  }
  .invitation-bg-wave {
    position: absolute;
    bottom: -18%;
    left: -12.5%;
    right: 0;
    width: 125%;
    height: 92vh;
    transform: rotate(-10deg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    // background-image: url('images/login-wave.svg');
    // height: 0;
    // border-top: 100vh solid transparent;
    // border-left: 100vh solid transparent;
    // border-right: 100vh solid rgba(0,0,0,0);
  }
}

//.invitation {
//  background-position: center !important;
//}

.signup-password {
  opacity: 0;
  visibility: hidden;
  height: 0px;
  overflow: hidden;
  transform: translateX(175px);
  transition: all 0.52s ease;
  transition-delay: 0.1s;
}

.signup-password.loaded {
  visibility: visible;
  height: initial;
  overflow: initial;
  transform: translateX(0) !important;
  opacity: 1 !important;
}

.invitation.loaded {
  .invitation-module {
    margin-top: auto;
    opacity: 1;
  }
  .invitation-brand-img {
    transform: translateY(0);
    opacity: 1;
  }
  .game-header {
    transform: translateY(0);
    opacity: 1;
  }
}
// .invitation.loaded.loading-stage-0 {
//   .game-header {
//     transform: translateY(0);
//     opacity: 1;
//   }
// }

.rates {
  .platform-rows {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
  }

  .price {
    font-weight: bold;
  }

  .platform-row {
    display: block;
    padding: 10px;

    .platform-icon {
      max-width: 20px;
      max-height: 20px;
      margin: 1px 15px 3px 1px;
    }
  }


}

.invitation.loaded2 {
  .invitation-meta-inner {
    transform: translateX(0) !important;
    opacity: 1 !important;
  }
  // .invitation-brand-img {
  //   transform: translateY(0);
  //   opacity: 1;
  // }
}

.invitation.loaded2.loading-stage-1 {
  .invitation-overview {
    transform: translateX(-350px) !important;
    opacity: 0 !important;
  }
  .invitation-rewards-container {
    transform: translateX(0) !important;
    opacity: 1 !important;
  }
  // .invitation-brand-img {
  //   transform: translateY(0);
  //   opacity: 1;
  // }
}

.invitation.loaded2.loading-stage-2 {
  .invitation-rewards-container, .invitation-btn-container-0 {
    transform: translateX(-350px) !important;
    opacity: 0 !important;
  }
  .invitation-socials {
    transform: translateX(0) !important;
    opacity: 1 !important;
  }
  // .invitation-brand-img {
  //   transform: translateY(0);
  //   opacity: 1;
  // }
}

.styled-text {

  h3 {
    font-size: 64px;
  }
}

.signup-bg-wave {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100vw;
}

// .loading-stage-0 {
//   background: #F26363;
// }
// .loading-stage-1 {
//   background: #a250ea;
// }
// .loading-stage-2 {
//   background: #2d7fea;
// }
// .loading-stage-3 {
//   background: #dc8544;
// }
// .loading-stage-4 {
//   background: #dc4465;
// }

.invitation.white-text {
  h1, h2, h3, h4, h5, h6, p, span {
    color: white !important;
  }
  hr {
    border-color: #eeeeee;
  }
}

.invitation.black-text {
  h1, h2, h3, h4, h5, h6, p, span {
    color: black !important;
  }
  hr {
    border-color: #111111;
  }
}

.invitation,
.invitation.black-text {
  .invitation-module .invitation-meta .email-error {
    width: 400px;
    margin: 10px auto auto;

    p {
      text-align: center;
      font-size: 16px;
      color: $error !important;
    }
  }
}

.bounty-influencer-name {
  margin: 20px 0;

  .and {
    font-weight: 500 !important;
    margin-top: 10px;
  }

  h3, h4 {
    margin: 0;
  }
}

.collapsable-options {
  margin: 30px 0;

  .invitation-btn {
    margin-top: 30px;
  }

  .submission-posts .submission-post {
    width: 100%;
  }

  p.sub-text {
    text-align: left;
  }

  form {
    h5, h4 {
      text-align: left;
    }
  }
}

.reward-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  > :first-child {
    margin-right: 10px;
  }

  p {
    margin: 0;
  }
}

.green {
  background: #71b54d;
}

.collapsable-options {
  > :not(:first-child):not(:last-child) {
    border-radius: 0;
  }

  > :not(:first-child) {
    border-top: 0;
  }

  > :first-child {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  > :last-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

button:disabled {
  opacity: 0.7;
}

.collapsable {
  margin-bottom: 0;
  padding: 0;
  overflow: hidden;

  &.collapsed:hover .header {
    transform: scale(1.01);
    transition: transform 0.2s ease-in-out, 0.2s ease-in-out;
  }
  &.collapsed:hover .header,
  &:not(.collapsed) .header {
    background-color: #eeeeee44;
  }
  &:not(.collapsed) {
    .header {
      border-bottom: 1px solid;
      border-color: inherit;
    }
  }

  .collapsable-content {
    padding: 40px 20px;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 25px 20px;

    &:hover {
      cursor: pointer;
    }

    .header-column {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .title-with-icon {
      display: flex;
      align-items: center;

      img {
        height: 20px;
        width: 20px;
        margin-right: 15px;
      }

      h5 {
        font-size: 22px;
        font-weight: 700;
      }
    }

    h4, h5 {
      margin: 0;
      padding: 0;
    }

    span {
      color: #808081 !important;
      text-align: left;
    }

    button.toggle-arrow {
      background-color: transparent;
      border: none;
      outline: none;
    }
  }

  p {
    text-align: left;
    font-weight: 500;
    font-family: 'Barlow';
  }

  .check {
    border-radius: 50%;
    padding: 5px;
    display: flex;

    img {
      height: 10px;
      width: 10px;
    }
  }

  .select-gift-option {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 15px;
    margin: 10px 0;
    border-radius: 15px;

    &:hover, &:hover .check {
      cursor: pointer;
      transition: background-color 0.1s ease-in-out;
    }

    &:not(:hover):not(.selected), &:not(:hover):not(.selected) .check {
      background-color: transparent !important;
    }

    .select-gift-img {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: 100%;
      min-height: 90px;
    }

    .gift-img-container {
      width: 30%;
      margin-left: 20px;
      border-radius: 15px;
      overflow: hidden;
    }

    .gift-description {
      flex: 1;
      margin-left: 20px;

      .sub-text {
        color: initial;
      }
    }

    .select-gift-indicator {
      width: 12px;
      height: 12px;
      border: 1px solid #cecece;
      border-radius: 50%;
      margin-right: 10px;
    }

    .two-line-ellipsis {
      max-height: unset;
    }
  }
}

@media (max-width: 500px) {
  .invitation {
    .invitation-module {
      .invitation-meta {
        p {
          font-size: 14px;
          margin-bottom: 6px;
        }

        .email-error {
          width: 100% !important;
        }

        ul.invitation-rewards {
          padding-left: 100px;

          li {
            font-size: 16px;
          }
        }

        .dark-input {
          font-size: 16px;
        }

        .login-separator {
          font-size: 14px;
        }

        .invitation-btn {
          padding: 8px 54px;
          font-size: 16px;
          //margin: auto;
        }
      }
    }
  }
}

@media (max-width: 500px) and (min-height: 725px){
  .invitation {
    .invitation-module {
      .game-header {
        height: 135px;
      }

      .invitation-brand-img {
        width: 95px;
        height: 95px;
        top: -50px;
        border-width: 5px;
      }

      .invitation-meta {
        hr {
          margin: 22px auto;
        }
        // height: ~'calc(100vh - 205px)';

        .invitation-user {
          img {
            width: 75px;
          }

          h5 {
            font-size: 16px;
          }
        }

        .invitation-meta-inner {
          // height: ~'calc(100vh - 320px)';
          padding-top: 10px;

          h3 {
            margin: 0 0 10px 0;
            font-size: 22px;
          }

          .invitation-overview {

            h4 {
              font-size: 20px;
            }

            ul.invitation-rewards {

              li {
                font-size: 20px;
              }
            }
          }
        }

        .invitation-btn-container {
          position: relative;
          // position: fixed;
          left: 0;
          right: 0;
          bottom: 0;

          p {
            margin-bottom: 25px;
          }
        }

        .integration-btn {

          .integration-btn-text-container {
            padding: 24px 24px 24px 42px;
          }
        }
      }
    }
  }
}

@media (max-width: 500px) and (max-height: 724px){
  .invitation {
    .invitation-module {
      .game-header {
        height: 115px;
      }

      .invitation-brand-img {
        width: 85px;
        height: 85px;
        top: -30px;
        border-width: 5px;
      }

      .invitation-meta {
        hr {
          margin: 22px auto;
        }
        // height: ~'calc(100vh - 205px)';

        .invitation-user {
          img {
            width: 65px;
          }

          h5 {
            font-size: 16px;
          }
        }

        .invitation-meta-inner {
          .top-section {
            margin-bottom: 20px;
          }

          // height: ~'calc(100vh - 320px)';
          padding-top: 28px;

          h3 {
            margin: 0 0 10px 0;
            font-size: 22px;
          }

          .invitation-overview {

            h4 {
              font-size: 18px;
            }

            ul.invitation-rewards {

              li {
                font-size: 18px;
              }
            }
          }

          .invitation-socials {
            p {
              font-size: 18px;
            }
          }
        }

        .invitation-btn-container {
          position: relative;
          // position: fixed;
          left: 0;
          right: 0;
          bottom: 0;

          p {
            margin-bottom: 25px;
          }
        }

        .integration-btn {

          .integration-btn-text-container {
            padding: 16px 24px 16px 42px;
          }
        }
      }
    }
  }
}

@media (max-width: 375px) {
  .invitation .invitation-module .invitation-meta ul.invitation-rewards {
    padding-left: 32px;
  }
}

// .invitation {
//   .loading-stage-1 {
//     .invitation-overview {
//       opacity: 1;
//       transform: translateX(0);
//     }
//   }
//
//   .loading-stage-2 {
//     .invitation-socials {
//       opacity: 1;
//       transform: translateX(0);
//     }
//   }
// }
