@import "vars.scss";

.background-gradient {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  /*background-image: linear-gradient(223deg, #dd313c, #ef7570);*/
  /*background-image: linear-gradient(119deg, #dd313c, #b06ab3);*/
  /* background: #133C55; */
  /*background: #285d8f;*/
  /*background: #323a5d;*/
  // background: #219ae4;
  // background: #fff;
  // background: #202225 !important;
  // color: white !important;
  z-index: 0;
}
.navbar {
  position: fixed;
  top: 0;
  z-index: 2;
  min-height: 60px;
  margin-bottom: 0;
  width: 100%;
  background-color: transparent;
  background-image: none;
  border: none;
}
.navbar .container {
  width: 100%;
  padding: 0 90px;
}
.nav-organization, .nav-ad-account {
  color: white;
  text-shadow: 1px 1px rgba(0,0,0,.1);
}
.nav-organization {
  font-size: 16px;
}
.nav-display-name {
  color: white;
  font-style: italic;
  text-shadow: 1px 1px rgba(0,0,0,.1);
}
.side-navigation-content {
  z-index: 0;
  position: fixed;
  padding-top: 85px;
  margin-top: 0;
  height: calc(100% + 5px);
  overflow-y: auto;
}
.nav {
  /*position: fixed;*/
  width: 200px;
}
.nav-sidenav {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;

  p {
    font-weight: 500;
    line-height: 21px;
    text-transform: initial;
  }

  li {

    a {
      color: white;
      font-family: Barlow;
      font-weight: 800;

      div {
        display: inline-block;
        float: left;

        p {
          // position: absolute;
          margin: 0;
          padding-top: 4px;
          font-size: 18px;
          text-shadow: none;
        }
      }
    }
  }

  img {
    margin-right: 24px;
    width: 22px;
    margin-top: -3px;
  }

  .nav-items-group {
    display: block;
    position: relative;
    margin-bottom: 0;
  }
}
.nav-sidenav li {
  padding: 15px 0 15px 21px;
  height: 60px;
}
.side-navigation-content .nav>li>a:focus, .nav>li>a:hover {
  background: none !important;
}
.nav-sidenav li a {
  padding: 0;
  opacity: .8;
  transition: all 0.15s ease;
  // color: white;
  color: black;
  font-size: 16px;
  text-shadow: 1px 1px rgba(0,0,0,.1);
}
.nav-sidenav li:hover a, .nav-sidenav li.active {
  opacity: 1;
}
.nav-sidenav li:hover, .nav-sidenav li.active {
  opacity: 1;
  background: white;
}
/*.nav-sidenav li.active {
  border-left: 3px solid #fff;
}*/
.nav-sidenav li.active a {
  /*margin-left: -3px;*/
  opacity: 1;
}
.page-with-side-navigation {
  // position: fixed;
  position: relative;
  padding-left: 0;
  height: 100%;
  overflow-y: scroll;
  width: 100%;
  z-index: 1;
  transition: all .15s ease;
  transition-timing-function: ease;
  overflow-y: auto;
  // left: 75px;
  // width: ~'calc(100vw - 75px)';
  left: 200px;
  width: calc(100vw - 200px);
}
// .page-with-side-navigation.hover {
//   // left: 200px;
//   transform: translateX(125px);
// }
.page-with-side-navigation article {
  position: relative;
  /*background: #fff;*/
  // background: #FAFAFA;
  // background: @main-bg !important;
  // color: white !important;
  border: 1px solid rgba(0,0,0,.05);
  box-shadow: 0 0 40px 0 #133C55, 0 -4px 8px 0 rgba(0,0,0,.05), 0 -1px 10px 0 rgba(0,0,0,.35);
  /*box-shadow: 0 0 40px 0 #323a5d, 0 -4px 8px 0 rgba(0,0,0,.05), 0 -1px 10px 0 rgba(0,0,0,.35);*/
  /*box-shadow: 0 0 40px 0 #f16969, 0 -4px 8px 0 rgba(0,0,0,.05), 0 -1px 10px 0 rgba(0,0,0,.35);*/
  border-radius: 0;
  // border-radius: 35px 0 0 0;
  /* border-radius: 10px 10px 0 0; */
  // min-width: 1130px;
  width: calc(100%);
  min-height: 100vh;
  /* max-height: calc(100vh - 60px);
  top: 60px; */
  // padding-top: 24px;
  // padding-bottom: 150px;
  overflow: auto;
}
.page-with-side-navigation article .main-container {
  /*margin-right: auto;
  margin-left: auto;*/
  margin-left: 0;
  padding-left: 25px;
  padding-right: 25px;
  min-height: 100vh;
  /*overflow: hidd;*/
}
.breadcrumbs h3 {
  color: white;
  text-shadow: 1px 1px rgba(0,0,0,.1);
}
.breadcrumbs h3 span {
  margin: 0 8px;
  font-size: 22px;
  color: white !important;
}
.white-logo {
  position: fixed;
  height: 36px;
  top: 25px;
  left: 22px;
}
.current-user-info {
  display: inline-block;
  margin-left: 10px;
  margin-top: 10px;
}
.account-details {
  position: relative;
  width: initial;
}
.account-details li {
  display: inline-block;
}
.li-account {
  float: right !important;
  margin-left: 15px;
}
.li-account .dropdown-toggle {
  padding-top: 13px;
}

.menu {
  position: fixed;
  width: 230px;
  height: 100vh;
  min-height: 695px;
  background: white;
  top: 0;
  left: 0;
  border-right: 1px solid rgba(105, 129, 152, 0.15);
  z-index: 999999;
  transition: all 0.5s ease;
}
.header-bar, .header-filler {
  display: none;
}
.prof-pic {
  position: relative;
  overflow: hidden;
  display: inline-block;
  float: left;
  width: 37px;
  border-radius: 50%;
  margin: 0 auto 15px auto;
}
.prof-pic img {
  display: none;
  width: 100%;
}
.account-balance {
  width: 100%;
  font-size: 22px;
	font-weight: 100;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: 1.6px;
	color: white;
  text-align: center;
  margin: 15px 0 0 0;
  text-shadow: 1px 1px rgba(0,0,0,.1);
}
.total-money-earned {
  font-size: 13px;
	font-weight: 400;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: 0.9px;
	color: #698198;
  text-align: center;
  margin: 25px 0 5px 0;
}
.withdraw-funds {
  font-size: 13px;
	font-weight: 100;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: 0.9px;
	text-align: center;
	color: white;
  margin: 0;
  cursor: pointer;
  text-shadow: 1px 1px rgba(0,0,0,.1);
}
.divider {
  width: 100%;
  height: 1px;
	border-top: solid 1px #e1e6ed;
  margin: 0;
}
.menu-options {
  margin: 0;
  padding-left: 0;
  font-size: 14px;
	font-weight: 300;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: 1.4px;
  color: #607d8b;
  text-decoration: none;
  list-style: none;
}
.menu-options li, .menu-options li a {
  margin: 0;
  height: 40px;
  line-height: 40px;
  padding: 0;
  cursor: pointer;
  color: #2d2d2d !important;
  font-weight: 400;
}
.menu-options li {
  position: relative;
  padding-left: 20px;
  transition: all 0.25s ease;
}
#nav-menu-options li .select-pillar {
  position: absolute;
  height: calc(100% + 14px);
  // margin-top: -7px;
  // width: 3px;
  // background: #fff;
  width: 6px;
  // padding: 10px 0;
  // top: 0;
  padding: 7px 0px;
  // top: 4px;
  top: 0;
  left: -1px;
  transition: all 0.5s ease;
  // border-radius: 0 4px 4px 0;
  border-radius: 0;
  z-index: 10;

  div.select-pillar-pill {
    position: relative;
    // background: #ff8e31;
    background-color: #3abdfe;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#61d8ff), to(#36aafe));
    background-image: linear-gradient(to bottom right, #61d8ff, #36aafe);
    width: 100%;
    // height: 70%;
    height: 100%;
    // border-radius: 0 4px 4px 0;
    border-radius: 0 6px 6px 0;
    // top: 6px;
    top: 0;
  }

  span {
    height: 100%;
    width: 100%;
    background: #c25be4;
  }
}
#nav-menu-options {
  li.nav-menu-balance {
    font-family: Barlow;
    background: none;

    p {
      margin: 0;
    }

    h3 {
      margin: 0;
      font-weight: 700;
      font-size: 32px;
    }
  }
  li.nav-menu-balance:hover {
    background: none;
  }
  li.nav-menu-earnings {
    height: 70px;
  }
  li.nav-menu-credits {
    height: 100px;
  }

  .nav-menu-cashout {
    background: black;
  }
}
#nav-menu-options li .select-pillar.dashboard { transform: translateY(-7px); }
// #nav-menu-options li .select-pillar.links { transform: translateY(calc(100%));}
// #nav-menu-options li .select-pillar.walmart { transform: translateY(calc(200%));}
// #nav-menu-options li .select-pillar.analytics { transform: translateY(calc(100%));}
#nav-menu-options li .select-pillar.bounties { transform: translateY(calc(100% - 21px)); }
#nav-menu-options li .select-pillar.search { transform: translateY(calc(200% - 35px)); }
#nav-menu-options li .select-pillar.outreach { transform: translateY(calc(300% - 49px)); }
#nav-menu-options li .select-pillar.invoices { transform: translateY(calc(400% - 63px)); }
#nav-menu-options li .select-pillar.roster { transform: translateY(calc(500% - 77px)); }
#nav-menu-options li .select-pillar.refer { transform: translateY(calc(600% - 91px)); }
// #nav-menu-options li .select-pillar.messages { transform: translateY(calc(300% - (35px + (14*2)))); }
// #nav-menu-options li .select-pillar.links { transform: translateY(calc(400% - (35px + (14*3)))); }
#nav-menu-options li .select-pillar.account { display: none; }
// #nav-menu-options li .select-pillar.dashboard { transform: translateY(0) }
// #nav-menu-options li .select-pillar.merchants { transform: translateY(calc(100%)) }
// #nav-menu-options li .select-pillar.analytics { transform: translateY(calc(200%)) }
// #nav-menu-options li .select-pillar.links { transform: translateY(calc(300%)) }
// #nav-menu-options li .select-pillar.messages { transform: translateY(calc(400%)) }
// #nav-menu-options li .select-pillar.tools { transform: translateY(calc(500%)) }
.nav-stacked>li+li {
  margin-top: 0;
}
.nav>li>a:focus, .nav>li>a:hover {
  text-decoration: none;
  background-color: unset;
}
.menu-options li:hover a, .menu-options li.active a {
  /*background: black;*/
  /*color: #2d2d2d !important;*/
  font-weight: 600;
  /*text-decoration: underline;*/
}
/*.menu-options li:hover {
  padding-left: 44px;
}*/
.menu-options li:hover .select-pillar, .menu-options li.active .select-pillar {
  left: 0px;
}
.menu-options li img {
  margin: -3px 10px 0 0;
  width: 17px;
  transition: all 0.1s ease;
  /*fill: #607d8b !important;*/
}
/*.menu-options li:hover img, .menu-options li.active img {
  width: 18px;
}*/
.menu-options li.selected a, .menu-options li.selected img {
  color: #222 !important;
  /*fill: #698198 !important;*/
}
.menu-options li span {
  font-size: 7px;
}
.footer-container {
  position: absolute;
  width: 100%;
  bottom: 15px;
  margin: 0 -15px;
}
.footer-link {
  display: block;
  font-size: 15px;
	font-weight: 100;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: 1.1px;
	color: #03a9f4;
  padding: 20px !important;
  text-align: center;
}
.footer-copyright {
  font-size: 13px;
	font-weight: 100;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: 0.9px;
	color: #8ba0b4;
  text-align: center;
}
.username {
  text-align: center;
  font-size: 20px;
	font-weight: 400;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: 0.9px;
	color: #fff;
}
.logo-container {
  position: relative;
  display: block;
  margin: 10px auto 5px auto;
  overflow: auto;
  width: 135px;
}
.logo {
  height: 30%;
  margin-top: -5px;
  margin-right: 5px;
}
.logo-text {
  font-size: 22px;
  line-height: 64px;
  color: #333333;
}
.logo-signup {
  display: inline-block;
  float: left;
  padding: 6px;
  margin: 9px auto 0 auto;
  width: 38px;
  height: 34px;
}
.weave-signup {
  display: inline-block;
  float: left;
  font-weight: 100;
  font-size: 36px;
  line-height: normal;
  letter-spacing: normal;
  color: #484848;
  text-align: center;
}
.g-signin2 {
  display: none;
}
.mobile-hamburger {
  display: none;
  position: fixed;
  top: 10px;
  left: 10px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid blue;
  background: yellow;
  z-index: 99999999999;
}
ul.dropdown-menu {
  min-width: 250px;
  padding: 0;
  border-radius: 15px !important;
  overflow: hidden;
}
ul.dropdown-menu li {
  display: block;
  color: #333;
  text-align: center;
  padding: 10px 0;
  letter-spacing: 0.4px;
  cursor: pointer;
}
.profile-tab-container {
  position: absolute;
  bottom: 25px;
  left: 22px;
  // text-shadow: 1px 1px rgba(0, 0, 0, 0.1);

  .profile-tab {

    a {
      padding: 0;
      transition: all 0.15s ease;
      font-size: 18px;
      font-family: Barlow;

      img {
        margin-left: -8px;
        width: 47px;
        margin-right: 24px;
        margin-top: -3px;
      }
    }
  }
}

.white-logo-container {
  position: fixed;
  top: 44px;
  left: 25px;
  transition: all 0.15s ease;

  .white-logo {
    position: relative;
    display: block;
    width: 24px;
    margin: auto;
    top: 0;
    left: 0;
  }
  span {
    position: relative;
    display: block;
    color: #292929;
    padding-top: 5px;
  }
}
.white-logo-container.hover {
  transform: translateX(52px);
}

.toggle-light-dark-container {
  position: relative;
  top: -25px;
  left: 2px;
}

.toggle-light-dark {
  position: relative;
  width: 32px;
  height: 14px;

  .toggle-light-dark-outer {
    background: #455057;
    width: 100%;
    height: 100%;
    border-radius: 25px;
    transition: all 0.32s ease;
    cursor: pointer;

    .toggle-light-dark-inner {

      img {
        position: absolute;
        top: -3px;
        width: 20px;
        transition: all 0.32s ease;
      }
    }
  }
}

.toggle-light-dark.theme-light {
  .toggle-light-dark-outer {
    background: #bdc2c4;
  }

  img {
    transform: translateX(15px);
  }
}

.toggle-light-dark.theme-dark {
  .toggle-light-dark-outer {
    background: #455057;
  }

  img {
    transform: translateX(-3px);
  }
}

.mobile-menu {
  display: none;
  position: fixed;
  width: 100vw;
  height: 55px;
  z-index: 11;
  top: 0;
  left: 0;
  box-shadow: rgba(0, 0, 0, 0.22) 0px 2px 5px -2px;

  .mobile-menu-inner {
    position: relative;
    padding: 17px 15px;
    text-align: center;

    .mobile-menu-logo {
      display: inline-block;

      img {
        width: 35px;
        margin-top: -7px;
      }
    }

    .mobile-menu-hamburger {
      position: relative;
      display: inline-block;
      float: left;

      img {
        width: 20px;
        cursor: pointer;
      }
    }
  }
}

.app-menu {
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 65px;
  z-index: 100;

  .app-menu-option {
    position: relative;
    display: inline-block;
    float: left;
    width: 25%;
    height: 100%;
    text-align: center;
    border-top: 1px solid rgba(0,0,0,0);
    border-right: 1px solid rgba(0,0,0,0);
    padding: 22px;
    font-size: 24px;
    font-family: Barlow;
    font-weight: 500;

    img {
      position: absolute;
      height: 18px;
      top: -19px;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
    }

    p {
      margin: 15px 0px 0px;
      font-size: 15px;
    }
  }
}

@media (min-width: 979px) {
  ul.nav li.dropdown:hover > ul.dropdown-menu {
    display: block;
  }
}
@media screen and (max-width: 750px){
  .desktop-menu {
    display: none;
  }
  .app-menu {
    display: block;
  }
  .intercom-lightweight-app {
    display: none !important;
    visibility: hidden !important;
    opacity: 0 !important;
    right: -10000px !important;
    margin-right: -10000px !important;
    width: 1px !important;
    height: 1px !important;
  }
  .point-event-no-menu {
    pointer-events: none !important;
    overflow: hidden;
    position: fixed;
    height: 100%;
    margin: 0;
    padding: 0;
    border: none;
  }
  .mobile-menu {
    display: block;
  }
  .page-with-side-navigation {
    left: 0;
    width: 100vw;
    z-index: 3;

    article {
      // padding-top: 55px;
      padding-top: 0;

      .main-container {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  .page-with-side-navigation.menu-push-right {
    transform: translate3d(55%, 0, 0);
  }
  .mobile-hamburger {
    display: block;
    cursor: pointer;
  }
  .main-container {
    padding: initial;
  }
  .menu {
    -webkit-transform: translateX(-230px);
    -moz-transform: translateX(-230px);
    -ms-transform: translateX(-230px);
    -o-transform: translateX(-230px);
    transform: translateX(-230px);
  }
  .point-event-no, .point-event-no-menu {
  	pointer-events: none !important;
    overflow:hidden;
    position:fixed;
    height: 100%;
    margin: 0;
    padding: 0;
    border: none;
  }
  .menu-push-right {
    overflow: hidden;
    -webkit-transform: translate3d(230px, 0, 0);
    -moz-transform: translate3d(230px, 0, 0);
    -ms-transform: translate3d(230px, 0, 0);
    -o-transform: translate3d(230px, 0, 0);
   	transform: translate3d(230px, 0, 0);
  }
  .menu-slide {
    display: block;
    -webkit-transform: translateX(0px);
    -moz-transform: translateX(0px);
    -ms-transform: translateX(0px);
    -o-transform: translateX(0px);
    transform: translateX(0px);
    pointer-events: auto !important;
  }
}
@media screen and (max-height: 800px){
  .menu-options li {
    height: 30px;
    line-height: 30px;
  }
}

@media screen and (max-width: 767px){
  .pointer-event-menu-open {
    // pointer-events: none !important;
    overflow: hidden;
    position: fixed;
    height: 100%;
    margin: 0;
    padding: 0;
    border: none;
  }
}
