.gmail-signin-container {

  .gmail-signin {
    font-family: Roboto;
    position: relative;
    padding: 8px 8px 8px 44px;
    background: #4285F4;
    border: none;
    color: white;
    border-radius: 2px;
    font-weight: bold;

    img {
      position: absolute;
      top: -2px;
      left: -2px;
      width: 40px;
    }
  }
}

.row.crm-row:hover, .row.crm-row.selected {
  .message-tab-avatar-container {
    display: none;
  }
}

.message-container {
  margin-top: -24px;

  .message-column {
    position: relative;
    display: inline-block;
    width: 30%;
    height: 100vh;
    float: left;
    border-right: 1px solid rgba(0,0,0,.2);
    margin-left: -30px;

    ul {
      padding: 0;
      list-style: none;

      li {
        border-bottom: 1px solid rgba(0,0,0,.1);

        .message-tab {
          // height: 100px;
          cursor: pointer;


          .message-tab-container {

            .message-tab-header {
              padding: 11px 20px 15px;
              border-left: 2px solid #fff;

              .message-tab-wrapper {
                display: flex;
                min-height: 40px;
                align-items: center;

                .message-tab-checkbox {
                  border-color: rgba(0,0,0,.1);
                  flex-direction: row;
                  align-items: center;
                  justify-content: center;
                  margin: 3px 14px 3px -1px;
                  box-sizing: border-box;
                  width: 34px;
                  height: 34px;
                  border-radius: 50%;
                  border: 1px solid transparent;
                  display: none !important;

                  input {
                    font-size: 100%;
                    margin: 0;
                    vertical-align: baseline;
                  }
                }

                .message-tab-layout-media-container {
                  flex: 1 1 auto;
                  min-width: 0;
                  cursor: pointer;

                  .message-tab-layout-media {
                    display: flex;
                    align-items: flex-start;

                    .message-tab-layout-media-ext {
                      flex: 1 1 auto;
                      min-width: 0;

                      h3.message-tab-layout-name {
                        color: #222;
                        font-size: 14px;
                        font-weight: 500;
                        display: block !important;
                        white-space: nowrap !important;
                        overflow: hidden !important;
                        text-overflow: ellipsis !important;
                        padding: 0 !important;
                        margin: 0 !important;
                        border: none !important;
                      }
                    }

                    .message-tab-layout-timestamp {

                      .message-tab-layout-timestamp-view {

                      }
                    }
                  }
                }
              }

              .message-tab-relative {
                position: relative !important;

                .message-tab-convo-summary {
                  word-wrap: break-word;
                  -ms-word-break: keep-all;
                  word-break: break-word;
                  -webkit-hyphens: auto;
                  -ms-hyphens: none;
                  hyphens: auto;
                  font-weight: 500;
                  line-height: 20px;
                  font-size: 13px;

                  .message-tab-convo {
                    flex: 1 1 auto;
                    min-width: 0;
                    margin-top: 13px;

                    .layout__media__ext {
                      flex: 1 1 auto;
                      min-width: 0;

                      .message-tab-convo-truncated-line {
                        display: block !important;
                        white-space: nowrap !important;
                        overflow: hidden !important;
                        text-overflow: ellipsis !important;
                        font-weight: 400;
                        height: 18px;

                        p {
                          overflow: hidden !important;
                          text-overflow: ellipsis !important;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      .message-tab:hover {
        background-color: #f3f7ff;

        .message-tab-container {

          .message-tab-header {
            // padding-left: 18px;
            border-left-color: #f3f7ff;

            // h3.message-tab-layout-name {
            //   color: #286efa !important;
            // }
          }

          .message-tab-relative {

            .message-tab-convo-summary {
              color: #222 !important;


            }
          }
        }
      }

      .message-tab.active {
        // border-left-color: #286efa;
        background-color: #e8f0ff;

        .message-tab-container {

          .message-tab-header {
            // padding-left: 18px;
            border-left-color: #286efa;

            h3.message-tab-layout-name {
              color: #286efa !important;
            }
          }

          .message-tab-relative {

            .message-tab-convo-summary {
              color: #222 !important;


            }
          }
        }
      }
    }
  }

  .message-view {
    position: relative;
    display: inline-block;
    width: 72%;
    height: 100vh;
    float: left;
  }

}

.message-tab-avatar-container {
  margin: 3px 14px 3px -1px;
  box-sizing: border-box;
  width: 35px;
  height: 35px;
  border-radius: 5px;
  border: 1px solid transparent;

  .message-tab-avatar {
    min-width: 35px;
    width: 35px;
    height: 35px;
    line-height: 35px;
    font-size: 13px;
    border-radius: 5px;
    display: inline-block;
    text-align: center;
    font-weight: 500;
    color: #fff;
    position: relative;
    box-sizing: border-box;
    background-clip: padding-box;
    vertical-align: bottom;
    box-shadow: 1px 1px 1px 0 #d3dae3;

    .message-tab-avatar-media {
      background-color: #fff;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      display: inline-block;
      border-radius: 5px;
      background-clip: padding-box;
      background-size: contain;
      vertical-align: bottom;
      min-width: 35px;
      width: 35px;
      height: 35px;
      line-height: 35px;
      font-size: 13px;
      border-radius: 5px;
      display: inline-block;
      text-align: center;
      font-weight: 500;
      color: #fff;
      position: relative;
      box-sizing: border-box;
    }
    .message-tab-avatar-badge {
      border-color: #e8f0ff;
      top: 22px;
      left: 22px;
      width: 12px;
      height: 12px;
      border-width: 2px;
      box-sizing: border-box;
      position: absolute;
      border-radius: 5px;
      transform: scale(.1);
      transition: all .1s cubic-bezier(.42,0,.58,1);
      opacity: 0;
      display: block;
      border: 2px solid #fff;
      min-width: 35px;
      width: 35px;
      height: 35px;
      line-height: 35px;
      font-size: 13px;
      display: inline-block;
      text-align: center;
      font-weight: 500;
      color: #fff;
      position: relative;
      box-sizing: border-box;
    }
  }
}
