

.section h4 {
  text-align: left;
  color: black;
  /*margin-bottom: 15px;
  font-size: 19px;
  font-weight: 100;*/
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.5px;
}

.main-container .section {
  /*display: none;*/
  position: relative;
  padding-bottom: 15px;
}
.main-container .section-1, .main-container .section-2 {
  display: block;
}
.main-container .search-container {
  position: relative;
  /*overflow: hidden;*/
  /*border-radius: 15px;
  box-shadow: 0 4px 7px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px #eaeaea;
  border-radius: 15px;*/
  min-height: 250px;
  border-radius: 8px;
	box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.02);
	// border: 1px solid rgba(0, 0, 0, 0.06);
	// background: white;
  padding: 0;
}
.main-container .search-box {
  position: relative;
  display: block;
  margin: 0 auto;
  box-sizing: border-box;
  width: 100%;
  min-height: 250px;
  color: black;
  outline: none;
  /*background-color: white;*/
  background-color: transparent;
  border: none;
  border-radius: 15px;
  /*box-shadow: 0 4px 7px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px #eaeaea;
  border-radius: 15px;*/
  padding: 15px;
  resize: none;
  overflow: auto;
  transition: all 0.25s ease;
  float: initial;
}
.main-container .search.searching {
  background: white;
  box-shadow: none;
  border: solid 1px #fff;
  opacity: 0;
}
.main-container .search-box-btn {
  position: absolute;
  right: 0;
  bottom: 0;
  display: block;
  margin: 0;
	border-radius: 8px 0 8px 0;
  border: none;
	/*background-image: linear-gradient(250deg, #e94d47, #ed716c);*/
  background: #dd313c;
	box-shadow: 0 2px 3px -1px rgba(100, 100, 100, 0.5);
	text-shadow: 1px 1px rgba(0,0,0,.1);
	padding: 15px 24px 14px;
	font-size: 13px;
	line-height: 1.42857;
	font-weight: 600;
	font-style: normal;
	font-stretch: normal;
	letter-spacing: 0.1px;
	text-align: center;
	outline: 0;
	transition: all 0.25s ease;
	user-select: none;
	vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
}
/*.main-container .search-box-btn.link-convert-btn:active {
  background-image: linear-gradient(250deg, #e94d47, #ed716c);
}*/
.main-container .search-box-btn.edit-links-btn {
  display: none;
  top: 0;
  bottom: initial;
  z-index: 999;
  border-radius: 0 0 0 15px;
  background: none;
  box-shadow: none;
  color: #e94d47 !important;
}
.main-container .live-links.active .search-box-btn.edit-links-btn {
  display: block;
}
.main-container .live-links {
  /*visibility: hidden;*/
}
.main-container .live-links.active {
  box-shadow: 0 4px 7px 0 rgba(0, 0, 0, 0.05);
}
.main-container .live-links .links-copy-btn {
  display: none;
  box-shadow: none;
}
.main-container .live-links.active .links-copy-btn {
  display: block;
  background: #dd313c;
}
.main-container .edit-links-container {
  display: none;
}
.main-container .live-links.edit-active .edit-links-container {
  display: block;
}
.main-container .live-links.active .live-links-text {
  display: block;
}
.main-container .edit-links-container ul {
  text-decoration: none;
  list-style: none;
  padding: 0;
  margin: 10px 0;
}
.main-container .edit-links-container ul li {
  text-decoration: none;
  list-style: none;
  padding: 0;
  margin: 3px;
}
.main-container .edit-links-container ul li input {
  padding: 7px;
  min-width: 225px;
  border-radius: 5px;
  border: 1px solid #e1e1e1;
}
.main-container .edit-links-container ul li span {
  font-size: 12px;
  margin: 0 5px;
}
.main-container .edit-links-container ul li img {
  width: 17px;
  vertical-align: middle;
  cursor: pointer;
  opacity: 0;
}
.main-container .edit-links-container ul li:hover img {
  opacity: 0.8;
}
.main-container .edit-links-container ul li img:hover {
  opacity: 1;
}
.main-container .video-display {
  position: relative;
  width: 100%;
  height: 100px;
  margin-top: 20px;
}
.main-container .video-display.video-selects {
  max-width: 700px;
}
.main-container .video-display.social-display {
  /*height: 50px;*/
}
.main-container .page-img {
  display: inline-block;
  width: 150px;
  float: left;
  border-radius: 8px;
}
.main-container .page-name {
  display: inline-block;
  color: black;
  float: left;
  margin-left: 20px;
}
.main-container .video-box {
  width: 135px;
  display: inline-block;
  margin: 0 15px;
}
.main-container .video-img {
  /*display: inline-block;*/
  /*width: 135px;*/
}
.main-container .login {
  margin-top: 150px;
}
.main-container .login input, .main-container .login textarea {
  display: block;
  margin: 5px auto;
  width: 100%;
  max-width: 400px;
  height: 32px;
  padding: 10px;
  border-radius: 4px;
	background-color: #ffffff;
	box-shadow: 0 2px 4px 0 rgba(100, 100, 100, 0.15);
	border: solid 1px #e4e4e4;
  outline: none;
}
.main-container .login textarea {
  height: 90px;
  resize: none;
}
.main-container .login input::-webkit-input-placeholder, .main-container .login textarea::-webkit-input-placeholder  {
  color: #9e9e9e;
}
.main-container .login input:-moz-placeholder, .main-container .login textarea:-moz-placeholder {
  color: #9e9e9e;
  opacity: 1;
}
.main-container .login input::-moz-placeholder, .main-container .login textarea::-moz-placeholder {
  color: #9e9e9e;
  opacity: 1;
}
.main-container .login input:-ms-input-placeholder, .main-container .login textarea:-ms-input-placeholder {
  color: #9e9e9e;
}
.main-container .login button {
  display: block;
  width: 235px;
	height: 60px;
  margin: 25px auto 46px auto;
  transition: all 0.5s ease;
}
.main-container .login button {
  margin: 25px auto 25px auto;
}
.main-container .vid-tile {
  position: relative;
  display: inline-block;
  float: left;
  width: 200px;
  height: 185px;
  top: 0;
  margin-right: 10px;
  border-radius: 15px;
  background: white;
  box-shadow: 0 4px 17px 0 rgba(0, 0, 0, 0.1);
  /*transition: all 0.3s ease;*/
  overflow: hidden;
  cursor: pointer;
}
.main-container .vid-img {
  position: relative;
	padding-bottom: 56.22%;
}
.main-container .vid-img img {
  position: absolute;
	max-width: 100%;
	height: 100%;
	margin: auto;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
}
/*.main-container .vid-tile:hover {
  top: -6px;
  box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.35);
}
.main-container .vid-tile.active {
  top: -6px;
  box-shadow: 0 4px 24px 0 rgba(50, 202, 121, 0.35);
  border-color: #5abd68;
}*/
.main-container .vid-tile p {
  position: relative;
  margin: auto;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  padding: 15px 15px 0 15px;
}

.main-container .social-channel {
  position: relative;
  display: inline-block;
  margin-right: 16px;
  /*width: 125px;*/
	height: 48px;
	/*border-radius: 8px 0 8px 0;*/
  border: 1px solid white;
  text-align: center;
  line-height: 48px;
  background: none;
  color: white;
  font-size: 16px;
  cursor: pointer;
  overflow: hidden;
}
.main-container .social-channel p {
  display: none;
  position: relative;
  z-index: 2;
}
.main-container .social-channel img {
  /*display: none;*/
  vertical-align: middle;
  margin: 0 auto;
  width: 40px;
  text-shadow: 1px 1px rgba(0,0,0,.1);
}
.main-container .social-channel.youtube {
  border-color: #ff0000;
  color: #ff0000;
}
.main-container .social-channel.youtube.active, .main-container .social-channel.youtube:hover {
  background-color: #ff0000;
  color: white;
}
.main-container .social-channel.instagram {
  border-color: #8a3ab9;
  color: #8a3ab9;
}
.main-container .social-channel.instagram.active, .main-container .social-channel.instagram:hover {
  background-color: #8a3ab9;
  color: white;
}
.main-container .social-channel.facebook {
  border-color: #3b5998;
  color: #3b5998;
}
.main-container .social-channel.facebook.active, .main-container .social-channel.facebook:hover {
  background-color: #3b5998;
  color: white;
}
.main-container .social-channel.snapchat {
  border-color: #e2e011;
  color: #e2e011;
}
.main-container .social-channel.snapchat.active, .main-container .social-channel.snapchat:hover {
  background-color: #e2e011;
  color: white;
}
.main-container .social-channel.twitter {
  border-color: #55acee;
  color: #55acee;
}
.main-container .social-channel.twitter.active, .main-container .social-channel.twitter:hover {
  background-color: #55acee;
  color: white;
}
.main-container .social-channel.pinterest {
  border-color: #cb2027;
  color: #cb2027;
}
.main-container .social-channel.pinterest.active, .main-container .social-channel.pinterest:hover {
  background-color: #cb2027;
  color: white;
}
.main-container .social-channel.blog {
  border-color: #505457;
  color: #505457;
}
.main-container .social-channel.blog.active, .main-container .social-channel.blog:hover {
  background-color: #505457;
  color: white;
}
.main-container .social-channel.other {
  border-color: #000000;
  color: #000000;
}
.main-container .social-channel.other.active, .main-container .social-channel.other:hover {
  background-color: #000000;
  color: white;
}
.main-container .social-channel:disabled {
  /*background: none !important;*/
  background: gray !important;
  border-color: gray !important;
  color: gray !important;
  cursor: not-allowed;
}
.youtube-description {
	margin-bottom: 30px;
  max-width: 645px;
}
.description-length {
	position: absolute;
  right: 16px;
  top: 0;
  font-size: 10px;
  color: #a3a3a3;
}
.description-length.too-long {
	color: rgba(255, 134, 134, 0.85);
}
.description {
	position: relative;
	/*background-color: #f6f9fc;*/
	/*border: solid 1px #ebf0f5;*/
	/*overflow: scroll;*/
	/*resize: none;*/
	/*border: 1px solid rgba(255, 134, 134, 0.23);*/
	/*box-shadow: 0 4px 7px 0 rgba(0, 0, 0, 0.05);*/
	/*border: solid 1px #cfd8dc;*/
  max-width: 615px;
  margin-top: 15px;
	border-radius: 25px;
	padding: 7px;
  box-shadow: 0 4px 7px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px #eaeaea;
  border-radius: 15px;
}
.description.active {
	border: 1px solid rgba(88, 255, 88, 0.23);
}
.description .zone {
	position: absolute;
	display: none;
	top: 0;
	background: rgba(255, 134, 134, 0.23);
	width: 100%;
	height: 63px;
	border-bottom: 2px dotted #fe7d7d;
}
.description.active .zone {
	background: rgba(88, 255, 88, 0.23);
	border-bottom: 2px dotted #26cb00;
}
.description hr {
	position: absolute;
	top: 24px;
	background: none;
	border-bottom: 1px dashed #9ea1a4;
}
.description .description-content {
	position: relative;
	width: 100%;
  max-width: 615px;
	min-height: 300px;
	height: 100%;
	outline: none;
	border: none;
	background: none;
	resize: none;
}
.section-5 {
  margin-top: 35px;
}
.section-5 .inputs-container {
  position: relative;
  overflow: auto;
  width: 450px;
  display: inline-block;
  margin-right: 15px;
}
.section-5 input {
  position: relative;
  display: block;
  outline: none;
  width: 100%;
  padding: 10px;
  box-shadow: 0 4px 7px 0 rgba(0, 0, 0, 0.05);
  background: white;
  border: solid 1px #cfd8dc;
  border-radius: 50px;
  float: left;
  transition: all 0.35s ease;
  resize: none;
  font-weight: 100;
}
.section-5 .next-btn.update {
  position: absolute;
  margin: 0;
  top: 1px;
  right: 0;
  border-radius: 0 50px 50px 0;
  padding: 13px 20px 12px 20px;
}
.section-5 .text-link {
  width: 275px;
}

.tutorial-active {
  background: white;
  z-index: 11;
}



.tutorial-overlay {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0,0,0,0.5);
  z-index: 10;
}
.tutorial-container {
  position: relative;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.tutorial-module {
  display: none;
  position: absolute;
  width: 400px;
  height: 200px;
  padding: 10px 24px;
  background: white;
  box-shadow: 0 4px 7px 0 rgba(0, 0, 0, 0.05);
  border-radius: 15px;
  z-index: 12;
}
.tutorial-module.t1 {
  top: 95px;
  left: 665px;
}
.tutorial-module.t2 {
  top: 95px;
  left: 250px;
}
.tutorial-module.t3 {
  top: 487px;
  left: 770px;
}
.tutorial-module.t4 {
  top: initial;
  bottom: 100px;
  left: 800px;
}
.tutorial-module.t5 {
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0
}
.tutorial-module.t5 .finish-tutorial {
  display: block;
  margin: 8px auto;
}
.next-btn.published {
	background: #19bd30;
	border-color: #19bd30;
	color: white;
	outline: 0;
}
