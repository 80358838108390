// html, body {
//   background: #2a2a2a !important;
// }

.muli {
  font-family: 'Muli', sans-serif;
}

.barlow {
  font-family: 'Barlow', sans-serif;
}

.heavitas {
  font-family: 'Heavitas', sans-serif;
}

.hero-container {
  margin-top: 150px;

  .hero-title {
    font-size: 36px;
    font-weight: 900;
    color: white !important;
    text-transform: uppercase;
    margin-bottom: 30px;
  }

  .hero-subtext {
    font-size: 18px;
    font-weight: 500;
    color: white;
  }

  .row-social-logins {
    padding-top: 80px;
    margin: 0 -40px;
  }
}

.new-landing {

  .section-hero {
    background: #141214;
    background: linear-gradient(180deg, rgba(20,18,20,1) 0%, rgba(42,42,42,1) 100%);
    height: 100vh;

    .container {
      top: 75px;
    }

    .hero-transition {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100vw;
      background-image: url('/images/homepage/hero-transition.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }
  }

  p {
    color: white;
  }

  h4 {
    font-family: Barlow;
    font-weight: 900;
    font-size: 32px;
  }

  .new-landing-logo {
    position: absolute;
    // top: -135px;
    top: 0;
    left: 15px;
    width: 168px;
  }

  .section {
    margin: 0;
    overflow: initial;
  }

  .section-1 {
    padding: 200px 0;
  }

  .section-2 {
    height: 700px;

    .container-2 {
      height: 700px;
    }
  }

  .section-slanted:before {
    content: "";
    position: absolute;
    width: 100vw;
    height: 100%;
    top: -50px;
    bottom: 0;
    right: 0;
    left: 0;
  }

  .section-2:before {
    // margin: 0 -100%;
    transform: skewY(-3deg);
    // background-color: #31c3a2;
    background-color: #462ecc;
    // background-color: #c25be4;
  }

  .section-3 {
    padding: 35px 0 275px;
  }

  .section-3:before {
    top: -110px;
    // margin: 0 -100%;
    transform: skewY(3deg);
    // background-color: #31c3a2;
    background-color: #182228;
  }

  h2 {
    margin-bottom: 24px;
    font-size: 36px;
    line-height: 1.1;
    font-weight: 900;
    color: white;
  }

  h4 {
    margin-bottom: 36px;
    font-size: 18px;
    line-height: 1.1;
    font-weight: 500;
    // color: white;
  }

  .social-login {
    display: inline-block;
    // float: left;
    overflow: hidden;
    height: 66px;
    border-radius: 8px;
    transition: all 0.26s ease;
    margin-right: 16px;

    .social-login-container {
      position: relative;
      cursor: pointer;
      width: 280px;

      .social-login-inner {
        position: relative;
        width: 100%;
        height: 60px;
        border-radius: 8px;
        z-index: 9;
        // padding: 15px;

        .social-login-img {
          position: relative;
          display: inline-block;
          float: left;
          width: 65px;
          height: 100%;


          img {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
          }
        }

        .social-login-txt {
          position: relative;
          display: inline-block;
          float: left;
          font-family: Barlow;
          font-weight: 800;
          font-size: 18px;
          width: calc(100% - 65px);
          height: 100%;
          color: #fff;

          p {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            text-align: center;
            height: 24px;
            color: #fff !important;
          }
        }
      }
      .social-login-shadow {
        position: absolute;
        width: 100%;
        height: 18px;
        left: 0;
        bottom: -6px;
        border-radius: 8px;
      }
    }
  }

  .social-login {
    width: 66px;
  }
  .social-login.active, .social-login:hover {
    width: 280px;
  }

  .social-login.social-instagram {
    .social-login-inner {
      background-image: linear-gradient(-135deg, #1400c8, #b900b4, #f50000);

      .social-login-txt {
        p {
          text-align: left;
          padding-left: 6px;
        }
      }

      .social-login-img {

        img {
          width: 39px;
        }
      }
    }
    .social-login-shadow {
      background-image: linear-gradient(-135deg, #1201a7, #82007e, #bb0101);
    }
  }

  .social-login.social-youtube {
    .social-login-inner {
      background: #FF0000;

      .social-login-img {

        img {
          width: 39px;
        }
      }
    }
    .social-login-shadow {
      background: #A90A0A;
    }
  }

  .social-login.social-twitch {
    .social-login-inner {
      background: #6542A6;

      .social-login-img {

        img {
          width: 32px;
        }
      }
    }
    .social-login-shadow {
      background: #4B2590;
    }
  }

  .social-login.social-mixer {
    margin: 0;

    .social-login-inner {
      background: #193760;

      .social-login-img {

        img {
          width: 39px;
        }
      }
    }
    .social-login-shadow {
      background: #1F4374;
    }
  }


  .section-partners {
    background: #2a2a2a;
    height: 450px;

    .partner-bg-img {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      width: 100vw;
      z-index: 9;
    }

    .container {
      position: relative;
      height: 450px;
    }

    .row-partner-logos {
      position: absolute;
      z-index: 10;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      height: 45px;

      img {
        width: 100%;
      }
    }
  }

  .section-sponsorships {
    background: #2a2a2a;
    padding-bottom: 100px;
    z-index: 2;

    .features-accent-bg {
      position: absolute;
      width: 100vw;
      bottom: 0;
      left: 0;
    }

    h3 {
      display: block;
      font-family: Barlow;
      font-weight: 900;
      font-size: 32px;
      text-align: center;
      color: #fff;
      max-width: 650px;
      margin: 0 auto 150px;
    }

    .row-feature {
      padding: 0 0 250px;

      h6 {
        font-family: Barlow;
        font-weight: 900;
        font-size: 24px;
        margin-bottom: 16px;
        color: #fff;
      }

      p {
        margin: 0;
        font-size: 16px;
        color: #b9bbbe;
      }
    }
  }

  .feature-1 {
    position: relative;
    width: 100%;

    .f1-outer {
      position: relative;
      display: block;
      margin: 0 auto;
      background: #3e3d3d;
      border-radius: 8px;
      overflow: hidden;
      width: 400px;
      height: 146px;
      max-width: 70%;
      padding: 20px;
      box-shadow: rgba(0, 0, 0, 0.6) 0px 6px 20px -4px;

      .f1-img {
        position: absolute;
        width: 100px;
        height: 100%;
        top: 0;
        left: 0;
        background: linear-gradient(180deg, #141214 0%, #2a2a2a 100%);
      }

      .f1-inner {
        position: relative;
        display: inline-block;
        width: calc(100% - 100px);
        text-align: center;
        float: right;

        .f1-title {
          transition: all 0.5s ease;

          h4 {
            text-transform: uppercase;
            font-family: Barlow;
            font-weight: 900;
            font-size: 18px;
            color: #b5b5b5;
            margin-bottom: 12px;
            transition: all 0.3s ease;
          }
        }
        .f1-title.active {
          margin-top: 40px;

          h4 {
            color: #fff;
            font-size: 20px;
          }
        }
        .f1-perc {
          transition: all 0.3s ease;

          h4 {
            text-transform: uppercase;
            font-family: Barlow;
            font-weight: 900;
            font-size: 32px;
            color: #fff;
            margin-bottom: 22px;
          }
        }
        .f1-perc.active {
          opacity: 0;
        }
        .f1-bar {
          position: relative;
          display: block;
          width: 85%;
          height: 8px;
          margin: 0 auto;

          .f1-bar-outer {
            height: 100%;
            background: #212121;
            border-radius: 8px;
            overflow: hidden;
            position: relative;

            .f1-bar-inner {
              .f1-bar-fill {
                position: absolute;
                top: 0;
                left: 0;
                width: 0%;
                height: 100%;
                background: linear-gradient(90deg, #25c725 0%, #118e11 100%);
              }

              .f1-bar-fill.transition {
                transition: all 2.0s linear;
              }
              .f1-bar-fill.active {
                width: 100%;
              }
            }
          }
        }
        .f1-bar.active {
          opacity: 0;
        }
      }
    }
  }

  .feature-2 {
    position: relative;
    width: 100%;

    .f2-outer {
      position: relative;
      display: block;
      margin: 0 auto;
      background: #3e3d3d;
      border-radius: 8px;
      overflow: hidden;
      width: 400px;
      max-width: 70%;
      padding: 20px;
      box-shadow: rgba(0, 0, 0, 0.6) 0px 6px 20px -4px;
      height: 195px;

      .f2-inner {
        position: relative;
        width: 100%;
        text-align: center;

        .f2-title {
          // color: white;

          h4 {
            text-transform: uppercase;
            font-family: Barlow;
            font-weight: 900;
            font-size: 22px;
            color: #b5b5b5;
            margin-bottom: 22px;
          }
        }
        .f2-earnings-1 {
          position: relative;
          display: block;
          margin: 5px auto 0;
          width: 70%;
          height: 14px;
          background: #1d1d1d;
          transition: all 0.26s ease;
        }
        .f2-earnings-2 {
          position: relative;
          display: block;
          margin: 12px auto 0;
          width: 70%;
          height: 14px;
          background: #292929;
          transition: all 0.26s ease;
        }
        .f2-cashout {
          position: relative;
          display: block;
          margin: 22px auto 0;
          width: 70%;
          padding: 6px 12px;
          background: black;
          text-align: center;
          border-radius: 4px;
          transition: all 0.8s ease;

          p {
            text-transform: uppercase;
            font-family: Barlow;
            font-weight: 900;
            font-size: 18px;
            text-transform: uppercase;
            color: #fff;
          }
        }

        .f2-cashout.hover {
          background: #168216;
        }
        .f2-cashout.clicked {
          background: #0f6b0f;
        }
        .f2-cashout.active {
          background: #168216;
          top: -45px;
        }
        .f2-mouse {
          position: absolute;
          bottom: 0px;
          right: 15px;
          transition: all 0.25s ease;
          width: 20px;
        }
        .f2-mouse.active {
          transform: translate(-53px, -10px);
        }
        .f2-earnings-1.active, .f2-earnings-2.active {
          opacity: 0;
        }
      }
    }
  }

  .feature-3 {
    position: relative;
    top: -125px;

    .f3-container {
      position: relative;
      display: block;
      margin: 0 auto;
      width: 415px;

      .f3-card {
        position: absolute;
        width: 200px;
        height: 335px;
        box-shadow: rgba(0, 0, 0, 0.6) 0px 6px 20px -4px;
        transition: all 0.52s ease;
        border-radius: 8px;

        .f3-card-inner {
          position: relative;
          overflow: hidden;
          border-radius: 8px;
          // background: #4e4e4e;
          background: #333333;

          .f3-img {
            position: relative;
            width: 100%;
            height: 200px;

            img {
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              margin: auto;
              width: 65%;
            }
          }
          .f3-lower {
            position: relative;
            width: 100%;
            height: 135px;
            padding: 15px;

            .f3-placeholder-1 {
              position: relative;
              display: block;
              margin: 5px auto 0;
              width: 70%;
              height: 14px;
              background: #1d1d1d;

            }
            .f3-placeholder-2 {
              position: relative;
              display: block;
              margin: 12px auto 0;
              width: 70%;
              height: 14px;
              background: #292929;
            }

            .f3-claim {
              position: relative;
              display: block;
              margin: 22px auto 0;
              width: 70%;
              padding: 6px 12px;
              background: #1f1f1f;
              text-align: center;
              border-radius: 4px;

              p {
                text-transform: uppercase;
                font-family: Barlow;
                font-weight: 900;
                font-size: 18px;
                text-transform: uppercase;
                margin: 0;
              }
            }
          }
        }
      }
      .f3-card-1 {
        .f3-img {
          // background: #808450;
          background: linear-gradient(-45deg, #808450 0%, #a8af59 100%);
        }
      }
      .f3-card-2 {
        .f3-img {
          // background: #845050;
          background: linear-gradient(-45deg, #845050 0%, #ab4b4b 100%);
        }
      }
      .f3-card-3 {
        .f3-img {
          // background: #485d47;
          background: linear-gradient(-45deg, #485d47 0%, #4b7149 100%);
        }
      }

      .f3-card-p1 {
        top: 0;
        left: 0;
        z-index: 1;
        opacity: 0.6;
      }
      .f3-card-p2 {
        top: -20px;
        left: 100px;
        z-index: 3;
        transform: scale(1.2);
      }
      .f3-card-p3 {
        top: -35px;
        left: 210px;
        z-index: 2;
        opacity: 0.7;
      }

    }
  }

  .section-sponsorships {

    .feature-3 {
      top: -15px !important;

      .f3-card {
        height: 200px !important;
      }
      .f3-card-p1 {
        top: 18px !important;
        left: 0px !important;
      }
      .f3-card-p2 {
        top: -40px !important;
        left: 130px !important;
      }
      .f3-card-p3 {
        top: -40px !important;
        left: 260px !important;
      }
    }

  }

  .section-creators {
    background: #2a2a2a;
    padding: 125px 0;
    z-index: 2;

    .sponsor-transition {
      img {
        position: absolute;
        top: -150px;
        left: 0;
        width: 100vw;
      }
    }

    .row-creators-header {
      position: relative;
      margin-bottom: 75px;
      text-align: center;
      z-index: 2;

      h3 {
        display: block;
        max-width: 400px;
        color: #fff;
        margin: 25px auto;
        // text-align: left;
        font-family: Barlow;
        font-weight: 900;
        font-size: 48px;
        text-transform: uppercase;
      }

      p {
        color: #b9bbbe;
        max-width: 550px;
        display: block;
        margin: 0 auto;
        font-size: 16px;
      }

      .creators-castle {
        display: block;
        margin: 0 auto;
        width: 80%;
      }
    }

    .row-creators-points {
      text-align: center;

      .creator-img-container {
        display: block;
        width: 125px;
        height: 125px;
        border-radius: 150px;
        background: linear-gradient(-45deg, #252525 0%, #141214 100%);
        margin: 0 auto;
        padding: 30px;
        margin-bottom: 30px;
        box-shadow: rgba(0, 0, 0, 0.3) 0px 5px 15px -4px;

        img {
          // height: 85px;
          height: 60px;
          margin-bottom: 25px;
        }
      }

      h6 {
        font-family: Barlow;
        font-weight: 900;
        font-size: 32px;
        margin-bottom: 16px;
        color: #fff;
      }

      p {
        color: #b9bbbe;
        font-size: 16px;
      }

    }
  }

  .section-join {
    background: #2a2a2a;

    .section-join-bg {
      position: relative;
      top: 0;
      left: 0;
      width: 100vw;
    }

    .container {
      position: absolute;
      top: 35%;
      left: 25px;
    }

    h3 {
      display: block;
      max-width: 400px;
      color: #fff;
      text-align: left;
      font-family: Barlow;
      font-weight: 900;
      font-size: 48px;
      text-transform: uppercase;
    }

    p {
      color: #b9bbbe;
      text-align: left;
    }
  }

  .section-ending {
    background: linear-gradient(180deg, rgba(42,42,42,1) 0%, rgba(20,18,20,1) 100%);

    h3 {
      display: block;
      max-width: 400px;
      color: #fff;
      text-align: right;
      font-family: Barlow;
      font-weight: 900;
      font-size: 48px;
      text-transform: uppercase;
    }

    p {
      color: #b9bbbe;
      text-align: right;
    }

    .footer-logo {
      display: block;
      margin: 50px auto;
      width: 180px;
    }

    .footer-menu {
      text-align: center;
      margin-top: 50px;
      font-size: 16px;
      font-family: Barlow;
      font-weight: 700;

      a {
        margin: 0 16px;
        color: white;
        cursor: pointer;
      }
    }

    .footer-legal {
      text-align: center;
      margin-top: 80px;
      font-family: Barlow;
      font-weight: 500;

      a {
        margin: 0 16px;
        color: #b9bbbe;
      }
    }
  }

  .hero-menu {
    position: absolute;
    top: 0;
    right: 15px;

    .hero-menu-inner {
      position: relative;

      .hero-menu-option {
        position: relative;
        display: inline-block;
        padding: 0 0 0 35px;

        a {
          font-family: Barlow;
          font-weight: 900;
          font-size: 14px;
          color: #fff;
        }
      }
    }
  }

  .introducing-weave {
    border: 1px solid #979797;
    border-radius: 50px;
    padding: 8px 12px;
    width: 155px;

    p {
      font-family: Barlow;
      font-size: 12px;
      text-transform: uppercase;
      text-align: center;
      margin: 0;

      span {
        color: #E1DFDF;
        font-weight: 500;
      }

      b {
        // color: white;
        background: -webkit-linear-gradient(#b5bd2f, #FFD700);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-weight: 900;
      }
    }
  }

}

.hero-container.end-footer-container {
  max-width: 600px;
  text-align: center;
  display: block;
  margin: 0 auto 200px;

  h1 {
    width: 450px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  p {
    text-align: center;
  }

  .social-login-text p {
    color: #fff !important;
  }

  .row-social-logins {
    width: 435px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

.section-sponsorships, .section-creators, .section-join, .section-ending {
  display: none !important;
}

.section-hero {

  .footer-legal {
    color: #BDBBC0;
    text-align: center;
    position: absolute;
    bottom: 25px;
    left: 0;
    right: 0;
    margin: auto;

    a {
      margin: 0 20px;
    }

    a {
      margin: 0 6px;
      font-size: 10px;
    }
  }
}

@media screen and (max-width: 767px) {
  html {
    max-width: 100vw;
    overflow-x: hidden;
  }
  .section-partners {

    .partner-bg-img {
      width: 180vw !important;
      left: -40vw !important;
    }

    .row-partner-logos {
      height: 165px !important;
    }
  }

  .new-landing .section-creators .sponsor-transition img {
    top: -100px;
  }
}

@media screen and (max-width: 599px) {

  .container {
    padding-left: 30px;
    padding-right: 30px;
  }

  .new-landing .section-hero .container {
    top: 30px !important;

    .hero-container {
      margin-top: 75px !important;

      .row-social-logins {
        padding-top: 35px;
      }
    }
  }

  .introducing-weave {
    display: block !important;
    margin: 0 auto !important;
  }

  .social-login {
    display: block !important;
    width: 270px !important;
    float: initial !important;
    margin: 0 auto 24px !important;
  }

  .new-landing .section-sponsorships .feature-3 {
    top: 100px !important;
  }

  .new-landing .section-sponsorships .features-accent-bg {
    width: 225vw;
  }

  .col-img {
    margin-top: 65px;
  }

  .new-landing .section-sponsorships .row-feature {
    padding-bottom: 150px;
  }

  .introducing-weave {
    display: none !important;
  }

  .row-creators-points .col-md-4 {
    margin-bottom: 50px;
  }

  .section-hero .feature-3 {
    display: none;
  }

  .section-hero {

    .footer-legal {

      a {
        margin: 0 6px;
        font-size: 10px;
      }
    }
  }

}

@media screen and (max-height: 675px) {

  .new-landing {
    .new-landing-logo {
      width: 100px !important;
    }

    .section-hero .container .hero-container {
      margin-top: 50px !important;

      .hero-title {
        font-size: 24px !important;
        margin-bottom: 24px !important;
      }

      .row-social-logins {
        padding-top: 30px !important;

        .social-login {
          margin-bottom: 16px !important;
        }
      }
    }
  }

}
