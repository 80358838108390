.input-character-count {
  margin-bottom: 0;
}

.trending-lists {
  margin-top: 50px;

  .trending-list-container {
    padding: 0 4px;

    .trending-list {
      position: relative;
      min-height: 200px;
      max-height: 450px;
      overflow-y: auto;
      list-style: none;
      padding: 10px 15px;
      // background: $module;
      border-radius: 3px;
      box-shadow: 0 4px 12px -1px rgba(18, 22, 33, 0.1);
    }
  }
}

.crm-filters {
  font-family: Barlow;
  font-weight: 500;
  font-size: 14px;

  .crm-filter {
    position: relative;
    display: inline-block;
    // background-color: #ebebeb;
    border: 1px solid transparent;
    border-radius: 4px;
    margin-right: 12px;

    .crm-filter-block {
      padding: 8px 12px;
      cursor: pointer;
    }
  }

  .crm-add-filter {
    position: relative;
    display: inline-block;
    color: #286efa;
    cursor: pointer;

    .main-dropdown-container.crm-filter-dropdown {
      min-width: 200px;

      .main-dropdown {

        .dropdown-results {
          li {
            padding: 6px;
          }
        }
      }
    }
  }
  .crm-add-filter:hover {
    color: #0549D1;
  }
}

.inbox-search {
  padding: 0 !important;

  &.expanded {
    width: 100%;
  }

  .inbox-search-inner {
    position: relative;
    display: flex;

    .inbox-search-glass {
      position: absolute;
      top: 0;
      left: 0;
      margin: auto;
      height: 100%;
      width: 32px;
      display: flex;
      padding: 7px 9px;

      &:hover {
        cursor: pointer;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }

    &.collapsed {
      input {
        width: 0;
        padding: 7px 7px 7px 25px;
      }
    }

    input {
      background-color: transparent;
      padding: 7px 7px 7px 32px;
      width: 100%;
    }
  }
}

.crm-search {

  .crm-search-container {
    position: relative;

    .crm-search-inner {

      .crm-search-glass {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 14px;
        margin: auto;
        left: 10px;
        z-index: 4;
      }

      input.search-filter-main-search {
        border-radius: 0;
      }

      input.dark-input {
        padding: 7px 7px 7px 32px;
        transition: all 0.32s ease;
        width: 180px;
        max-width: 100%;
      }

      input.dark-input:focus {
        width: 400px;
      }
    }
  }
}

.search-filter-container {
  position: relative;
  display: block;

  .search-filter-box-container {
    position: relative;
    display: inline-block;
    width: 100%;
    float: left;

    h5 {
      // font-size: 14px;
      // padding: 10px 25px 0 2px;
      // font-weight: 700;
      // text-transform: uppercase;
      // color: #898989;
      // letter-spacing: 1px;
      text-align: left;
      margin-bottom: 4px !important;
    }

    .search-filter-box {
      position: relative;
      // border-radius: 24px;
      // width: 180px;
      width: 100%;
      min-height: 76px;
      cursor: pointer;
      float: left;
    }
    .search-filter-box:hover {
    }
    .search-filter-box.active {
      border-radius: 0 0 0 0;

      .search-filter-dropdown {
        display: block;
        border-radius: 0 0 4px 4px;
      }
    }

    .search-filter-dropdown {
      display: none;
      position: absolute;
      // top: 75px;
      // left: -1px;
      // top: 42px;
      top: 75px;
      left: 0;
      width: calc(#{'100% + 2px'});
      z-index: 3;
      border-radius: 0 0 4px 4px;
      max-height: 400px;
      overflow: auto;
      // padding: 10px 0;

      ul {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
          padding: 15px 17px;
          text-align: left;
          cursor: pointer;
        }
        li:hover {
          background: #58c2f5;
          color: white;
        }
      }
    }
  }
}

.search-menu {

  .search-filter-container {
    // width: 180px;

    .search-filter-box-container {
      // width: 180px;

      .search-filter-box {
        // width: 180px;
        height: initial !important;
        min-height: initial !important;
      }
    }
  }
}

.search-filter-container.page-search {
  margin: 0 auto;

  .search-filter-box-container {
    margin-right: 25px;

    .search-filter-box {
      height: 40px;
      padding: 9px 25px;
      border-bottom: 1px solid #e0e3e5;
      box-shadow: rgba(0,0,0,0.22) 0px 2px 5px -2px;
    }
  }

  .search-filter-dropdown {
    top: 38px;
    border-color: rgba(0,0,0,0.04);
    box-shadow: rgba(0,0,0,0.22) 0px 2px 5px -2px;
  }
}

.search-filter-container.page-message {
  width: 100%;
  margin: 0;
  font-family: Barlow;

  .search-filter-box {
    padding: 16px 0 16px 16px;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
  }

  .search-filter-chosen {
    font-weight: 500;
    font-size: 16px;
  }
}

.crm-search.active {

  input.dark-input.active {
    width: 400px;
  }
}

.crm-actions {
  padding: 15px;
  // width: calc(100vw - 450px);
}

.mgmt-section {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  margin-left: -25px;
  margin-right: -25px;

  .mgmt-menu {
    width: 230px;
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: hidden;
  }
}

.mgmt-list {
  position: absolute;
  height: 100vh;
  // min-width: 300px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  box-shadow: 0 0 0 1px rgba(0,0,0,.05), 0 4px 24px 2px rgba(0,0,0,.1);
  box-shadow: rgba(33,43,54,.4) 0px 2px 8px;
  box-shadow: 0 2px 8px 0 rgba(0,0,0,.1);
  border-left: 1px solid #e0e3e5;
  transition: all .32s cubic-bezier(.165,.84,.44,1);
  left: 230px;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 30;
  background: #fff;
  // border-radius: 25px 0 0 25px;
  border-radius: 0;
  // border-radius: 35px 0 0 0;
}

.mgmt-list.collapsed {
  // transform: translateX(-19%);
  left: 0;

  .crm-actions {
    width: calc(100vw - 215px);
  }
}

.crm-hamburger {
  &:not(.no-position) {
    position: relative;
  }
  position: relative;
  float: left;
  display: inline-block;
  margin-right: 15px;
  cursor: pointer;
  padding: 2px 8px 3px 8px;
  border: 1px solid #d3dae3;
  border-radius: 50px;
  background: white;
  overflow: hidden;
  width: 43px;
  height: 26px;

  .crm-hamburger-inner {
    position: absolute;
    top: 1px;
    left: 6px;
    width: 45px;
    transition: all 0.28s ease;

    .crm-hamburger-caret {

    }
    .crm-hamburger-icon {
      margin: 0 6px 0 4px;
    }
    .crm-hamburger-caret-left {

    }
    .crm-hamburger-caret-right {

    }
  }
}

.crm-hamburger.collapsed {
  .crm-hamburger-inner {
    transform: translateX(-13px);

    .crm-hamburger-icon {
      margin: 0 4px 0 6px;
    }
  }
}

.crm-hamburger:hover {
  border-color: #1991EB;

  img {
    filter: invert(44%) sepia(98%) saturate(600%) hue-rotate(168deg) brightness(94%) contrast(96%) !important;
  }
}

.crm-title {
  position: relative;
  display: inline-block;
  float: left;
  font-family: Barlow;
  font-size: 21px;
  margin: 0;
}

.crm-inline {
  position: relative;
  display: inline-block;
  float: left;
  margin-right: 8px;
}

.crm-btn {
  position: relative;
  display: inline-block;
  line-height: 18px;
  // border: 1px solid;
  // border-color: rgba(0,0,0,.2);
  border-radius: 4px;
  padding: 5px 12px 7px;
  cursor: pointer;
  font-family: Barlow;
  font-weight: 500;
  background: #fff;
  border: 1px solid #eee;
}

.crm-filters {
  margin-bottom: 25px;

  h5 {
    font-weight: 500;
    font-family: Barlow;
    font-size: 18px;
  }
}

.crm-table {
  position: relative;
  height: #{'calc(100vh - 190px)'};
  width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
  padding: 0 0 150px;
  border-radius: 15px;

  .crm-table-header {
    width: max-content;

    .crm-td {
      padding: 12px 14px;
      height: 44px;
    }
  }

  .crm-table-main {
    overflow-y: auto;
    overflow-x: hidden;
    width: max-content;
    height: #{'calc(100vh - 206px)'};
    .crm-td {
      padding: 19px 14px;
    }
  }

  .crm-td-menu {
    cursor: pointer;
  }

  .crm-td {
    position: relative;
    display: inline-block;
    float: left;
    // padding: 14px;
    text-align: left;
    font-weight: 400;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    // height: 48px;
    height: 58px;

    p {
      margin: 0;
      white-space: nowrap;
    }
  }

  .crm-row-header .crm-td {
    // height: 48px;
  }

  .crm-username {
    width: 100%;
    cursor: pointer;
    padding-right: 0;

    .crm-username-title {
      position: relative;
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      line-height: 16px;
      margin-bottom: 8px;
    }
    a.crm-username-title-text {
      color: #1991EB;
      font-weight: 500;
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      line-height: 16px;
    }
  }
  .crm-username:hover {
    text-decoration: underline;
  }

  .crm-td-pd {

  }

  .crm-td-xs {
    width: 62px;
  }

  .crm-td-sm {
    width: 100px;
  }

  .crm-td-md {
    width: 156px;
  }

  .crm-td-ml {
    width: 225px;
    padding-left: 0;
  }

  .crm-td-lg {
    width: 265px;
    padding-left: 0;
  }

  .crm-td-xl {
    width: 325px;
    padding-left: 0;
  }

  .crm-td-dbl {
    padding-top: 7px;
  }

  .crm-dbl-text-container {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    max-height: 46px;
    width: 100%;
    padding-right: 12px;
  }

  .crm-dbl-text {
    position: relative;
    white-space: initial !important;
    line-height: 23px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }

  .row.crm-row.crm-row-header {

    .crm-checkbox {

      input {
        margin-top: 4px !important;
      }
    }
  }

  .row.crm-row {
    min-width: 4450px;
    padding: 0;
    // padding: 0 0 0 15px;
    // margin: 1px 6px;
    margin: 0;
    border-radius: 3px;

    .crm-drag {

      svg {
        cursor: pointer;
      }
    }

    .crm-profile-img {
      position: absolute;
      display: block;
      width: 30px;
      border-radius: 50%;
      margin-left: -7px;
      margin-top: -1px;
    }

    .crm-checkbox {
      display: none;
      // position: relative;
      // width: 24px;
      // height: 24px;
      // border-radius: 50%;

      input {
        margin-top: 12px;
        margin-left: 9px;
        width: 14px;
      }
    }
  }

  .row.crm-row.crm-row-influencer:hover {
    // border-color: #eee !important;
    box-shadow: rgba(139,163,189,.21) 0px 2px 8px;
    // transform: translateX(-4px);
  }

  .row.crm-row:hover, .row.crm-row.selected {
    .crm-profile-img {
      display: none;
    }
    .crm-checkbox {
      display: block;

      input {

      }
    }
  }
  .row.crm-row.crm-row-influencer.selected {
    // background-color: #e8f0ff;
  }

}



.crm-row-influencer, .slide-out-ratings {

  .influencer-ratings {
    padding-top: 5px;
    opacity: 0.3;

    img {
      display: inline-block;
      float: left;
      width: 14px;
    }
  }
  .influencer-ratings.active {
    opacity: 1;
  }
}

.crm-tag {
  display: inline-block;
  float: left;
  font-size: 12px;
  padding: 2px 6px;
  border-radius: 3px;
  margin-left: 10px;
}

.crm-btn:disabled {
  opacity: 0.5;
  border-color: initial;
}

.main-dropdown-container {
  position: relative;
  width: 100%;
}

.main-dropdown {
  display: none;
  position: absolute;
  // top: 53px;
  top: 0px;
  left: -1px;
  width: calc(#{'100% + 2px'});
  min-height: 250px;
  max-height: 400px;
  min-width: 225px;
  overflow-y: auto;
  border: 1px solid #ddd;
  z-index: 99;
  box-shadow: 0px 9px 9px 0px rgba(0, 0, 0, 0.13);
  border-radius: 0 0 8px 8px;

  .main-dropdown-outer {
    position: relative;
    width: 100%;

    .main-dropdown-inner {
      position: relative;

      .dropdown-section {
        position: relative;

        .dropdown-insert {
          display: none
        }
        .dropdown-insert.active {
          display: block
        }

        .dropdown-placeholder {
          h5 {
            text-align: center;
            margin-top: 45px;
            margin-bottom: 45px;
          }
        }

        h5 {
          font-size: 16px;
          padding: 10px 25px 0 25px;
          font-weight: 500;
        }

        ul.dropdown-results {
          padding: 0;
          margin: 0;

          li {
            list-style: none;
            padding: 6px 25px;
            border-bottom: 1px solid #eee;
            cursor: pointer;

            .inner-autocomplete {
              position: relative;
              display: inline-block;

              img {
                display: inline-block;
                // float: left;
                width: 45px;
                border-radius: 50%;
              }

              span {
                padding: 0px 0 0 10px;
                font-family: Barlow;
                font-weight: 500;
                font-size: 16px;
              }
            }

            .inner-autocomplete.a-name {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

.main-dropdown.align-left {
  left: -1px;
  right: initial;
}
.main-dropdown.align-right {
  left: initial;
  right: -1px;
}

.main-dropdown.active {
  display: block;
}

.crm-table.drip-crm-table {
  .crm-td {
    padding: 18px 0;
    overflow: initial !important;
  }
}

.trending-list-container {
    padding: 0 4px;

    .trending-list {
      position: relative;
      min-height: 200px;
      max-height: 350px;
      overflow-y: auto;
      list-style: none;
      padding: 10px 15px;
      background: white;
      // background: $module;
      border-radius: 3px;
      box-shadow: 0 4px 12px -1px rgba(18, 22, 33, 0.1);
    }
  }
