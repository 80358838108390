.html, body {

}

.react-date-picker {
  position: absolute !important;
  top: 80px;
  left: 15px;

  .react-date-picker__wrapper { display: none !important; visibility: hidden !important; opacity: 0 !important; }
  .react-date-picker__calendar .react-calendar {
    border-radius: 15px;
    border: 1px solid rgba(0, 0, 0, 0.04);
    box-shadow: rgba(0,0,0,.32) 0px 2px 35px -24px;
    background: white;

    .react-calendar__navigation {
      margin: 0;
      button.react-calendar__navigation__label {
        font-weight: bold;
        font-size: 16px;
      }
      button.react-calendar__navigation__arrow {
        font-size: 28px;
      }
    }
    .react-calendar__month-view__weekdays__weekday {
      font-size: 14px;
    }
    .react-calendar__tile {
      font-size: 16px;
    }
    abbr, span, button {
      font-family: Barlow !important;
    }
    button abbr {
      font-weight: 500;
    }
  }
}

.invoice-plaid-container {
  padding: 25px;

  button.plaid-btn {
    float: initial;
    font-weight: bold;
    width: 300px;
    padding: 7px 30px 9px 30px !important;
    font-size: 18px;

    img {
      margin: -3px 8px 0 0;
    }
  }
}

.create-invoice-deliverables {

  .create-invoice-deliverable {
    padding: 8px 0;

    .create-invoice-deliverable-icon {
      position: relative;
      display: inline-block;
      float: left;
      padding-right: 12px;

      img {
        width: 16px;
        margin-top: -2px;
      }
    }
    .create-invoice-deliverable-description {
      position: relative;
      display: inline-block;
      float: left;
    }

    .create-invoice-deliverable-remove {
      img {
        cursor: pointer;
      }
    }
  }
}

.deliverable-options {

  .deliverable-option {
    position: relative;
    display: inline-block;
    float: left;
    margin-right: 6px;
    padding: 8px 10px;
    border: 1px solid #eee;
    border-radius: 6px;
    box-shadow: rgba(0,0,0,.32) 0px 2px 11px -8px;
    cursor: pointer;
    height: 40px;

    img {
      // width: 18px;
      height: 18px;
    }
  }
  .deliverable-option.deliverable-tiktok.selected { background-color: #000; }
  .deliverable-option.deliverable-instagram.selected { background-image: linear-gradient(-135deg, #1400c8, #b900b4, #f50000); }
  .deliverable-option.deliverable-youtube.selected { background-color: #f00; }
  .deliverable-option.deliverable-twitch.selected { background-color: rgb(100, 65, 164); }
  .deliverable-option.deliverable-twitter.selected { background-color: #1DA1F2; }
  .deliverable-option.deliverable-whitelisting.selected { background-color: #2f7fec; }
  .deliverable-option.deliverable-content.selected { background-color: #2f7fec; }
  .deliverable-option.deliverable-other.selected { background-color: #2f7fec; }
  .deliverable-option.selected { border: none; }
}

.preview-invoice {

  .module {
    padding: 8px 15px;

    label {
      text-transform: uppercase;
      letter-spacing: 1px;
      font-size: 12px;
    }
    h3 {
      margin: 0;
      padding: 0;
      text-align: right;
      font-size: 24px;
    }
    .preview-invoice-company-image-container {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      width: 65px;
      height: 65px;

      .preview-invoice-company-image-inner {
        position: relative;
        width: 65px;
        height: 65px;
      }
      .preview-invoice-company-image {
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        width: 100%;
        height: 100%;
        border-radius: 8px;
      }
    }
  }
}

.invoice-status-pill {
  position: relative;
  display: inline-block;
  padding: 5px 12px 6px 28px;
  background: rgba(0,0,0,0.28);
  border-radius: 20px;
  float: left;
  margin-right: 8px;

  .invoice-status-ind, .invoice-status-text {
    display: inline-block;
    float: left;
  }
  .invoice-status-ind {
    position: absolute;
    width: 9px;
    height: 9px;
    left: 12px;
    top: -1px;
    bottom: 0;
    margin: auto;
    background: #26df26;
    border-radius: 50%;
  }
  .invoice-status-text {
    color: white !important;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: bold;
    font-size: 12px;
    padding-top: 1px;
  }
}
.invoice-status-pill.invoice-status-preview {
  float: right;
  margin: 0;
  background: #f4f4f4;

  .invoice-status-text {
    span {
      color: black;
    }
  }
}


.invoice-page {
  width: 100vw;
  height: 100vh;
  background: #f5f4f9;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  /*width: 100%;*/
  /*height: 100%;*/
  padding: 0;
  margin: 0;
  color: #4a4a4a;
  overflow: auto;
  padding: 60px 15px;

  .invoice-weave-logo-container {
    position: absolute;
    top: 15px;
    width: 100%;

    .invoice-weave-logo {
      position: relative;
      // height:
    }

    .logo-hero {
      position: absolute;
      width: 128px;
      left: 0;
      transition: all 0.32s ease;
      transform: translateX(15px);
    }
  }

  @media screen and (max-width: 1000px){
    .invoice-weave-logo-container .invoice-weave-logo .logo-hero {
      // left: 0;
      // right: 0;
      // margin: 0 auto;
      transform: translateX(calc(calc(100vw - 128px) * .5))
    }
  }

  .invoice-box {
    margin: auto;
    width: 650px;
    max-width: 100%;
    z-index: 2;

    // background: #fcfcfc;
    // overflow: hidden;
    // border-radius: 25px;
    // box-shadow: 0 10px 20px -10px rgba(0,0,0,.4);


    .invoice-box-inner {
      position: relative;
      width: 100%;
      // height: 100%;

      background: #fcfcfc;
      overflow: hidden;
      border-radius: 25px;
      box-shadow: 0 10px 20px -10px rgba(0,0,0,.4);
      margin-bottom: 25px;
    }

    .logo-hero {
      width: 168px;
    }

    label {
      text-transform: uppercase;
      letter-spacing: 2px;
      font-size: 12px;
    }

    .invoice-header-container {
      position: relative;
    }

    .invoice-overview-module {
      position: absolute;
      left: 0;
      bottom: -80px;
      width: 100%;

      .invoice-overview-module-inner {
        padding: 0 25px;

        .module {
          margin: 0;
        }
      }
    }

    // header {
    //   height: 250px;
    //   border-radius: 0 0 25px 25px;
    //   background: #05d5ff;
    //   background: linear-gradient(118deg, #5d3dff, #2a95ff 32%, #05d5ff 59%, #12d9fb 62%, #20ddf6 66%, #22ddf6 67%, #a6ffcb);
    //   display: -webkit-box;
    //   display: -ms-flexbox;
    //   display: flex;
    //   -webkit-box-align: center;
    //   -ms-flex-align: center;
    //   align-items: center;
    //   -webkit-box-pack: center;
    //   -ms-flex-pack: center;
    //   justify-content: center;
    //
    //   h3 {
    //     color: white !important;
    //   }
    // }

    header {
      border-radius: 0 0 35px 35px;
      background: #05d5ff;
      // background: linear-gradient(118deg, #5d3dff, #2a95ff 32%, #05d5ff 59%, #12d9fb 62%, #20ddf6 66%, #22ddf6 67%, #a6ffcb);
      background: linear-gradient(118deg, #5d3dff, #2a95ff 32%, #05d5ff 59%, #12d9fb 62%, #20ddf6 66%, #22ddf6 67%, #8131f1);
      padding: 25px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      // display: -webkit-box;
      // display: -ms-flexbox;
      // display: flex;
      // -webkit-box-align: center;
      // -ms-flex-align: center;
      // align-items: center;
      // -webkit-box-pack: center;
      // -ms-flex-pack: center;
      // justify-content: center;

      .invoice-title {
        position: relative;
        width: 400px;
        max-width: 80%;
        overflow: hidden;

        .invoice-title-text {
          color: white !important;
          font-weight: bold;
          font-family: Barlow;
          font-size: 36px;
          letter-spacing: 1px;
          margin-top: 15px;
          width: 100%;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          margin: 0;
        }
      }
      .invoice-num {
        color: white !important;
        margin: 0;
      }

      .invoice-header-actions {
        position: relative;
        display: inline-block;
        float: right;

        .invoice-header-action {

          img.downloading {
            animation-name: downloading;
            animation-iteration-count: infinite;
            animation-duration: 0.28s;
            animation-direction: alternate;
            transition: all 0.28s ease;
          }
          @keyframes downloading {
            from {transform: translateY(0);}
            to {transform: translateY(5px);}
          }
        }
      }
    }

    .invoice-go-back {
      position: relative;
      height: 54px;

      .invoice-go-back-icon {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        width: 20px;
        cursor: pointer;
      }
    }

    .invoice-header-container {
      display: none;

      header.invoice-header {
        height: 230px;
      }
    }
    .invoice-header-secondary-container {
      display: none;
      height: 72px;

      header.invoice-header-secondary {
        padding: 8px 25px;
        border-radius: 0;
      }

      .invoice-title-text {
        font-size: 24px !important;
      }

      .invoice-header-secondary-amount {
        float: right;

        h3 {
          color: white !important;
          font-weight: bold;
          font-size: 24px;
          margin: 13px 0 0 0;
        }
      }
    }
    .invoice-header-container.show {
      display: block;
    }
    .invoice-header-secondary-container.show {
      display: block;
    }

    .invoice-summary-amount {
      position: relative;

      .invoice-summary-amount-bg {
        position: absolute;
        top: -11%;
        left: -6%;
        width: 110%;
        height: 125%;
        background: #f5f8fd;
        border-radius: 15px;
      }
      .invoice-summary-amount-meta {
        z-index: 2;
        position: relative;

        h3 {
          margin: 10px 0 12px 0;
        }
      }
    }

    .invoice-items {
      .invoice-items-header {
        font-weight: bold;
        // background: #dcdcdc;
        padding: 8px 0;
        border-radius: 8px;
      }
      .invoice-item {
        padding: 8px 0;
        font-size: 16px;

        .invoice-item-desc {

          .invoice-item-desc-platform-container {
            position: relative;
            display: inline-block;
            float: left;
            margin-right: 8px;

            .invoice-item-desc-platform {
              position: relative;
              width: 28px;
              height: 28px;
              background: #ececec;
              border-radius: 50%;
              margin-top: -3px;

              .invoice-item-desc-icon {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                width: 14px;
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
              }
            }
          }

          .invoice-item-desc-text {
            position: relative;
            display: inline-block;
            float: left;
          }
        }
        .invoice-item-amount {
          text-align: right;
          font-weight: bold;
        }
      }
    }

    .invoice-dollar {
      font-weight: 400;
      margin-right: 3px;
      color: #fafafa;
    }

    .invoice-notes {
      position: relative;
      display: inline-block;
      float: left;

      .invoice-notes-text {

      }
    }
    .invoice-total-sum {
      position: relative;
      display: inline-block;
      float: right;
      text-align: right;

      .invoice-total-sum-num {
        font-size: 20px;
        font-weight: bold;
        margin: 0;
      }
    }

    .invoice-padding, .invoice-payment {
      padding: 25px;
      z-index: 2;
    }
    .invoice-padding-top {
      padding-top: 95px;
    }
    .invoice-scrollable {
      // overflow: auto;
      // height: calc(90vh - 425px);
    }

    .invoice-due-date {
      .invoice-date, .invoice-time-remaining, .invoice-date-text, .invoice-remaining-text, .invoice-icon {
        position: relative;
        display: inline-block;
        float: left;
      }

      .invoice-icon {
        img {
          width: 16px;
          margin: -3px 6px 0 0;
        }
      }

      .invoice-date {

      }
      .invoice-remaining {
        float: right;

        .invoice-remaining-text {

        }
      }
    }

    .invoice-payments {

      .invoice-payments-header {
        background: #fff;
        box-shadow: rgba(0,0,0,0.32) 0px 2px 35px -24px;
      }

      .invoice-payments-menu {
        // position: absolute;
        width: 100%;
        // height: 77px;
        left: 0;
        bottom: 0;
        background: white;
        z-index: 3;

        .invoice-payments-border {
          position: relative;
          width: 100%;
          height: 2px;
          background: #eee;

          .invoice-payments-selector {
            position: absolute;
            // background: black;
            background: #427afe;
            width: 20%;
            height: 4px;
            bottom: 0;
            left: 0;
            border-radius: 4px;
            transition: all 0.32s ease;
          }

          .invoice-payments-selector.invoice-selected-ach { transform: translateX(calc(0)); border-radius: 0 4px 4px 0; }
          .invoice-payments-selector.invoice-selected-paypal { transform: translateX(calc(650px * .20)); }
          .invoice-payments-selector.invoice-selected-wire { transform: translateX(650px * .40); }
          .invoice-payments-selector.invoice-selected-card { transform: translateX(650px * .60); }
          .invoice-payments-selector.invoice-selected-billcom { transform: translateX(calc(650px * .80)); border-radius: 4px 0 0 4px; }
        }

        .invoice-payment-options {
          position: relative;

          .invoice-payment-option {
            position: relative;
            display: inline-block;
            float: left;
            width: 20%;
            // border-bottom: 2px solid #eee;
            // padding: 8px;

            .invoice-payment-btn {
              position: relative;
              width: 100%;
              height: 50px;
              padding: 15px 0;
              text-align: center;
              // background: white;
              // border: 1px solid rgba(0, 0, 0, 0.04);
              // box-shadow: rgba(0,0,0,0.32) 0px 2px 18px -10px;
              // border-radius: 15px;
              cursor: pointer;

              .invoice-payment-icon, .invoice-payment-logo {
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
              }
              .invoice-payment-icon-container {
                position: relative;
                display: inline-block;
                margin-right: 8px;
                width: 18px;
                height: 18px;

                .invoice-payment-icon {
                  position: absolute;
                  width: 18px;
                  height: 18px;
                  top: 3px;
                  left: 0;
                }
              }
              .invoice-payment-text-container {
                position: relative;
                display: inline-block;

                .invoice-payment-text {
                  font-weight: 500;
                }
              }

              .invoice-payment-logo {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                width: 90px;
                height: 50px;
              }
            }
          }
        }
      }

      .invoice-paypal-me-ind {
        position: absolute;
        top: 12px;
        right: 12px;
        width: 28px;
        height: 28px;

        .invoice-paypal-me-valid, .invoice-paypal-me-invalid {
          position: relative;
          width: 100%;
          height: 100%;
          border-radius: 50%;

          img {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            width: 12px;
            height: 12px;
          }
        }
        .invoice-paypal-me-valid { background: #23e026; }
        .invoice-paypal-me-invalid { background: #ff4747; }
      }

      .invoice-paypal-btn {
        position: relative;
        background: #ffc439;
        width: 100%;
        height: 55px;
        padding: 12px;
        text-align: center;
        border-radius: 4px;
        cursor: pointer;

        .invoice-paypal-me {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto;
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          background-image: url('https://weave-img.s3-us-west-2.amazonaws.com/icons/icon-general/paypal-me.svg');
          width: 150px;
          height: 24px;
        }
      }
      .invoice-paypal-btn:hover { background: #f3ba37; }
    }

    input {
      transition: ease-in-out all 500ms;
    }

    .login-error {
      input {
        box-shadow: inset 0px 0px 1px 1px #ff0000a1;
      }
    }

    .social-button {
      background: rgba(255, 0, 0, 0.79);
      padding: 16px 16px 12px;
      display: block;
      border-radius: 8px;
      text-align: left;
      color: white;
      font-size: 14px;
      box-shadow: 0 1px 2px rgba(0,0,0,.3);
      cursor: pointer;
      margin: 0 auto;
    }
    .social-button .social-button-icon {
      width: 21px;
      height: 18px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100%;
      display: inline-block;
      background-image: url(https://s3-us-west-2.amazonaws.com/weave-img/web-assets/icons/youtube-icon.svg);
    }
    .social-button .social-button-text {
      display: inline-block;
      top: -4px;
      position: relative;
      margin-left: 10px;
      font-weight: 500;
    }
    .login-separator {
      text-align: center;
      margin: 14px 0;
      display: block;
      letter-spacing: 1px;
      font-size: 12px;
    }
    .input-text-wrapper {
      background: #F5F4F7;
      background-size: 14px;
      background-repeat: no-repeat;
      background-position: 16px center;
      height: 50px;
      // margin-bottom: 20px;
      margin-bottom: 8px;
      border-radius: 8px;
    }
    .input-text-wrapper.email {
      background-image:
        url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE4LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCINCgkgdmlld0JveD0iMCAwIDM1MCAzNTAiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDM1MCAzNTA7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxnPg0KCTxwYXRoIGQ9Ik0xNzUsMTcxLjE3M2MzOC45MTQsMCw3MC40NjMtMzguMzE4LDcwLjQ2My04NS41ODZDMjQ1LjQ2MywzOC4zMTgsMjM1LjEwNSwwLDE3NSwwcy03MC40NjUsMzguMzE4LTcwLjQ2NSw4NS41ODcNCgkJQzEwNC41MzUsMTMyLjg1NSwxMzYuMDg0LDE3MS4xNzMsMTc1LDE3MS4xNzN6Ii8+DQoJPHBhdGggZD0iTTQxLjkwOSwzMDEuODUzQzQxLjg5NywyOTguOTcxLDQxLjg4NSwzMDEuMDQxLDQxLjkwOSwzMDEuODUzTDQxLjkwOSwzMDEuODUzeiIvPg0KCTxwYXRoIGQ9Ik0zMDguMDg1LDMwNC4xMDRDMzA4LjEyMywzMDMuMzE1LDMwOC4wOTgsMjk4LjYzLDMwOC4wODUsMzA0LjEwNEwzMDguMDg1LDMwNC4xMDR6Ii8+DQoJPHBhdGggZD0iTTMwNy45MzUsMjk4LjM5N2MtMS4zMDUtODIuMzQyLTEyLjA1OS0xMDUuODA1LTk0LjM1Mi0xMjAuNjU3YzAsMC0xMS41ODQsMTQuNzYxLTM4LjU4NCwxNC43NjENCgkJcy0zOC41ODYtMTQuNzYxLTM4LjU4Ni0xNC43NjFjLTgxLjM5NSwxNC42OS05Mi44MDMsMzcuODA1LTk0LjMwMywxMTcuOTgyYy0wLjEyMyw2LjU0Ny0wLjE4LDYuODkxLTAuMjAyLDYuMTMxDQoJCWMwLjAwNSwxLjQyNCwwLjAxMSw0LjA1OCwwLjAxMSw4LjY1MWMwLDAsMTkuNTkyLDM5LjQ5NiwxMzMuMDgsMzkuNDk2YzExMy40ODYsMCwxMzMuMDgtMzkuNDk2LDEzMy4wOC0zOS40OTYNCgkJYzAtMi45NTEsMC4wMDItNS4wMDMsMC4wMDUtNi4zOTlDMzA4LjA2MiwzMDQuNTc1LDMwOC4wMTgsMzAzLjY2NCwzMDcuOTM1LDI5OC4zOTd6Ii8+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8L3N2Zz4NCg==);
    }
    .input-text-wrapper.password {
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMCAxNiI+PHBhdGggZmlsbD0iIzAwMDAwMCIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNOC44OSA2LjRWNC4xMUE0IDQgMCAwIDAgNSAwYTQgNCAwIDAgMC0zLjg5IDQuMTFWNi40Qy40NiA2LjYzIDAgNy4zIDAgOC4wNXY2LjJDMCAxNS4yMi43NCAxNiAxLjY3IDE2aDYuNjZBMS43IDEuNyAwIDAgMCAxMCAxNC4yNXYtNi4yYzAtLjc2LS40Ni0xLjQtMS4xMS0xLjY1ek01IDEuMTZjMS41NCAwIDIuNzggMS4zMiAyLjc4IDIuOTV2Mi4ySDIuMjJWNC4xYzAtMS42MyAxLjI0LTIuOTUgMi43OC0yLjk1em0uMzcgMTAuN2MwIC4yMi0uMTcuNC0uMzcuNGEuMzguMzggMCAwIDEtLjM3LS40VjEwLjdjMC0uMjEuMTctLjM5LjM3LS4zOS4yIDAgLjM3LjE4LjM3LjM5djEuMTZ6Ii8+PC9zdmc+);
    }
    .input-text-wrapper.url {
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MCA2MCI+PHBhdGggZmlsbD0iIzAwMDAwMCIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNMzAgMTVjLTguMjUgMC0xNSA2Ljc1LTE1IDE1czYuNzUgMTUgMTUgMTVoMTVjOC4yNSAwIDE1LTYuNzUgMTUtMTVhMzAgMzAgMCAxIDAtNjAgMCAzMCAzMCAwIDAgMCAzMCAzMGgzMHYtNy41SDMwQTIyLjQ3IDIyLjQ3IDAgMCAxIDcuNSAzMCAyMi40NyAyMi40NyAwIDAgMSAzMCA3LjUgMjIuNDcgMjIuNDcgMCAwIDEgNTIuNSAzMGMwIDQuMTMtMy4zOCA3LjUtNy41IDcuNWgtMi4xQTE1LjA0IDE1LjA0IDAgMCAwIDQ1IDMwYzAtOC4yNS02Ljc1LTE1LTE1LTE1em0wIDIyLjVhNy41MiA3LjUyIDAgMCAxLTcuNS03LjVjMC00LjEzIDMuMzgtNy41IDcuNS03LjUgNC4xMyAwIDcuNSAzLjM4IDcuNSA3LjUgMCA0LjEzLTMuMzggNy41LTcuNSA3LjV6Ii8+PC9zdmc+);
    }
    .input-text-wrapper .input-text {
      width: 300px;
      padding: 14px;
      border: 0;
      background: transparent;
      background-size: 14px;
      background-repeat: no-repeat;
      background-position: 16px center;
      text-indent: 30px;

      border-radius: 8px;
      border-style: solid;
      border-width: 2px;
      // border-image-source: linear-gradient(to right, #c70bef 4%, #f046a1 47%, #ff9a38 97%);
      border-image-slice: 1;
      border: solid 2px #dee2e9;
    }
    .input-text-wrapper .input-text.success {
      border-color: #77e74f;
    }
    .btn-submit {
      background: black;
      color: white;
      border: 0;
      // border-radius: 8px;
      // text-transform: uppercase;
      // padding: 20px;
      width: 100%;
      // box-shadow: 0 10px 10px -10px rgba(0,0,0,.4);
      font-weight: bold;
      cursor: pointer;
      font-size: 18px;
      padding: 16px 26px;
      border-radius: 8px;
      box-shadow: 0 10px 20px 0 rgba(48, 41, 88, 0.12);
      background-image: linear-gradient(to right, #c407f5, #ff5c82 75%, #ff9d35);
    }
    button.disabled {
      cursor: not-allowed;
      pointer-events: none;
      opacity: 0.6;
    }
    .switch-boxes {
      text-align: center;
      margin: 20px auto 0;
    }
    .input-text-wrapper.message {
      height: 92px;
    }
    .input-text-wrapper.message textarea {
      resize: none;
      /*width: 100%;*/
      height: 100%;
      background: none;
      text-indent: 5px;
    }

  }

  .login-bg-wave {
    position: absolute;
    bottom: -18%;
    left: -12.5%;
    right: 0;
    width: 125%;
    height: 92vh;
    transform: rotate(-10deg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    // background-image: url('images/login-wave.svg');
    // height: 0;
    // border-top: 100vh solid transparent;
    // border-left: 100vh solid transparent;
    // border-right: 100vh solid rgba(0,0,0,0);
  }
}

.taxinfo {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(0,0,0,0.32);

  label {
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 12px;
  }

  .taxinfo-container {
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;

    .taxinfo-popup {
      margin: auto;
      width: 650px;
      max-width: 100%;
      min-height: 350px;
      z-index: 2;

      .taxinfo-close {
        position: absolute;
        top: 25px;
        right: 25px;

        img {
          width: 16px;
          cursor: pointer;
        }
      }

      .taxinfo-popup-inner {
        position: relative;
        width: 100%;
        // height: 100%;
        padding: 25px;
        background: #fcfcfc;
        overflow: hidden;
        border-radius: 25px;
        box-shadow: 0 10px 20px -10px rgba(0,0,0,.4);

        .taxinfo-popup-content {
          min-height: 350px;
        }

        .taxinfo-popup-footer {

        }
      }
    }
  }
}

.invoice-overview-report {
  width: 700px;
  font-family: Barlow;
  margin: 15px 0;

  .overview-unpaid {
    font-weight: bold;
    font-size: 16px;
    margin-right: 12px;
  }
  .overview-daterange {
    font-size: 14px;
  }
  .overview-breakdown {
    margin-top: 15px;

    .overview-amount {
      font-weight: 800;
      font-size: 24px;
      margin: 0;
    }
  }

  .invoice-bar-container {
    position: relative;
    width: 100%;
    height: 22px;
    border-radius: 4px;
    overflow: hidden;

    .invoice-bar {
      position: relative;
      display: inline-block;
      float: left;
      height: 100%;
      padding-right: 3px;
      border-radius: 4px;
      overflow: hidden;

      .invoice-bar-inner {
        position: relative;
        border-radius: 4px;
        width: 100%;
        height: 100%;

        .invoice-bar-fill {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          border-radius: 4px;
        }
      }
    }

    .invoice-bar-left { margin-left: -3px; }
    .invoice-bar-right { margin-right: -3px; }
    .invoice-bar-fill.invoice-unpaid { background: #ff8000; }
    .invoice-bar-fill.invoice-deposited { background: #2ca01c; }
    .invoice-bar-fill.invoice-earlypay {
      background: linear-gradient(118deg,#5d3dff,#2a95ff 32%,#05d5ff 59%,#12d9fb 62%,#20ddf6 66%,#22ddf6 67%,#a6ffcb);
      animation: earlypay-flashing .82s infinite linear;
      animation-fill-mode: forwards;
      transition: all 0.41s ease;
    }
  }
}

@keyframes earlypay-flashing {
  0%   {
    opacity: 1;
  }
  50%  {
    opacity: 0.72;
  }
  100% {
    opacity: 1;
  }
}

@media screen and (max-width: 768px){
  .login-page {
    height: auto;
  }
}

@media (max-width: 550px){
  .login-page {
    .login-box {
      width: 100vw;
      height: 100vh;
      border-radius: 0;

      .input-text-wrapper .input-text {
        width: 100%;
      }
    }
  }
}
