.autocomplete-dropdown {
  .game-chosen-img, .game-chosen-text {
    position: relative;
    display: inline-block;
    float: left;
  }
  .game-chosen-text {
    padding: 15px;
  }
}

.add-influencer-popup {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(0,0,0,0.18);
  z-index: 31;

  .add-influencer-popup-container {
    position: relative;
    width: 100%;
    height: 100%;

    .add-influencer-popup-module {
      position: absolute;
      width: 70%;
      height: 460px;
      max-height: 90vh;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      border-radius: 8px;
      box-shadow: rgba(0, 0, 0, 0.6) 0px 6px 20px -4px;
      overflow: hidden;

      // FIX THIS
      .add-influencer-popup-header {
        position: relative;
        padding: 15px;

        h3 {
          margin: 0;
          padding: 0;
        }

        .add-influencer-popup-header-title, .add-influencer-popup-header-close  {
          position: relative;
          display: inline-block;
          width: 50%;
        }
        .add-influencer-popup-header-title {

        }
        .add-influencer-popup-header-close {
          .popup-out-close-icon {

          }
        }
      }

      .add-influencer-popup-body {
        position: relative;
        overflow: hidden;
      }

      .popup-out-close-icon {
        position: absolute;
        top: 25px;
        right: 25px;
        width: 16px;
        cursor: pointer;
        z-index: 3;
        opacity: 0.85;
        transition: all 0.32s ease;
      }
      .popup-out-close-icon:hover {
        opacity: 1;
      }

      .add-influencer-popup-outer {
        position: relative;

        .add-influencer-popup-inner {
          position: relative;

          .add-influencer-popup-options {
            margin-top: 25px;
            margin-bottom: 25px;
          }

          label {
            position: relative;
            display: block;
            font-family: Barlow;
            font-weight: 700;
            text-transform: uppercase;
            margin-bottom: 6px;
            margin-top: 12px;
          }

          .dark-input {
            position: relative;
            display: block;
            border: 1px solid rgba(0, 0, 0, 0.04);
            // box-shadow: rgba(0, 0, 0, 0.22) 0px 2px 5px -2px;
            border-radius: 4px;
            padding: 9px;
            width: 100%;
          }

          .add-influencer-input-list {

            .add-influencer-input-item {
              margin-bottom: 15px;

              .add-influencer-input-icon {
                position: relative;
                display: inline-block;
                float: left;
                margin-right: 12px;
                height: 40px;
                width: 32px;
                text-align: left;

                img {
                  position: absolute;
                  top: 0;
                  bottom: 0;
                  margin: auto;
                  width: 24px;
                }
              }

              .add-influencer-input-text {
                display: inline-block;
                float: left;
                width: 240px;
              }
            }
          }

          .add-influencer-csv-table {
            border: 1px solid black;
            text-align: left;

            tr, th, td {
              border: 1px solid black;
            }

            th, td {
              padding: 6px;
            }

            th {
              background: #eee;
            }
          }
        }
      }
    }
  }

}

.add-influencer-dropdown {
  position: absolute;
  top: 100px;
  left: 0;
  width: 100%;
  height: 400px;
  background: white;
  z-index: 9;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 6px 25px -4px;
  border-radius: 0 0 15px 15px;

  .add-influencer-dropdown-inner {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: auto;

    .add-influencer-dropdown-item {
      position: relative;
      width: 100%;
      padding: 8px 0;
      border-bottom: 1px solid #f5f5f5;
      cursor: pointer;

      .add-influencer-dropdown-item-img {
        width: 100%;
        padding-bottom: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 3px;
      }

      p {
        margin: 0;
      }
    }
    .add-influencer-dropdown-item:hover {
      background: #f2f8ff;
    }
  }
}

.edit-inf-socials {

  .edit-social-div {
    position: relative;
    display: inline-block;
    float: left;
    height: 75px;
  }

  .edit-social-platform {
    width: 30px;

    img {
      width: 20px;
    }
  }
  .edit-social-img {
    width: 50px;

    img {
      width: 40px;
      border-radius: 50%;
    }
  }
  .edit-social-username {
    width: 120px;
  }
  .edit-social-delete {
    width: 40px;
    img {
      width: 14px;
      cursor: pointer;
    }
  }
}

.create-tag-list {
  padding: 0;
  list-style: none;
  text-align: left;

  li {
    position: relative;
    list-style: none;
    padding: 8px 12px;
    width: 100%;
    font-weight: bold;
    cursor: pointer;
  }
}

.add-influencer-popup {
  .react-tagsinput {
    border: none;
    background: none;
    padding: 2px 0;
    text-align: left;

    input.react-tagsinput-input {
      width: initial;
      min-width: 80px;
      margin: 0;
      padding: 4px 6px;
      font-size: 14px;
    }
    .react-tagsinput-tag {
      position: relative;
      display: inline-block;
      font-family: Barlow;
      padding: 4px 24px 4px 6px;
      border-radius: 3px;
      float: left;
      margin-right: 6px;
      border: none;
      background: #eee;
      color: black;
      font-size: 14px;
    }
    .react-tagsinput-remove {
      position: absolute;
      font-size: 22px;
      top: -2px;
      right: 6px;
    }
  }
}

.add-influencer-popup.add-influencer-tag {
  .add-influencer-tag-search {
    padding-left: 34px;
    width: 100%;
    position: relative;
    border-bottom: 1px solid #eee;

    .add-influencer-tag-search-icon {
      position: absolute;
      width: 14px;
      top: 8px;
      left: 13px;
      z-index: 2;
    }

    .react-tagsinput {
      position: relative;
      z-index: 2;
      background: none;

      input.react-tagsinput-input {

      }

      .react-tagsinput-tag {
        margin-bottom: 0;
      }
    }
  }
}

.create-email-popup {

  .create-email-to {
    border-bottom: 1px solid #eee;
    padding: 6px 15px;
    // cursor: text;

    .create-email-to-label {
      position: relative;
      display: inline-block;
      float: left;
      width: 24px;
      padding: 6px 0;
    }
    .create-email-to-input {
      position: relative;
      display: inline-block;
      float: left;
    }
  }
  .create-email-cc {
    border-bottom: 1px solid #eee;
  }
  .create-email-subject {

  }
  .create-email-body {

  }
}

.create-email-conversations {
  cursor: pointer;
}
.create-email-conversations:hover {
  background: #eee;
}

@media screen and (max-width: 767px){
  .add-influencer-popup .add-influencer-popup-container .add-influencer-popup-module {
    min-width: unset !important;
    width: 90% !important;
    height: fit-content !important;

    .row {
      > div {
        padding: 0 0 12px 0;
      }
    }
  }

  .add-influencer-popup input.dark-input {
    width: 100% !important;
  }
}
