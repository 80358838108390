.copy-code-snippet {
  margin: 0px;
  resize: none;
  width: 100%;
  padding: 25px;
  min-height: 100px;
  background: rgb(254, 255, 255);
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 2px 9px 0px;
}
