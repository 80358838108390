.paypal {
  /*display: none;*/
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  top: 0;
  left: 0;
  z-index: 9999999;
}
.paypal-popup {
  position: fixed;
  max-width: 673px;
	max-height: 538px;
  width: 85%;
  height: 85%;
	border-radius: 15px;
	background-color: #ffffff;
	box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.24);
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
}
.paypal-top {
  width: 100%;
  height: 315px;
	background-color: #f6f9fc;
  margin: 0;
  padding: 0 15px;
  text-align: center;
  border-radius: 4px 4px 0 0;
}
.paypal-top h2 {
  margin-top: 100px;
  font-size: 60px;
  font-weight: 100;
  color: #4caf50;
  transition: all 0.3s ease;
}
.paypal-top h4 {
  font-weight: 100;
  color: #698198;
  transition: all 0.8s ease;
}
.paypal-top.success h2 {
  margin-top: 25px;
  font-size: 35px;
}
.paypal-top.success h4 {

}
.paypal-top h3.cashout-complete {
  opacity: 0;
  margin-top: 120px;
  transition: all 0.5s ease;
  color: #4caf50;
  font-weight: 100;
}
.paypal-top h3.cashout-complete.success {
  opacity: 1;
  margin-top: 65px;
}
.paypal-top h3.cashout-complete b {
  font-weight: 500;
}
.paypal-email {
  display: block;
  max-width: 408px;
  width: 90%;
	height: 50px;
  margin: 0 auto;
	border-radius: 4px;
	background-color: #ffffff;
	border: solid 1px #e4e4e4;
  padding: 15px;
  font-size: 16px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: rgba(0, 0, 0, 0.5);
  text-align: center;
  font-weight: bold;
}
.paypal-email:focus {
  border-color: #15abff;
}
.paypal-withdraw {
  display: block;
  width: 285px;
	height: 50px;
  margin: 35px auto;
	border-radius: 4px;
	background-color: #0070ba;
	box-shadow: 0 2px 4px 0 rgba(100, 100, 100, 0.5);
	border: solid 1px #0070ba;
  margin: 0 auto;
  color: white;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 500;
}
.paypal-withdraw.success {
  background: #19bd30;
	border-color: #19bd30;
}
.paypal-balance-minimum h3 {
  text-align: center;
  margin-top: 90px;
  font-weight: 100;
  color: #698198;
  padding: 0 25px;
}
.paypal-top .close-paypal {
  position: absolute;
  top: 25px;
  right: 35px;
  font-size: 21px;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  filter: alpha(opacity=20);
  opacity: .2;
  cursor: pointer;
}
.see-withdrawal-history {
  position: absolute;
  bottom: 15px;
  text-align: center;
  left: 0;
  right: 0;
  color: #698198;
  font-weight: 300;
  cursor: pointer;
}
.see-withdrawal-history:hover {
  text-decoration: underline;
}
.withdrawal-history {
  position: absolute;
  /*display: none;*/
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: white;
  margin: 0;
}
.withdrawal-history h3 {
  margin-bottom: 25px;
}
.withdrawal-history h3, .withdrawal-history .col-xs-4 {
  text-align: center;
  font-weight: 300;
}
.withdrawals .row {
  margin: 10px 0;
}

.pending-balance {
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 500;
}
