.invite-modal-bg {
  // width: 100vw;
  width: calc(100vw + 110px);
  // height: 100%;
  height: calc(100% + 40px);
  z-index: -10000;
  position: fixed;
  top: 0;
  margin: -40px 0 0 -35px;
  filter: blur(20px);
  display: none;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  @media screen and (min-width: 600px) {
    display: block;
  }
}

.invite-modal-darken {
  width: 100vw;
  height: 100%;
  z-index: -1;
  position: absolute;
  display: none;

  @media screen and (min-width: 600px) {
    display: block;
  }
}

.invite-modal-body {
  overflow-y: auto;
  overflow-x: hidden;
}

.invite-modal-wrapper {

  @media screen and (min-width: 600px) {
    padding-top: 20px;
  }

  .invite-modal {
    height: 100vh;
    max-width: 600px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 9px 0px;
    @media screen and (min-width: 600px) {
      height: calc(100vh - 75px);
      margin: 0 auto;
      margin-top: 20px;
      background-color: white;
      border-radius: 15px;
      overflow: hidden;
      .invite-modal-header {
        height: 80px !important;
      }

      .invite-modal-body {
        &::-webkit-scrollbar {
          width: 0;  /* Remove scrollbar space */
          background: transparent;  /* Optional: just make scrollbar invisible */
        }

        &::-webkit-scrollbar-thumb {}
      }
    }
    .nav-left {
      cursor: pointer;
    }

    .invite-modal-header {
      position: sticky;
      display: flex;
      flex-direction: row;
      height: 60px;
      align-items: center;
      justify-content: center;
      top: 0;
      z-index: 9999;

      background-color: #f4f4f4;

      img {
        height: 15px;
      }

      .title {
        flex-grow: 1;
        text-align: center;
        font-weight: 600;
        font-size: 20px;
      }


      .left-panel {
        height: 100%;
        display: flex;
        align-items: center;
        cursor: pointer;
      }
    }

    .invite-modal-body {
      height: calc(100% - 80px);
    }

    .spacer {
      margin-left: 20px;
      height: 100%;
    }
  }
}
