.invite-shipping {

  // todo: generic button styles!
  button {
     width: 100%;
  }

  .invite-shipping-full {
    margin: 20px;

    .autocomplete-wrapper {
      width: 100%;
      position: relative;
    }
  }

  .invite-shipping-form {
    margin: 20px 0;
  }

  .invite-shipping-preview {
    .sub-text {
      text-align: left;
    }
  }
}
